import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {GestionproduitsComponent} from './gestionproduits.component';
import {ProduitresolverService} from './produitresolver.service';
import {FP_ROUTES, HAS_GESTION_IDISTRI, HAS_GESTIONPRODUITS, OUTLET_SECONDARY} from '../core/constants';
import {Auth2Guard} from '../core/services/security/auth2.guard';
import {FicheidentiteProduit2Component} from './produit/ficheidentite-produit2/ficheidentite-produit2.component';
import {AllergeneProduitComponent} from './produit/allergene-produit/allergene-produit.component';
import {AllergeneResolverService} from './produit/allergene-produit/allergene-resolver.service';
import {GemrcnProduitComponent} from './produit/gemrcn-produit/gemrcn-produit.component';
import {GemrcnResolverService} from './produit/gemrcn-produit/gemrcn-resolver.service';
import {CiqualComponent} from './produit/ciqual/ciqual.component';
import {FichetechniqueProduitComponent} from './produit/fichetechnique-produit/fichetechnique-produit.component';
import {FichetechniqueResolverService} from './produit/fichetechnique-produit/fichetechnique-resolver.service';
import {DeclinaisonsProduitComponent} from './produit/declinaisons-produit/declinaisons-produit.component';
import {ProduitComponent} from './produit/produit.component';
import {ArticleProduitComponent} from './produit/article-produit/article-produit.component';
import {ArticlesresolverService} from './produit/article-produit/articlesresolver.service';
import {CiqualResolverServiceService} from './produit/ciqual/ciqual-resolver-service.service';
import {AppellationsResolverService} from './produit/appellations/appellations-resolver.service';
import {ModeOperatoireProduitComponent} from './produit/mode-operatoire-produit/mode-operatoire-produit.component';
import {ProduitDeclinaisonResolverService} from './produit/declinaisons-produit/produit-declinaison-resolver.service';
import {AppellationsComponent} from './produit/appellations/appellations.component';
import {MarchesProduitComponent} from './produit/marches-produit/marches-produit.component';
import {MarchesProduitResolverService} from './produit/marches-produit/marches-produit-resolver.service';
import {ImplementationModeleConditionnementPlatComponent} from "./produit/conditionnement/modele-conditionnement-plat/implementation-modele-conditionnement-plat.component";
import {VisualisationMcpParametrageComponent} from "./produit/conditionnement/modele-conditionnement-plat/visualisation/visualisation-mcp-parametrage.component";
import {VisualisationMcpParametrageResolverService} from "./produit/conditionnement/modele-conditionnement-plat/visualisation/visualisation-mcp-parametrage-resolver.service";
import {CommunicationComponent} from "./produit/communication/communication.component";

@NgModule({
  imports: [
    RouterModule.forChild([

      {
        path: `${FP_ROUTES.GPR}`,
        component: GestionproduitsComponent,
        canActivate: [Auth2Guard],
        canActivateChild: [Auth2Guard],
        data:  HAS_GESTIONPRODUITS,
      },
      {
        path: `${FP_ROUTES.GPR}/produit/:isFabrique/:idProduit`,
        component: ProduitComponent,
        data:  HAS_GESTIONPRODUITS,
        outlet: OUTLET_SECONDARY,
        runGuardsAndResolvers: 'always',
        canActivate: [Auth2Guard],
        canActivateChild: [Auth2Guard],
        resolve: {
          produitSupplier: ProduitresolverService,
        },
        children: [
          {
            path: 'ficheidentite',
            component: FicheidentiteProduit2Component,
            runGuardsAndResolvers: 'always',
            data:  HAS_GESTIONPRODUITS,
          },
          {
            path: 'produit-declinaisons',
            component: DeclinaisonsProduitComponent,
            runGuardsAndResolvers: 'always',
            data:  HAS_GESTIONPRODUITS,
            resolve: {
              produitDeclinaisonSupplier: ProduitDeclinaisonResolverService,
            },
          },

          {
            path: 'allergenes',
            data:  HAS_GESTIONPRODUITS,
            component: AllergeneProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              allergeneSupplier: AllergeneResolverService
            }
          },

          {
            path: 'gemrcn',
            data:  HAS_GESTIONPRODUITS,
            component: GemrcnProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              gemrcnSupplier: GemrcnResolverService
            }
          },
          {
            path: 'ciqual',
            data:  HAS_GESTIONPRODUITS,
            component: CiqualComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              ciqualSupplier: CiqualResolverServiceService
            }
          },
          {
            path: 'appellations',
            data:  HAS_GESTIONPRODUITS,
            component: AppellationsComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              appellationsSupplier: AppellationsResolverService
            }
          },
          {
            path: 'fichetechnique',
            data:  HAS_GESTIONPRODUITS,
            component: FichetechniqueProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              fichetechniqueSupplier: FichetechniqueResolverService
            }
          },
          {
            path: 'fichetechnique-preselection/:idSiteContratMenu',
            data:  HAS_GESTIONPRODUITS,
            component: FichetechniqueProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              fichetechniqueSupplier: FichetechniqueResolverService
            }
          },
          {
            path: 'fichetechnique-preselection-by-udp/:idUdp',
            data:  HAS_GESTIONPRODUITS,
            component: FichetechniqueProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              fichetechniqueSupplier: FichetechniqueResolverService
            }
          },
          {
            path: 'marches',
            data:  HAS_GESTIONPRODUITS,
            component: MarchesProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              marcheProduitSupplier: MarchesProduitResolverService
            },
          },
          {
            path: 'articles',
            data:  HAS_GESTIONPRODUITS,
            component: ArticleProduitComponent,
            runGuardsAndResolvers: 'always',
            resolve: {
              articlesSupplier: ArticlesresolverService
            },
            children:[]
          },
          {
            path: 'mode-operatoire',
            data:  HAS_GESTIONPRODUITS,
            component: ModeOperatoireProduitComponent,
            resolve: {
              fichetechniqueSupplier: FichetechniqueResolverService
            }
          },
          {
            path: `conditionnement`,
            component: ImplementationModeleConditionnementPlatComponent,
            data: HAS_GESTIONPRODUITS && HAS_GESTION_IDISTRI,
          },
          {
            path: `conditionnement-visualization/:idUdpMcp`,
            component: VisualisationMcpParametrageComponent,
            data: HAS_GESTIONPRODUITS && HAS_GESTION_IDISTRI,
            runGuardsAndResolvers: 'always',
            resolve: {
              idUdpMdp: VisualisationMcpParametrageResolverService
            }
          },
          {
            path: `communication`,
            component: CommunicationComponent,
            data: HAS_GESTIONPRODUITS,
            runGuardsAndResolvers: 'always',
          }
        ],
      },

    ])
  ],
  exports: [RouterModule],
  providers:
    [
      ProduitresolverService,
      ArticlesresolverService,
      AllergeneResolverService,

      // FicheidentiteProduitComponent,
      // FicheidentiteProduitdecliResolverService,
      FichetechniqueResolverService,
      CiqualResolverServiceService,
      VisualisationMcpParametrageResolverService
    ]
})

export class GestionproduitsRoutingModule {
}

