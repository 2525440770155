import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ImageBase64DTO} from "../../../../../core/dtos/images/ImageBase64DTO";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {DxPopupComponent} from "devextreme-angular";

@Component({
  selector: 'yo-image-list-popup',
  templateUrl: './image-list-popup.component.html',
  styleUrls: ['./image-list-popup.component.scss']
})
export class ImageListPopupComponent implements OnInit {

  @ViewChild("popup") popup: DxPopupComponent;
  @ViewChild("imageInput") imageInput: ElementRef;
  @ViewChild("image") image: ElementRef;

  @Input() isOpened: boolean;
  @Input() disabled: boolean;
  @Input() title: string = "Gestion des images";
  @Input() values: ImageBase64DTO[];

  @Output() onClose: EventEmitter<ImageBase64DTO[]> = new EventEmitter<ImageBase64DTO[]>();

  displayImagePopup: boolean;
  imageSelected: ImageBase64DTO;

  fullscreen: boolean;

  imageRotation: number = 0;

  constructor(private utilsSvc: UtilsService) {
  }

  ngOnInit() {
  }

  private resetImageInput = () => {
    if (this?.imageInput?.nativeElement)
      this.imageInput.nativeElement.value = null;

  }

  onImageSelected($event: any) {
    if (!this.values)
      this.values = [];

    for (const image of $event?.target?.files) {
      const fileName = this.utilsSvc.getFormattedFileName(image.name);

      if (!this.values.map((value) => value.fileName).includes(fileName)) {

        this.convertToBase64(image).then((result: string) => {
          this.values.push({
            id: null,
            uid: null,
            fileName: fileName,
            imageBase64: result
          })
        })
      }

    }
    this.resetImageInput();
  }

  private convertToBase64 = (file: File) => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      reader.readAsDataURL(file);
    });
  }

  onCloseDialog = () => {
    this.onClose.emit(this.values);
    this.resetImageInput();
  };

  closePopup = () => {
    console.log("Close !");
    this.popup.instance.hide();
  }

  deleteImage = (image: ImageBase64DTO) => {
    this.values.splice(this.values.indexOf(image), 1);
  };

  getFileSource = (image: ImageBase64DTO, size: string) => {
    if (image)
      return this.utilsSvc.isNullOrEmpty(image.imageBase64) ?
        "dolrest/document/loadById/" + image.id + "/" + size :
        image.imageBase64;
    return 'assets/images/no-image.png';
  };

  closeImagePopup = () => {
    this.displayImagePopup = false;
    this.imageSelected = null;
    this.resetFullscreenImage();
  }

  openImage = (image: ImageBase64DTO) => {
    this.imageSelected = image;
    this.displayImagePopup = true;
  };

  rotateImage = () => {
    if (!this.imageRotation)
      this.imageRotation = 0;

    if (this.imageRotation == 360) {
      this.resetFullscreenImage();
    } else
      this.removeRotationClass();

    this.imageRotation = (this.imageRotation + 90);

    this.updateImageSize();

    this.image.nativeElement.classList.add("rotation-" + this.imageRotation);
  };

  removeRotationClass = () => {
    this.image.nativeElement.classList.remove("rotation-" + this.imageRotation);
  }

  addRotationClass = () => {
    this.image.nativeElement.classList.add("rotation-" + this.imageRotation);
  }

  updateImageSize = () => {
    this.image.nativeElement.parentElement.parentElement.offsetWidth;
    if ((this.imageRotation - 90) % 180 == 0) {
      this.image.nativeElement.style.maxWidth = this.image.nativeElement.parentElement.offsetHeight + 'px';
      this.image.nativeElement.style.maxHeight = this.image.nativeElement.parentElement.offsetWidth + 'px';
    } else {
      this.image.nativeElement.style.maxWidth = '';
      this.image.nativeElement.style.maxHeight = '';
    }
  }

  resetFullscreenImage = () => {
    this.image.nativeElement.classList.add('no-animation');
    this.removeRotationClass();
    this.imageRotation = 0
    this.addRotationClass();
    this.image.nativeElement.offsetHeight;
    this.image.nativeElement.classList.remove('no-animation');

    this.updateImageSize();
  }

  toggleFullScreen = async () => {
    this.fullscreen = !this.fullscreen;
    // permet d'attendre que la fenêtre ait eu le temps de se resize
    await new Promise(res => setTimeout(res, 1));
    this.updateImageSize();
  };
}
