import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {PrintMenuService} from '../../../../core/services/gestionmenus/print-menu.service';
import {
  PreparationConditionnementService
} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {PRINT_MODE} from "../../../../core/constants";
import BonLivraisonSettingsMl from "../../../../core/dtos/conditionnement/bon-livraison-settings-ml";

@Component({
  selector: 'yo-bon-livraison',
  templateUrl: './bon-livraison.component.html',
  styleUrls: ['./bon-livraison.component.scss']
})
export class BonLivraisonComponent implements OnInit, OnDestroy {

  @Input() public idPlanProduction: number;
  @Input() public idPlanProductionDetails: number[];
  comment: string;
  message: string;
  displayDialog: boolean;
  paysage: boolean;

  subBonlivraison: Subscription;
  plcList: object[] = [];
  private subComment: Subscription;
  protected subPlc: Subscription;
  plcSelectedComment: string[] = [];
  plcSelectedMessage: string[] = [];

  constructor(
    private utils: UtilsService,
    private printMenuService: PrintMenuService,
    private pcSvc: PreparationConditionnementService) {
  }

  ngOnInit(): void {
    this.printMenuService.displayDialogPrintBonLivraison$.subscribe(() => {
      this.initPlcList();
      this.displayDialog = true;
      this.paysage = false;
    });
    this.initCommentaire();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subComment);
  }

  private initCommentaire = () => {
    this.subComment = this.printMenuService.getCommentaireBonlivraison().subscribe(response => {
      this.comment = response.replace(/^"(.*)"$/, '$1');
    });
  }

  downloadBonlivraison = (): void => {
    const printMode = this.paysage ? PRINT_MODE.PAYSAGE : PRINT_MODE.PORTRAIT;
    const bonLivraisonSettings: BonLivraisonSettingsMl = new BonLivraisonSettingsMl();
    bonLivraisonSettings.message = this.message;
    bonLivraisonSettings.comment = this.comment;
    bonLivraisonSettings.plcMessageList = this.plcSelectedMessage.map(plcId => +plcId);
    bonLivraisonSettings.plcCommentList = this.plcSelectedComment.map(plcId => +plcId);
    bonLivraisonSettings.idPlanProductionDetails = this.idPlanProductionDetails;

    this.pcSvc.printBonLivraison(this.idPlanProduction, bonLivraisonSettings, printMode);
    this.displayDialog = false;
  }

  closeDialog = () => {
    this.plcSelectedMessage = [];
    this.plcSelectedComment = [];
    this.plcList = [];
    this.displayDialog = false;
  };

  onChangePlcMessage(data: any) {
    this.plcSelectedMessage = data;
  }

  onChangePlcComment(data: any) {
    this.plcSelectedComment = data;
  }

  private initPlcList() {
    this.subPlc = this.pcSvc.getPlcBonLivraison(this.idPlanProduction).subscribe(response => {
      this.plcList = response.resultList;
      this.plcSelectedComment = response.resultList.map(plc => plc.id);
    });
  }
}
