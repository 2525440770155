import {Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UtilsService} from '../../utils/utils.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {ModeleNutritionnelDTO} from '../../dtos/modele-nutritionnel-dto';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {GenericHandler} from '../generics/generic-handler';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ContratmenuDTO} from '../../dtos/contratmenu-dto';
import {SiteDTO} from '../../dtos/site-dto';
import {ProduitDTO} from '../../dtos/produit-dto';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {FormFieldCheckboxSupplier} from '../../suppliers/form-field-checkbox-supplier';
import {ConvivesService} from '../entities/convives.service';
import {find as _find, startCase as _startCase} from 'lodash';
import {ContratMenuConviveRepasDTO} from '../../dtos/contratmenuconviverepas-dto';
import {ContratMenuConvive__EquipeDTO} from '../../dtos/contratmenuconvive-equipe-dto';
import {DeclinaisonsService} from '../entities/declinaisons.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormGroup} from '@angular/forms';
import {ConviveDTO} from '../../dtos/convive-dto';
import {ContratMenuConvive__ModeleNutriDTO} from '../../dtos/contratmenuconvive__modele-nutri-dto';
import * as moment from 'moment';
import {ContratMenuConvive__PlcDTO} from '../../dtos/contratmenuconvive__plc-dto';
import {DeclinaisonDTO} from '../../dtos/declinaison-dto';
import {GenericRequestSupplier, Predicat, Search} from '../../suppliers/generics/generic-request-supplier';
import {CmcContrainteAlimService} from '../entities/cmc-contrainte-alim.service';
import {RegimeAlimentaireService} from '../entities/regime-alimentaire.service';
import {ContrainteModel} from '../../models/contrainte-model';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../dtos/contratmenuconvive__contrainte-alim-dto';
import {TypePrestationService} from './type-prestation.service';
import {HttpService} from "../technique/http.service";

const URL_GET_CONTRATMENUCONVIVE_UDPEQUIPES = 'dolrest/gestioncontrats/contrat-menu-convive/equipes/udp-equipes';
const URL_GET_CONTRATMENUCONVIVE_CMCEQUIPES = 'dolrest/gestioncontrats/contrat-menu-convive/equipes/cmc-equipes';
export const URL_POST_CREATE_PERIODE_PLANNING = `dolrest/gestioncontrats/contrat-menu-convive/planning/create-periode`;
export const URL_GET_PROCHAINE_DATE_DISPONIBLE = `dolrest/gestioncontrats/contrat-menu-convive/planning/prochaine-date-disponible`;
export const URL_POST_SAVE_PLANNING = `dolrest/gestioncontrats/contrat-menu-convive/planning/save`;
export const URL_DUPLICATE_PRESTATION_MENU = `dolrest/contratsmenusconvives/duplicate/contratsmenusconvive`;
export const URL_DELETE_PRESTATION_MENU = `dolrest/contratsmenusconvives/delete/contratsmenusconvive`;

@Injectable()
export class ContratsMenusConvivesService extends GenericHandler<ContratMenuConviveDTO> {

  // champs static s nécessaires pour les linkedFieldFn
  static sRegimeSvc;
  static sCmcContrainteAlimSvc;
  static contraintesAlim: ContrainteModel[] = [];
  static cmcContraintesAlim;

  formFieldSite: FormFieldTextboxSupplier;

  declinaisonReferenteFormField: FormFieldDropdownSupplier;

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private httpSvc: HttpService,
              private convivesSvc: ConvivesService,
              private regimeSvc: RegimeAlimentaireService,
              private cmcContrainteAlimSvc: CmcContrainteAlimService,
              private declinaisonsSvc: DeclinaisonsService,
              private typePrestationSvc: TypePrestationService
  ) {
    super(utils, auth2Svc, router, http, title);

    ContratsMenusConvivesService.sRegimeSvc = this.regimeSvc;
    ContratsMenusConvivesService.sCmcContrainteAlimSvc = this.cmcContrainteAlimSvc;

  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  getOas = (): boolean => undefined;


  /**
   * Récupérer les equipes des unités de production
   * @returns {Observable<any>}
   */
  getUdpEquipes = (idContratMenuConvive: number): Observable<any> => {
    const params: HttpParams = new HttpParams().set('idContratMenuConvive', idContratMenuConvive + '')
    return this.httpSvc.get(URL_GET_CONTRATMENUCONVIVE_UDPEQUIPES, params);
  };

  /**
   * Récupérer les equipes du contrat menu convive
   * @returns {Observable<any>}
   */
  getCmcEquipes = (idContratMenuConvive: number): Observable<any> => {
    const params: HttpParams = new HttpParams().set('idContratMenuConvive', idContratMenuConvive + '');
    return this.httpSvc.get(URL_GET_CONTRATMENUCONVIVE_CMCEQUIPES, params);
  };


  /**
   * Enregistrer la fiche d'identité d'un contrat menu convie
   * @param {ProduitDTO} produit
   * @returns {Observable<ResponseWrapper<FournisseurDTO>>}
   */
  saveFicheIdentite = (contratMenuConvive: any): Observable<ResponseWrapper<any>> => this.httpSvc.post('dolrest/gestioncontrats/contrat-menu-convive/ficheidentite/save', contratMenuConvive);

  getFields = (dto: ContratMenuConviveDTO): FormFieldBaseSupplier<any>[] => {


    if (this.utils.isNullOrEmpty(dto)) {
      dto = new ContratMenuConviveDTO();
    }

    const isReadonly = !this.canModify(dto);
    ContratsMenusConvivesService.contraintesAlim = this.regimeSvc.getRegimesContraintes();
    ContratsMenusConvivesService.cmcContraintesAlim = dto && dto.contratMenuConvive__contrainteAlimList ? dto.contratMenuConvive__contrainteAlimList : [];

    this.declinaisonReferenteFormField = new FormFieldDropdownSupplier({
        key: 'declinaisonReference',
        label: 'Déclinaison référente',
        help: 'Déclinaison de référence',
        required: true,
        readonly: isReadonly,
        value: this.utils.preSelectSingleList(this.declinaisonsSvc.declinaisonEnvironnement.filter(de => de.typeProduit.fabrique), dto.declinaisonReference),
        order: 6,
        options: this.getDeclinaisonsOfCmc(dto, this.declinaisonsSvc.declinaisonEnvironnement),
      });

    let formFields: FormFieldBaseSupplier<any>[] = [


      this.formFieldSite = new FormFieldTextboxSupplier({
        key: 'site',
        label: 'Site',
        readonly: true,
        value: dto.site,
        order: 1
      }),

      new FormFieldDropdownSupplier({
        key: 'typePrestationDTO',
        label: 'Type prestation',
        readonly: isReadonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.typePrestationSvc.typePrestationEnvironnement, dto.typePrestationDTO),
        order: 2,
        required: true,
        options: this.typePrestationSvc.typePrestationEnvironnement,
      }),

      new FormFieldDropdownSupplier({
        key: 'convive',
        label: 'Prestation',
        readonly: isReadonly,
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.convivesSvc.convivesEnvironnement, dto.convive),
        order: 3,
        required: true,
        linkedFieldFn: this.changeLibelle,
        options: this.convivesSvc.convivesEnvironnement,
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        help: `Code de la préstation`,
        // type: 'hidden',
        readonly: isReadonly,
        value: dto.code,
        minLength: 1,
        maxLength: 100,
        required: true,
        order: 4
      }),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        help: `Dénomination de la prestation`,
        // type: 'hidden',
        readonly: isReadonly,
        value: dto.libelle,
        minLength: 1,
        maxLength: 100,
        required: true,
        order: 4
      }),

      new FormFieldDropdownSupplier({
        key: 'menuConviveDeclinaisonList',
        label: 'Déclinaisons de produit autorisées',
        help: 'Déclinaisons de produit pouvant être utilisées sur cette prestation',
        readonly: isReadonly,
        type: 'multiple',
        value: this.getDeclinaisonsOfCmc(dto, this.declinaisonsSvc.declinaisonEnvironnement),
        order: 6,
        required: true,
        options: this.declinaisonsSvc.declinaisonEnvironnement.filter(de => de.typeProduit.fabrique)
      }),

      this.declinaisonReferenteFormField,

      new FormFieldDropdownSupplier({
        key: 'regimes',
        label: 'Régimes',
        help: 'Régime et Texture',
        required: true,
        readonly: isReadonly,
        type: 'multiple',
        value: this.regimeSvc.preselectContraintesAlim(ContratsMenusConvivesService.contraintesAlim, ContratsMenusConvivesService.cmcContraintesAlim),
        order: 7,
        options: ContratsMenusConvivesService.contraintesAlim,
        linkedFieldFn: this.loadRegimeReferentList,
        dto: dto
      }),

      new FormFieldDropdownSupplier({
        key: 'regimeReferent',
        label: 'Régime référent',
        help: 'Régime qui sera utilisé dans les menus pour pré-renseigner les plats des autres régimes',
        required: true,
        readonly: isReadonly,
        value: ContratsMenusConvivesService.preselectRegimeReferent(ContratsMenusConvivesService.contraintesAlim, ContratsMenusConvivesService.cmcContraintesAlim),
        order: 8,
        options: this.regimeSvc.preselectContraintesAlim(ContratsMenusConvivesService.contraintesAlim, ContratsMenusConvivesService.cmcContraintesAlim),
        linkedFieldFn: this.changeRegimeReferent
      }),


      new FormFieldCheckboxSupplier({
        key: 'actif',
        label: 'Actif',
        readonly: isReadonly,
        value: dto.actif,
        required: true,
        order: 9
      }),

      new FormFieldTextboxSupplier({
        key: 'id',
        type: 'hidden',
        value: dto.id,
        order: 10
      }),

      new FormFieldTextboxSupplier({
        key: 'contratMenuId',
        type: 'hidden',
        value: dto.contratMenuId,
        order: 11
      }),


    ];

    return formFields;
  };

  updateDeclinaisonReferenteList = (selected: any) => {
    this.declinaisonReferenteFormField.updateOptions(selected);
  }

  static preselectRegimeReferent = (contraintes: ContrainteModel[], cmcContraintesAlim: ContratMenuConvive__ContrainteAlimDTO[]) => {


    for (const cmcCa of cmcContraintesAlim) {
      if (cmcCa.referent === true) {

        for (const contrainte of contraintes) {
          if (contrainte.regime.id === cmcCa.regimeId && contrainte.texture.id === cmcCa.textureId) {
            return contrainte;
          }
        }


      }
    }


    return null;

  };


  /**
   * Charger la liste des régimes référents à partir des régimes sélectionnés
   * @param value
   * @param form
   * @param fields
   * @param contraintesAlimentaires
   */
  loadRegimeReferentList = (value, form: FormGroup, fields: FormFieldBaseSupplier<any>[], contraintesAlimentaires) => {

    if (fields) {

      const fieldReferent = fields.filter(field => field.key === 'regimeReferent');
      const fieldRegimes = fields.filter(field => field.key === 'regimes');
      fieldReferent[0].options = contraintesAlimentaires;
      form.controls['regimeReferent'].setValue(ContratsMenusConvivesService.preselectRegimeReferent(contraintesAlimentaires, fieldRegimes[0].dto.contratMenuConvive__contrainteAlimList));

    }

  };

  changeRegimeReferent = (value, form: FormGroup, fields: FormFieldBaseSupplier<any>[], contraintesAlimentaires) => {

    if (fields) {

      const fieldRegimes = fields.filter(field => field.key === 'regimes');
      const cmc = fieldRegimes[0].dto;

      cmc.contratMenuConvive__contrainteAlimList = ContratsMenusConvivesService.prepareRegimes(cmc, form);


    }

  };


  /**
   * Déclinaisons disponibles dans le contrat menu convive
   * @param cmc
   * @return {any[]}
   */
  getDeclinaisonsOfCmc = (cmc: ContratMenuConviveDTO, declinaisons: DeclinaisonDTO[]): DeclinaisonDTO[] => {
    let menuConviveDeclinaisonList = cmc.menuConviveDeclinaisonList;
    const selectedDecliList: DeclinaisonDTO[] = [];

    if (!this.utils.isCollectionNullOrEmpty(menuConviveDeclinaisonList)) {
      if (!this.utils.isCollectionNullOrEmpty(declinaisons)) {

        menuConviveDeclinaisonList.map(mcd => {

          const found = _find(declinaisons, {'id': mcd.idDeclinaison});

          if (!this.utils.isNullOrEmpty(found)) {
            selectedDecliList.push(found);
          }

        })
      }
    }


    return selectedDecliList;
  };


  getEntityName = (): string => 'ContratMenuConvive';

  getTitle = (): string => 'GESTION DES PRESTATIONS PAR CONTRAT';

  getCreateNewObjectLabel = (): string => undefined;

  getSort = (): string[] => ['libelle,desc'];


  getAllFromEnvironnement = (): void => {
  };

  createEmptyDTO = (): ContratMenuConviveDTO => new ContratMenuConviveDTO();

  createEmptyDTOWithContratMenu = (contratMenu: ContratmenuDTO): ContratMenuConviveDTO => {
    const cmc = new ContratMenuConviveDTO();

    cmc.contratMenuId = contratMenu.id;

    const site = new SiteDTO();
    cmc.site = site;
    cmc.site.id = contratMenu.site.id;
    cmc.site.libelle = contratMenu.site.libelle;
    cmc.actif = true;
    cmc.libelle = '';
    cmc.periodeGemrcn = 28;

    // Initialiser les contraintes alimenataires par defaut
    cmc.contratMenuConvive__contrainteAlimList = this.regimeSvc.initContraintesAlimentaires(this.cmcContrainteAlimSvc.createEmptyFromContrainte);


    return cmc;
  };

  /**
   *
   */
  isEquipeActive = (cmcEquipe: ContratMenuConvive__EquipeDTO): boolean => {

    if (cmcEquipe.contratMenuConviveActif === true
      && cmcEquipe.tacheActif === true
      && cmcEquipe.udpEquipeActif === true) {
      return true;
    }
    return false;
  };

  equipeActiveTooltip = (cmcEquipe: ContratMenuConvive__EquipeDTO): string => {

    let tooltip = '';
    let result = true;

    if (cmcEquipe.contratMenuConviveActif === false) {
      result = false;
      tooltip += `Le contrat convive "${cmcEquipe.contratMenuConviveLibelle}" est inactif.<br>`;
    }
    if (cmcEquipe.tacheActif === false) {
      result = false;
      tooltip += `Le type de fabrication "${cmcEquipe.tacheLibelle}" est inactif.<br>`;
    }
    if (cmcEquipe.udpEquipeActif === false) {
      result = false;
      tooltip += `L'équipe "${cmcEquipe.equipeLibelle}" rattachée à l'unité de production "${cmcEquipe.uniteDeProductionLibelle}" est inactive.<br>`;
    }

    if (result === false) {
      tooltip += 'Ce paramétrage ne peut être activé.';
    }

    return tooltip;
  };

  getEditObjectLabel = (data: ObjectDTO): string => '';


  changeLibelle = (value: string, form: FormGroup, fields: FormFieldBaseSupplier<any>[], object: any) => {

    if (fields) {

      const convive = object as ConviveDTO;
      for (let field of fields) {
        if (field.key === 'libelle' && field.value === '') {
          field.value = convive.libelle;
          form.controls['libelle'].setValue(field.value);
          break;
        }
      }
    }

  };


  createPeriode = (prestation: ContratMenuConviveDTO, planAlimentaire: ModeleNutritionnelDTO, selectedPeriode: Date) => {
    const fd = new FormData();
    fd.set('idContratMenuConvive', prestation.id + '');
    fd.set('idModeleNutritionnel', planAlimentaire.id + '');
    fd.set('dateDebut', this.utils.getYYYYMMDD(moment(selectedPeriode)));

    return this.http.post(URL_POST_CREATE_PERIODE_PLANNING, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  getProchaineDateDisponible = (idContratMenuConvive: number) => {
    return this.http.get(URL_GET_PROCHAINE_DATE_DISPONIBLE, {params: new HttpParams().set('idContratMenuConvive', idContratMenuConvive + '')})
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  /**
   * Enregistrer une periode de plan alimentaire
   * Appliquer les cout et effectifs previsionnels sur les pls rattachés à la prestation
   * @param idContratMenuConvive
   */
  savePeriodePlanning = (cmcrList: ContratMenuConviveRepasDTO[], cmcPlcList: ContratMenuConvive__PlcDTO[], periode: ContratMenuConvive__ModeleNutriDTO) => {
    return this.http.post(URL_POST_SAVE_PLANNING, cmcrList, {
      params: new HttpParams().set('idsCmcPlc', cmcPlcList ? cmcPlcList.map(item => item.id).join(',') : '')
        .set('idCmcMn', periode.id + '')
    })
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  /**
   * Récupérer toutes les contraintes alimentaires (regime + texture) de la prestation (cmc)
   * @param idCmc
   * @return {GenericRequestSupplier}
   */
  filterCmcContrainteAlim = (idCmc: number): GenericRequestSupplier => {

    const grs = new GenericRequestSupplier(this.cmcContrainteAlimSvc.getEntityName());
    const search = new Search();

    const predicatCmc = new Predicat();
    predicatCmc.path = `${this.cmcContrainteAlimSvc.getEntityName().toLowerCase()}.contratMenuConvive.id`;
    predicatCmc.operator = PREDICAT_OPERATOR.Equals;
    predicatCmc.type = PREDICAT_TYPE.Integer;
    predicatCmc.value = idCmc + '';

    search.predicats = [predicatCmc];

    grs.search = search;

    return grs;
  };


  /**
   * Transformer les contraintes alimentaires en ContratMenuConvive__ContrainteAlimDTO[]
   * Positionner le referent
   * @deprecated
   * @param contratMenuConvive
   * @param formInfoGenerales
   */
  static prepareRegimes = (contratMenuConvive: ContratMenuConviveDTO, formInfoGenerales: FormGroup): ContratMenuConvive__ContrainteAlimDTO[] => {


    const contraintes: ContrainteModel[] = formInfoGenerales.controls['regimes'].value;
    const contrainteReferente: ContrainteModel = formInfoGenerales.controls['regimeReferent'].value;

    const cmcCaArr = ContratsMenusConvivesService.sRegimeSvc.convertToEntityContrainteAlim(contraintes, contratMenuConvive, 'id', 'cmcId', ContratsMenusConvivesService.sCmcContrainteAlimSvc.createEmptyFromContrainte);

    for (const cmcCa of cmcCaArr) {
      cmcCa.referent = false;
      if (cmcCa.regimeId === contrainteReferente.regime.id && cmcCa.textureId === contrainteReferente.texture.id) {
        cmcCa.referent = true;
      }
    }


    return cmcCaArr;
  };

  duplicatePrestation = (contratMenuConvive: ContratMenuConviveDTO, contratMenu: ContratmenuDTO) => {
    const fd = new FormData();
    fd.append('idContratMenuConvive', contratMenuConvive.id.toString());
    fd.append('idContratMenu', contratMenu.id.toString());

    return this.httpSvc.post(URL_DUPLICATE_PRESTATION_MENU, fd);
  };

  delete = idToDelete => {
    const fd = new FormData();
    fd.append('idContratMenuConvive', idToDelete);

    return this.http.post(URL_DELETE_PRESTATION_MENU, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  findByIdsOffreAlimentaire = (idsOffreAlimentaire: number[]) => {
    const params: HttpParams = new HttpParams().set("idsOffreAlimentaire", idsOffreAlimentaire.join(','));
    return this.httpSvc.get(`dolrest/contratsmenusconvives/findByIdsOffreAlimentaire`, params);
  }
}
