<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <form (ngSubmit)="save()" [formGroup]="form">
    <div class="dialog-line">
      <div class="label" title="Site">Site <span class="danger-color">*</span></div>
      <div class="value">
        <dx-lookup
          [dataSource]="findAllLocalSites()"
          [value]="idsite"
          displayExpr="libelle"
          valueExpr="id"
          [searchEnabled]="true"
          placeholder="Selectionner le site..."
          formControlName="site"
        >
          <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
        </dx-lookup>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box class='inputfield' placeholder="Libellé" formControlName="libelle"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Code">Code <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box [required]="!isCreateMode" class='inputfield' placeholder="Code" formControlName="code"></dx-text-box>
      </div>
    </div>


    <div class="dialog-line">
      <div class="label" title="Abréviation">Abréviation <span class="danger-color">*</span></div>
      <div class="value">
        <dx-text-box class='inputfield' placeholder="Abréviation" formControlName="abreviation"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Libellé">Poids en Kg</div>
      <div class="value">
        <dx-text-box class='inputfield' placeholder="Poids en Kg" formControlName="poidsEnKg"></dx-text-box>
        <ng-container
          *ngTemplateOutlet="tplError;context:{form:form,property:'poidsEnKg',label:'Poids en Kg'}"></ng-container>
      </div>
    </div>
    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button
          type="submit"
          iconClass="fas fa-save"
          tooltip="Enregistrer"
          buttonClass="cta-success"
          [disabled]="!canModify()">
        </yo-button>
        <yo-button
          buttonClass="cta-delete"
          (onClick)="closeDialog()"
          tooltip="Fermer"
          iconClass="fas fa-times"
        >
        </yo-button>
      </div>
    </div>
  </form>
</dx-popup>


<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
