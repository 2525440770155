import {ContratMenuConviveRepasDTO} from "./contratmenuconviverepas-dto";
import {ContratmenuDTO} from "./contratmenu-dto";
import {MenuConviveDeclinaisonDTO} from "./menu-convive-declinaison-dto";
import {ModeleNutritionnelDTO} from "./modele-nutritionnel-dto";
import {ObjectDTO} from "./object-dto";
import {ConviveDTO} from "./convive-dto";
import {ContratMenuConvive__ContrainteAlimDTO} from "./contratmenuconvive__contrainte-alim-dto";
import {TypePrestationDTO} from "./type-prestation-dto";
import {RegimeAlimentaireDTO} from "./regime-alimentaire-dto";
import {DeclinaisonDTO} from "./declinaison-dto";

export class ContratMenuConviveDTO extends ObjectDTO {

  //    contrat menu convive

  code: string;
  libelle: string;
  actif: boolean;
  dateDebGemrcn:Date;

  // Periode en jours du GEMRCN
  periodeGemrcn;

  // convive
  convive : ConviveDTO;

  // type prestation
  typePrestationDTO : TypePrestationDTO;

  // modele nutritionnel
  modeleNutritionnel : ModeleNutritionnelDTO;

  // contrat menu --> parent do not use as DTO to avoid circular dependency
  contratMenuId : number;
  contratMenuCode : string;
  contratMenuLibelle : string;
  contratMenuActif : boolean;
  offreAlimentaire : ContratmenuDTO;
  contratMenuSiteId : number;

  // menus_convives_declinaisons
  declinaisonReference: DeclinaisonDTO;
  menuConviveDeclinaisonList:MenuConviveDeclinaisonDTO[]=[];


  // contrat menus convives repas
  contratsMenusConvivesRepas : ContratMenuConviveRepasDTO[]=[];

  // contrat menu convive contrainte alim
  contratMenuConvive__contrainteAlimList : ContratMenuConvive__ContrainteAlimDTO[]=[];

  regimes: RegimeAlimentaireDTO[] = [];

}
