<div class="d-flex flex-row align-items-center">
  <h5 class="mg-b-0 mg-r-15">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <label class="badge badge-secondary">
      {{ 'plan de production' | uppercase }}
    </label>
  </h5>
  <div><strong>"{{ planProduction?.libelle | uppercase }}"</strong>
    <yo-site class="mg-l-15" [siteLibelle]="planProduction?.site?.libelle"
             [siteId]="planProduction?.site?.id"></yo-site>
    <span [title]="currentEffectif" class="type-effectif mg-l-10">(C'est l'{{ currentEffectif }} qui est utilisé pour le calcul du plan de production)</span>
  </div>
</div>

<div class="d-flex flex-row justify-content-between mg-b-10">
  <div>
    <yo-button (onClick)="openBulkEditionDetailDialog()" label="Traitement en masse"
               tooltip="Appliquer les modifications aux ligne(s) selectionnée(s)"
               [disabled]="selectedDetails.length === 0 || (!valueConsumptionDate || !this.valueMeal || !this.valuePlc || !this.valueTeam)"
               iconClass="fas fa-edit"></yo-button>
  </div>
  <div>
    <span class="mg-r-5">
      <yo-button (onClick)="openOptionsDisplayDialog()" tooltip="Options d'affichage" iconClass="fa fa-desktop"
                 [disabled]="!isDataRetrieved"></yo-button>
    </span>
    <span class="mg-r-5" title="Générer une liste de besoins à partir du plan de production courant">
      <yo-button (onClick)="genererListeBesoins()"
                 tooltip="Générer une liste de besoins à partir du plan de production courant" iconClass="fas fa-box"
                 [disabled]="!isDataRetrieved"></yo-button>
    </span>
    <span class="mg-r-5" title="Lancer le calcul du conditionnement">
      <yo-button (onClick)="openPreparationConditionnement()" tooltip="Lancer le calcul du conditionnement"
                 iconClass="fas fa-boxes" [disabled]="!isDataRetrieved"></yo-button>
    </span>
    <span class="mg-r-5" title="Rafraîchir le calcul du conditionnement">
      <yo-button (onClick)="openPreparationConditionnement(true)" tooltip="Rafraîchir le calcul du conditionnement"
                 iconClass="fas fa-arrows-rotate" [disabled]="!isDataRetrieved"></yo-button>
    </span>
    <span class="mg-r-5" title="Sorties de production">
      <yo-button (onClick)="generateSortiesPlanProduction()" tooltip="Sorties de production"
                 iconClass="fas fa-sign-out-alt" [disabled]="!isDataRetrieved"></yo-button>
    </span>

    <yo-button
      tooltip="Générer le bon d'allotissement"
      iconClass="fas fa-truck-loading"
      class="mr-1"
      (onClick)="printAllotissement()"
      [disabled]="!isDataRetrieved"
    >
    </yo-button>

    <yo-button
      tooltip="Générer le bon de livraison"
      iconClass="fas fa-shipping-fast"
      class="mr-1"
      (onClick)="printLivraison()"
      [disabled]="!isDataRetrieved"
    >
    </yo-button>

    <yo-button (onClick)="openPrintSettingsFichesTechniques()" tooltip="Sélectionner une impression"
               iconClass="fas fa-print" [disabled]="!isDataRetrieved"></yo-button>
  </div>
</div>

<dx-data-grid
  id="gridDetailPlanProd"
  (onCellPrepared)="onCellPrepared($event)"
  (onEditingStart)="onEditingStart($event)"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onInitialized)="onInitialized()"
  (onExporting)="onExporting($event)"
  [dataSource]="planProduction?.rows"
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [height]="utils.getWindowAvailableHeight(250)"
  columnResizingMode="widget"
  #grid>
  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
  <dxi-column alignment="right" dataField="atelier.libelle" caption="Atelier" [allowEditing]="false"
              [groupIndex]="0"></dxi-column>
  <dxi-column alignment="right"
              [dataField]="columnNameEquipe"
              caption="Équipe"
              cellTemplate="equipeCellTemplate"
              headerCellTemplate="updateHeaderCellTemplate"
              editCellTemplate="equipeEditCellTemplate"
              [visible]="isColumnTeamVisible()"
              [allowEditing]="isEditable()">
  </dxi-column>
  <dxi-column alignment="right" dataField="uniteDeProduction.libelle" caption="Unité de Production"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="produitDeclinaison.libelle" cellTemplate="produitDeclinaisonCellTemplate"
              [width]="250" caption="Nom de la fiche technique"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="produitDeclinaison.declinaison.libelle" caption="Déclinaison"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="offreAlimentairePrestation.libelle" caption="Prestation"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="pointDeLivraisonClient.libelle" caption="Point de livraison"
              [visible]="isColumnPlcVisible()" [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" dataField="repas.libelle" caption="Repas" [visible]="isColumnMealVisible()"
              [allowEditing]="false"></dxi-column>
  <dxi-column alignment="right" [dataField]="columnNameNbParts" caption="Nb. portions" cellTemplate="effCellTemplate"
              [allowEditing]="isEditable()"></dxi-column>
  <dxi-column alignment="right" dataField="poidsNetUT" caption="Poids net total" cellTemplate="weightCellTemplate"
              [allowEditing]="false"></dxi-column>

  <dxi-column alignment="right" [dataField]="columnNameDateFab" caption="Date de fab."
              headerCellTemplate="updateHeaderCellTemplate"
              dataType="date" cellTemplate="dateFab" [allowEditing]="isEditable()"></dxi-column>
  <dxi-column alignment="right" dataField="dateConso" caption="Date de conso."
              [visible]="isColumnConsumptionDateVisible()" [allowEditing]="false" dataType="date"
              cellTemplate="date"></dxi-column>
  <dxi-column alignment="right" [dataField]="columnNameDateDlc" caption="Date limite de conso."
              headerCellTemplate="updateHeaderCellTemplate"
              dataType="date" cellTemplate="dateDlc" [allowEditing]="isEditable()"></dxi-column>

  <dxo-summary>
    <dxi-total-item
      column="id"
      displayFormat="{0} éléments"
      showInColumn="produitDeclinaison.declinaison.libelle"
      summaryType="count">
    </dxi-total-item>
  </dxo-summary>

  <dxo-search-panel [visible]="true"></dxo-search-panel>

  <dxo-editing mode="cell" [allowUpdating]="isEditable()" [confirmDelete]="false"></dxo-editing>
  <dxo-selection
    [selectAllMode]="'allPages'"
    [showCheckBoxesMode]="'always'"
    mode="multiple">
  </dxo-selection>
  <dxo-column-chooser mode="select" [enabled]="true"></dxo-column-chooser>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling mode="virtual"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-header-filter [visible]="true"></dxo-header-filter>

  <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
    <div [title]="'Colonne modifiable : ' + cell.column.caption">
      {{ cell.column.caption }}
    </div>
  </div>

  <div *dxTemplate="let cell of 'date'">
    {{ cell.value | date:'E d LLL yyyy' | titlecase }}
  </div>

  <div *dxTemplate="let cell of 'effCellTemplate'">
    <i *ngIf="cell.row.data.nbParts == 0" class="fas fa-exclamation-triangle space yoni-color"
       [title]="getToolTipEffectifsZero(cell)">
    </i>
    {{ cell.value }} {{ cell.data.produitDeclinaison.uniteDeMesure.abreviation }}
  </div>

  <div *dxTemplate="let cell of 'weightCellTemplate'">
    {{ cell.value }} g
  </div>

  <div *dxTemplate="let cell of 'dateDlc'">
    <i *ngIf="dlcLessThanDateConsommation(cell.row.data)" class="fas fa-exclamation-triangle space yoni-color"
       [title]="getToolTipDlc(cell)">
    </i>
    {{ cell.value | date:'E d LLL yyyy' | titlecase }}
  </div>

  <div *dxTemplate="let cell of 'dateFab'">
    {{ cell.value | date:'E d LLL yyyy' | titlecase }}
  </div>

  <div *dxTemplate="let cell of 'equipeGroupCellTemplate'">
    Équipe: {{ cell.data.items[0].equipeLibelle }}
  </div>

  <div *dxTemplate="let cell of 'produitDeclinaisonCellTemplate'">
    <a (click)="displayParentsDishes(cell.data.produitDeclinaison?.id, cell.data.parent.id)"
       *ngIf="cell.data.parent && cell.data.parent.id && cell.data.parent.produitDeclinaison"
       title="Obtenir la liste des plats parents utilisant ce sous-plat">
      <i (click)="displayParentsDishes(cell.data.produitDeclinaison?.id, cell.data.parent.id)"
         class="fa fa-bars mg-r-10 cursor" aria-hidden="true"></i>
    </a>
    <a
      [title]="'Aller sur la fiche technique ' + cell.value + (cell.data.parent && cell.data.parent.id && cell.data.produitDeclinaison ? ' (' + cell.data.produitDeclinaison.produit?.typeProduit?.libelle + ')' : '') "
      (click)="goToProductPage(cell.data.produitDeclinaison?.produit?.id)">
      <span class="cursor save-color">{{ cell.value }} <span class="subrecipe-color"
                                                             title="Regroupé">{{ cell.data.parent && cell.data.parent.id && cell.data.produitDeclinaison ? '(' + cell.data.produitDeclinaison.produit?.typeProduit?.libelle + ')' : '' }}</span></span>
    </a>
  </div>

  <div *dxTemplate="let cell of 'equipeCellTemplate'">
    {{ cell.value }}
  </div>
  <div *dxTemplate="let cell of 'equipeEditCellTemplate'">
    <dx-lookup (onSelectionChanged)="changeTeam(cell.value?.id, cell.data?.id)" [(value)]="cell.value"
               [dataSource]="dataSourceTeams" [grouped]="true" displayExpr="equipeLibelle" itemTemplate="itemTpl"
               groupTemplate="groupTpl">
      <dxo-drop-down-options [showTitle]="false">
        <div *dxTemplate="let item of 'groupTpl'">
          <span [title]="item.key"><i class="fas fa-store mg-r-5"></i> {{ item.key }}</span>
        </div>
        <div *dxTemplate="let item of 'itemTpl'">
          <span [title]="item.equipeLibelle"><i class="fas fa-users mg-r-5"></i> {{ item.equipeLibelle }}</span>
        </div>
      </dxo-drop-down-options>
    </dx-lookup>
  </div>

</dx-data-grid>

<div class="d-flex mg-t-15 text-center" id="footer-step">
  <div class="col-lg-12 text-center">
    <yo-button (onClick)="previousStep()" label="Précédent" tooltip="Sélection des filtres"
               iconClass="fas fa-angle-left"></yo-button>
  </div>
</div>

<yo-print-settings
  [displayDialog]="displayPrintSettingsFichesTechniques"
  [datesConso]="datesConso"
  [datesFab]="datesFab"
  [equipes]="teams"
  [planProd]="planProduction" (onClose)="displayPrintSettingsFichesTechniques=$event">
</yo-print-settings>

<yo-bulk-edition-details-dialog></yo-bulk-edition-details-dialog>

<dx-popup
  title="Options d'affichage"
  [showTitle]="true"
  [height]="'auto'"
  [width]="550"
  (onHiding)="closeOptionsDisplayDialog()"
  [(visible)]="displayOptionsDisplayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row">
    <div class="col-md-12">
      <label>Détail des lignes par : </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueTeam" text="Equipe"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valuePlc" text="Point de livraison client"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueMeal" text="Repas"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueConsumptionDate" text="Date de consommation"></dx-check-box>
    </div>
  </div>
  <div class="row mg-t-15">
    <div class="col-md-12">
      <label>Affichage sous-recettes : </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <dx-check-box [(ngModel)]="displayOnlyMainRecipes" text="Ne pas afficher les sous-recettes"></dx-check-box>
    </div>
  </div>
  <div class="row float-right mg-t-15">
    <div class="col-md-12">
      <yo-button buttonClass="cta-success" (onClick)="applyGrouping()" tooltip="Enregistrer"
                 iconClass="fas fa-save"></yo-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  title="Liste des plats parents : "
  [showTitle]="true"
  [height]="'auto'"
  [width]="'50%'"
  (onHiding)="closeParentsDishes()"
  [(visible)]="displayParentsDishesDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <dx-data-grid
    id="gridDishesParents"
    [dataSource]="dishesParents"
    [allowColumnReordering]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    columnResizingMode="widget"
    #grid>
    <dxi-column alignment="right" dataField="produitDeclinaison.libelle" caption="Fiche technique"
                [allowEditing]="false"></dxi-column>
    <dxi-column alignment="right" dataField="formattedDateFab" caption="Date de fabrication"
                [allowEditing]="false"></dxi-column>
    <dxi-column alignment="right" dataField="nbParts" cellTemplate="nbPartsTemplate" caption="Nombre de parts"
                [allowEditing]="false"></dxi-column>
    <div *dxTemplate="let cell of 'nbPartsTemplate'">
      {{ cell.value }} {{ cell.data.produitDeclinaison?.uniteDeMesure?.abreviation }}
    </div>
  </dx-data-grid>
</dx-popup>

<dx-popup
  title="Génération d'une liste de besoin"
  [showTitle]="true"
  [height]="'auto'"
  [width]="650"
  (onHiding)="closeOptionsGenerationListeBesoin()"
  [(visible)]="displayOptionsGenerationListeBesoinDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row">
    <div class="col-md-12">
      <label>Il est possible de filtrer les denrées de votre future liste de besoins directement à partir des filtres
        proposés ci-dessous, cette possibilité
        est facultative, vous pouvez donc générer directement votre liste de besoins sans filtres en appuyant
        directement sur le bouton Générer.
        L'attribution d'un nom pour la future liste de besoins est également facultative, si ce dernier n'est pas
        renseigné, l'application le fera à votre place
        de manière automatique.
      </label>
    </div>
  </div>
  <div class="filters-list">
    <dx-text-box placeholder="Nom de la liste de besoins" [(value)]="newLabelListeBesoin"></dx-text-box>

    <yo-tagbox
      [data]="famillesPlats"
      (onValueChange)="onChangeParentFamilleProduitSelection($event)"
      [selectedItems]="selectedFamillesPlats"
      [placeholder]="'Filtrer par famille de produit...'"
    >
    </yo-tagbox>

    <yo-tagbox
      [data]="filtersLbde?.fournisseurs"
      (onValueChange)="onChangeFournisseur($event)"
      [selectedItems]="idsFournisseursSelected"
      [placeholder]="'Filtrer par fournisseur...'"
    >

    </yo-tagbox>

    <yo-tagbox
      [data]="filtersLbde?.taches"
      (onValueChange)="onChangeTache($event)"
      [selectedItems]="idsTachesSelected"
      [placeholder]="'Filtrer par type d\'approvisionnement...'"
    >
    </yo-tagbox>

    <dx-check-box
      [(ngModel)]="avoidQuantityZero"
      text="Exclure les ingrédients dont la quantité est à 0"
    >
    </dx-check-box>
  </div>
  <div class="dialog-line">
    <div class="custom-button-container-right">
      <yo-button (onClick)="simulationStock()" [label]="'Simulation des stocks'"></yo-button>
      <yo-button (onClick)="applyFiltersForGenerateListeBesoins(false)" tooltip="Générer"
                 iconClass="fas fa-box"></yo-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="newLabelListeBesoin ? 'Simulation des stocks - ' + newLabelListeBesoin + ' - du ' + formatDate(dateMin) + ' au ' + formatDate(dateMax) : 'Simulation des stocks - ' + planProduction.libelle + ' - du ' + formatDate(dateMin) + ' au ' + formatDate(dateMax)"
  [(visible)]="displaySimulationStocks"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeSimulationStock()"
>

  <div class="p-3 d-flex justify-content-between">
    <dx-text-box
      placeholder="Recherche denrée..."
      [showClearButton]="true"
      valueChangeEvent="keyup"
      [value]="searchDenree"
      (onValueChanged)="valueChanged($event)"
    ></dx-text-box>

    <yo-button (onClick)="applyFiltersForGenerateListeBesoins(true)" tooltip="Générer la liste des besoins"
               iconClass="fas fa-box"></yo-button>
  </div>

  <div class="p-3" style="scroll-behavior: auto;" id="paneVScroll">
    <dx-accordion
      [dataSource]="simulationStocks | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: simulationStocksInitial.length }"
      [collapsible]="true"
      [multiple]="false"
      id="accordion-container"
    >
      <div *dxTemplate="let rowData of 'title'">
        <div class="d-flex justify-content-between">
          <div class="col-lg-3 d-flex align-items-center">
            <span class="mg-r-5 bold">
              {{ rowData.produitDeclinaison.code }}
            </span>
            <span class="mg-r-5 bold">
              {{ rowData.produitDeclinaison.libelle }}
            </span>
          </div>

          <div class="col-lg-2" style="display: grid">
            <span>Besoin en UT : {{ rowData.besoinUT }} {{ rowData.uniteTechnique.abreviation }}</span>
          </div>

          <div class="col-lg-4" style="display: grid">
            <span>Qté prévisionnelle en stock : {{ rowData.quantityUT }} {{ rowData.uniteTechnique.abreviation }}
              (UT)</span>
          </div>

          <div class="col-lg-3 bold" style="display: grid">
            <span>Qté à commander : {{ rowData.quantityACommanderUT }} {{ rowData.uniteTechnique.abreviation }}
              (UT)</span>
          </div>
        </div>
      </div>

      <div *dxTemplate="let rowData of 'item'">

        <table class="table">
          <thead class="thead-light">
          <tr>
            <th>Date</th>
            <th>Motif</th>
            <th>Entrée en US</th>
            <th>Sortie en US</th>
            <!--<th>Stock en US</th>-->
          </tr>
          </thead>
          <tbody *ngFor="let step of rowData.steps">
          <tr>
            <td style="text-align: left" [title]="step.planProduction ? step.planProduction.libelle : ''">
              {{ step.date | date:'dd/MM/yy' }}
            </td>
            <td style="text-align: left" *ngIf="step.type === 'ENTREE' || step.type === 'SORTIE'">
              {{ step.description }}
            </td>
            <td style="text-align: left" *ngIf="step.type === 'STOCK'">
              {{ step.type }} - {{ step.description }}
            </td>
            <td style="text-align: left" *ngIf="step.type === 'ENTREE' || step.type === 'STOCK'">
              {{ step.quantityUS }} {{ step.abrUS }}
            </td>
            <td style="text-align: left" *ngIf="step.type === 'ENTREE' || step.type === 'STOCK'">

            </td>
            <td style="text-align: left" *ngIf="step.type === 'SORTIE'">

            </td>
            <td style="text-align: left" *ngIf="step.type === 'SORTIE'">
              {{ step.quantityUS }} {{ step.abrUS }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </dx-accordion>

    <pagination-controls
      class="text-center p-4"
      (pageChange)="handlePageChange($event)"
      [directionLinks]="false"
      responsive="true"
      previousLabel="Précédent"
      nextLabel="Suivant"></pagination-controls>

  </div>

</dx-popup>

<dx-popup
  [showTitle]="true"
  title="Denrées sans article préféré"
  [(visible)]="displayDenreesWithoutItems"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeDenreesWithoutItems()"
>
  <ng-container *ngIf="denreesWithoutItems.length">
    <div class="d-flex justify-content-between mb-2">
      <label>Veuillez ajouter des articles préférés pour les denrées affichées ci-dessous : </label>
      <yo-button *ngIf="typeGeneration === 'LISTE_BESOIN'" (onClick)="applyFiltersForGenerateListeBesoins(false, true)"
                 tooltip="{{titleButtonForceGeneration}}" label="{{titleButtonForceGeneration}}"></yo-button>
      <yo-button *ngIf="typeGeneration === 'SORTIES_PRODUCTION'" (onClick)="generateSortiesPlanProduction(true)"
                 tooltip="{{titleButtonForceGeneration}}" label="{{titleButtonForceGeneration}}"></yo-button>
    </div>
    <dx-data-grid
      [dataSource]="denreesWithoutItems"
      keyExpr="id"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column dataField="libelle" caption="Denrée" [filterOperations]="['contains']"
                  [allowFiltering]="true"></dxi-column>
      <dxi-column caption="Actions" [allowFiltering]="false" cellTemplate="templateActions"></dxi-column>

      <div class="actions" *dxTemplate="let cell of 'templateActions'">
        <a class="mg-r-15" target="_blank" (click)="goToUrl(cell.row.data.url)"
           title="Accéder à la page articles de la denrée"><i class="fa-solid fa-hand-pointer"></i></a>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} denrée(s) non configurée(s)">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
</dx-popup>

<dx-popup
  [showTitle]="true"
  title="Denrées avec ratio(s) manquant(s) au niveau des articles"
  [(visible)]="displayDenreesWithRatiosMisconfigured"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeDenreesWithRatiosMisconfigured()"
>
  <ng-container *ngIf="denreesWithRatiosMisconfigured.length">
    <label>Veuillez contrôler les ratios au niveau des articles pour les denrées affichées ci-dessous : </label>
    <dx-data-grid
      [dataSource]="denreesWithRatiosMisconfigured"
      keyExpr="id"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [allowColumnResizing]="true"
      [showRowLines]="true"
      [showBorders]="true"
      columnResizingMode="widget"
    >
      <dxi-column dataField="denreeInitialeLibelle" caption="Denrée" [filterOperations]="['contains']"
                  [allowFiltering]="true"></dxi-column>
      <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production" [filterOperations]="['contains']"
                  [allowFiltering]="true"></dxi-column>
      <dxi-column caption="Ratio(s)" [allowFiltering]="false" cellTemplate="templateRatios"></dxi-column>
      <dxi-column caption="Actions" [allowFiltering]="false" cellTemplate="templateActions" width="80"></dxi-column>

      <div class="actions" *dxTemplate="let cell of 'templateActions'">
        <a class="mg-r-15" target="_blank" [href]="cell.row.data.url" title="Accéder à la page articles de la denrée"><i
          class="fa-solid fa-hand-pointer"></i></a>
      </div>

      <div id="tplRatios" *dxTemplate="let cell of 'templateRatios'">
        <span>
          <i [class]="!cell.row.data.ratioUs ? 'fa-regular fa-circle-xmark' : 'fa-solid fa-check'"></i> Ratio unité de stockage
        </span>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} denrée(s) avec article(s) non configuré(s)">
      </dxo-pager>
    </dx-data-grid>
  </ng-container>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="popupErrorsTitle"
  [(visible)]="displayBonErrors"
  [height]="'auto'"
  width="auto"
  maxWidth="70%"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Attention : les dates de conditionnements des produits suivants sont postérieures à la date de livraison.</label>
  <dx-data-grid
    [dataSource]="printErrors"
    keyExpr="id"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="code" caption="Code"
                [allowFiltering]="true"></dxi-column>
    <dxi-column dataField="libelle" caption="Produit"
                [allowFiltering]="true"></dxi-column>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
  <div class="custom-button-container-right">
  <yo-button (onClick)="forcePrint()" label="Forcer l'impression" iconClass="fas fa-print"></yo-button>
  </div>
</dx-popup>

<yo-bon-livraison [idPlanProductionDetails]="idPlanProductionDetails" [idPlanProduction]="planProduction?.id"></yo-bon-livraison>
<yo-bon-allotissement [idPlanProductionDetails]="idPlanProductionDetails" [idPlanProduction]="planProduction?.id"></yo-bon-allotissement>
<yo-preparation-calcul-conditionnement-errors></yo-preparation-calcul-conditionnement-errors>
