import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../technique/http.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import ModeleConditionnementPlatDeclinaisonDTO
  from '../../dtos/conditionnement/modele-conditionnement-plat-declinaison-dto';

export const URL_GET_ALL_BY_ID_MCP = `dolrest/conditionnements/modeles-conditionnements-plats-declinaisons/listByIdMcp`;

@Injectable({
  providedIn: 'root'
})
export class ModelesConditionnementsPlatsDeclinaisonsService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer tous les modèles conditionnements plats déclinaisons en fonction d'un identifiant
   * de modèle de conditionnement de plat
   * @returns {Observable<ModeleConditionnementPlatDeclinaisonDTO>}
   */
  getAllByIdMcp(idMcp: number): Observable<ResponseWrapper<ModeleConditionnementPlatDeclinaisonDTO>> {
    if(idMcp) return this.httpSvc.get(`${URL_GET_ALL_BY_ID_MCP}/${idMcp}`, null);
    else return this.httpSvc.get(`${URL_GET_ALL_BY_ID_MCP}`, null);
  }

}
