<span id="container" *ngIf="emailHisto && emailHisto.statut">
<label
  [title]="emailHistoSvc.getLabelStatut(emailHisto.statut)"
  class="badge cursor {{emailHistoSvc.getEmailStatutBadge(emailHisto.statut)}}"
  (click)="openHistoriqueMails()">
        <span class="fa-layers fa-fw">
          <i class="fas fa-envelope mg-r-5"></i>
          <span class="fa-layers-counter">{{emailHistoSvc.getShortLabel(emailHisto.statut)}}</span>
        </span>
  </label>
</span>
