<!--TYPE DE CHAMP-->
<ng-container [ngSwitch]="field.controlType">

  <div class="form-group">

    <label [for]="field.key" class="genlabel no-overflow"
           [style.max-width.px]="250"
           [style.min-width.px]="250"
           class="font-10"
           [title]="getTooltip(field)"
           *ngIf="field.type!='hidden' && field.label.length > 0 " >
    <span [style.width.px]="6">
      <ng-container *ngIf="field.required"><span class="danger-color">*</span></ng-container>
      <ng-container *ngIf="!field.required">&nbsp;</ng-container>
    </span>{{field.label | uppercase}}
    </label>

    <div>

      <ng-container *ngSwitchCase="'checkbox'">
        <ng-container *ngTemplateOutlet="tplCheckbox;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'currency'">
        <ng-container *ngTemplateOutlet="tplCurrency;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'dropdown'">
        <ng-container *ngTemplateOutlet="tplDropdown;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'table'">
        <ng-container *ngTemplateOutlet="tplTable;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'tree'">
        <ng-container *ngTemplateOutlet="tplTree;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'textbox'">
        <ng-container *ngTemplateOutlet="tplTextbox;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'textarea'">
        <ng-container *ngTemplateOutlet="tplTextarea;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'selectbutton'">
        <ng-container *ngTemplateOutlet="tplSelectButton;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'primengeditor'">
        <ng-container *ngTemplateOutlet="tplPrimengEditor;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'calendar'">
        <ng-container *ngTemplateOutlet="tplCalendar;context:{field:field,form:form}"></ng-container>
      </ng-container>


      <ng-container *ngSwitchCase="'file'">
        <ng-container *ngTemplateOutlet="tplFile;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <yo-dynamic-form-field-error-bs [form]="form" [field]="field"></yo-dynamic-form-field-error-bs>

      <!-- Pour d'éventuels messages à l'utilisateur -->
      <!-- cf. ProduitDeclinaisonService.changeUniteDeMesure -->
      <yo-dynamic-form-field-message-bs [form]="form" [field]="field"></yo-dynamic-form-field-message-bs>
    </div>
  </div>

</ng-container>

<!--TEMPLATE CALENDAR-->
<ng-template #tplCalendar let-field="field" let-form="form">

  <div [formGroup]="form">
    <p-calendar formControlName="{{field.key}}"
                firstDayOfWeek="1"
                [style]="{'width':field.width+'px'}"
                [locale]="localeFr"
                [dateFormat]="field.dateFormat"
                [readonlyInput]="true"
                [showTime]="field.showTime"
                [disabled]="field.readonly"
                [showWeek]="true">
    </p-calendar>
  </div>

</ng-template>


<!--TEMPLATE CHECKBOX-->
<ng-template #tplCheckbox let-field="field" let-form="form">

  <div [formGroup]="form">
    <p-checkbox [formControl]="form.controls[field.key]" [disabled]="field.readonly" [id]="field.key"
                binary="true">
    </p-checkbox>
  </div>

</ng-template>

<!--TEMPLATE CURRENCY-->
<ng-template #tplCurrency let-field="field" let-form="form">

  <div class="input-group input-group-sm " [formGroup]="form">
    <input type="text" [style.max-width.px]="field.width" style="text-align: right;"
           class="form-control form-control-sm "
           id="{{field.key}}"
           placeholder=""
           (change)="changeInput($event)"
           pKeyFilter="pnum"
           formControlName="{{field.key}}" [readonly]="field.readonly">
    <div class="input-group-append ">
      <span class="input-group-text" *ngIf="field.suffix"
            [innerHTML]="dsSvc.bypassSecurityTrustHtml(field.suffix)"></span>
      <span class="input-group-text" *ngIf="!field.suffix">€</span>
      <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
    </div>
  </div>

</ng-template>

<!--TEMPLATE TABLE-->
<ng-template #tplTable let-field="field" let-form="form">

  <ng-container *ngIf="field.readonly; else notReadonly ">
    <dx-data-grid
      [allowColumnResizing]="true"
      [dataSource]="field.value"
      keyExpr="id"
      [height]="field.tableHeight"
      [showBorders]="false"
      [title]="field.help"
    >
      <ng-container *ngFor="let col of field.cols">
        <dxi-column [dataField]="col.field" [caption]="col.header"></dxi-column>
      </ng-container>
    </dx-data-grid>

  </ng-container>

  <ng-template #notReadonly>
    <dx-data-grid
      [allowColumnResizing]="true"
      [dataSource]="field.options"
      keyExpr="id"
      [height]="field.tableHeight"
      [hoverStateEnabled]="true"
      [selectedRowKeys]="selectedRowKeys"
      [showBorders]="true"
      (onFocusedRowChanged)="onSelectRowSingle($event)"
      [focusedRowEnabled]="true"
      [(focusedRowKey)]="selectedRowKeys"
    >
      <dxo-filter-row
        [visible]="true"
        [applyFilter]="[]"></dxo-filter-row>
      <dxo-paging [pageSize]="900"></dxo-paging>

      <dxo-selection mode="single"></dxo-selection>

      <ng-container *ngFor="let col of field.cols">
        <dxi-column [dataField]="col.field" [caption]="col.header"></dxi-column>
      </ng-container>

    </dx-data-grid>
  </ng-template>

</ng-template>

<!--TEMPLATE TREE-->
<ng-template #tplTree let-field="field" let-form="form">

  <ng-container *ngIf="field.readonly; else notReadonly ">
    <label class="badge badge-secondary text-white p-2 mg-b-3 font-12">{{getTreeLabel(field)}}</label>
  </ng-container>

  <ng-template #notReadonly>

    <div class="cursor">
      <ng-container *ngIf="utils.isNullOrEmpty(field.value)">
        <label class="cursor badge badge-secondary text-white p-2 mg-b-3 font-12"
               (click)="showTree()">Sélectionner une famille de produit...</label>
      </ng-container>
      <ng-container *ngIf="!utils.isNullOrEmpty(field.value)">
        <label class="cursor badge badge-secondary text-white p-2 mg-b-3 font-12"
               (click)="showTree()">{{getTreeLabel(field)}}</label>
      </ng-container>

    </div>

    <ng-container *ngIf="displayTree">
      <dx-tree-list
        id="parent-grid"
        [dataSource]="field.options"
        [showColumnHeaders]="false"
        [rootValue]="null"
        keyExpr="id"
        [width]="380"
        [height]="field.tableHeight"
        parentIdExpr="parent.id"
        [showRowLines]="true"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [focusedRowEnabled]="true"
        [(focusedRowKey)]="selectedRowKeys"
        (onFocusedRowChanged)="onSelectRowSingle($event)"
        [selectedRowKeys]="[field?.value?.id]"
        [expandedRowKeys]="this.expandedRowKeys">

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection mode="single"></dxo-selection>

        <ng-container *ngFor="let col of field.cols">
          <dxi-column [dataField]="col.field" [caption]="col.header"></dxi-column>
        </ng-container>
      </dx-tree-list>
    </ng-container>


  </ng-template>

</ng-template>

<!--TEMPLATE DROPDOWN-->
<ng-template #tplDropdown let-field="field" let-form="form">

  <div class="input-group input-group-sm " [formGroup]="form">

    <ng-container [ngSwitch]="field.key">

      <!--cas particulier couleur-->
      <ng-container *ngSwitchCase="'couleur'">
        <ng-container *ngTemplateOutlet="tplColor;context:{field:field,form:form}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>

        <ng-container [ngSwitch]="field.type">

          <ng-container *ngSwitchCase="'multiple'">

            <!--[style]="{'max-width':field.width+'px'}"-->
            <p-multiSelect
              formControlName="{{field.key}}"
              appendTo="body"
              [options]="field.options"
              (onChange)="changeMultiSelect($event)"
              optionLabel="{{field.optionLabel}}"
              selectedItemsLabel="{0} {{field.label.toLowerCase()}}"
              [defaultLabel]="field.label+'...'"
              [maxSelectedLabels]="4"
              [disabled]="field.readonly"
            ></p-multiSelect>
          </ng-container>

          <ng-container *ngSwitchCase="'externalRef'">
            <p-dropdown [style]="{'max-width':field.width+'px'}"
                        filter="true"
                        (onChange)="changeExternalRef($event)"
                        showClear="true"
                        placeholder="sélection..."
                        appendTo="body"
                        [options]="field.options"
                        [disabled]="field.readonly"
                        [formControlName]="field.key"
                        optionLabel="{{field.optionLabel}}"></p-dropdown>

          </ng-container>

          <ng-container *ngSwitchCase="'multipleListBox'">

            <p-listbox [options]="field.options"
                       multiple="true"
                       [style]="{'max-width':field.width+'px','min-width':field.width+'px'}"
                       (onChange)="changeDropdown($event)"
                       [disabled]="field.readonly"
                       checkbox="true"
                       filter="true"
                       [formControlName]="field.key"
                       optionLabel="{{field.optionLabel}}">
            </p-listbox>

          </ng-container>

          <ng-container *ngSwitchCase="'multipleListBoxSiteAndValue'">

            <p-listbox [options]="field.options"
                       multiple="true"
                       [style]="{'max-width':field.width+'px','min-width':field.width+'px'}"
                       (onChange)="changeDropdown($event)"
                       [disabled]="field.readonly"
                       checkbox="true"
                       filter="true"
                       [formControlName]="field.key"
                       optionLabel="{{field.optionLabel}}">
              <ng-template let-item pTemplate="item">

                <yo-site [siteLibelleTooltip]="item.site.libelle"
                         [siteId]="item.site.id">
                </yo-site>
                {{item.libelle | uppercase}}
              </ng-template>

            </p-listbox>

          </ng-container>

          <ng-container *ngSwitchCase="'singleListBox'">


            <p-listbox [options]="field.options"
                       [style]="{'max-width':field.width+'px','min-width':field.width+'px'}"
                       (onChange)="changeDropdown($event)"
                       [disabled]="field.readonly"
                       checkbox="false"
                       filter="true"
                       dataKey="id"
                       [formControlName]="field.key"
                       optionLabel="{{field.optionLabel}}">
            </p-listbox>

          </ng-container>

          <ng-container *ngSwitchDefault>
            <!--            <pre>{{field.dto.contratMenuConvive__contrainteAlimList | json}}</pre>-->
            <p-dropdown [style]="{'max-width':field.width+'px'}"
                        filter="true"
                        showClear="true"
                        placeholder="sélection..."
                        appendTo="body"
                        (onChange)="changeDropdown($event)"
                        [options]="field.options"
                        [disabled]="field.readonly"
                        [formControlName]="field.key"
                        optionLabel="{{field.optionLabel}}"></p-dropdown>

          </ng-container>

        </ng-container>
      </ng-container>

    </ng-container>

    <div class="input-group-append">
      <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
    </div>

  </div>

</ng-template>


<!--TEMPLATE TEXTBOX-->
<ng-template #tplTextbox let-field="field" let-form="form">

  <div [formGroup]="form">

    <ng-container [ngSwitch]="field.key">

      <!--cas particulier site-->
      <ng-container *ngSwitchCase="'site'">
        <ng-container *ngIf="field.type!=='hidden'">
          <yo-site [siteId]="field?.value?.id" [siteLibelle]="field?.value?.libelle"></yo-site>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="tplTextboxEnhanced;context:{field:field, form:form}"></ng-container>
      </ng-container>

    </ng-container>

  </div>

</ng-template>

<!--TEMPLATE TEXTAREA-->
<ng-template #tplTextarea let-field="field" let-form="form">

  <div [formGroup]="form">

    <ng-container [ngSwitch]="field.key">


      <ng-container *ngSwitchDefault>

        <ng-container
          *ngTemplateOutlet="tplTextareaEnhanced;context:{field:field, form:form}"></ng-container>

      </ng-container>
    </ng-container>

  </div>

</ng-template>

<!--TEMPLATE SELECT BUTTON-->
<ng-template #tplSelectButton let-field="field" let-form="form">

  <div [formGroup]="form">
    <p-selectButton [options]="field.options" [formControlName]="field.key"></p-selectButton>
  </div>

</ng-template>

<!--TEMPLATE EDITOR-->
<ng-template #tplPrimengEditor let-field="field" let-form="form">

  <div [formGroup]="form">

    <ng-container [ngSwitch]="field.key">
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="tplPrimengEditorEnhanced;context:{field:field, form:form}"></ng-container>
      </ng-container>
    </ng-container>

  </div>

</ng-template>


<!--TEMPLATE FILE-->
<ng-template #tplFile let-field="field" let-form="form">

  <div [formGroup]="form">

    <div class="row">
      <div class="col-md-12">
        <input type="file" [style.max-width.px]="field.width"
               [id]="field.key"
               (change)="onFileChange($event,field)"
               accept="image/jpeg, image/png"
               [readonly]="field.readonly">
      </div>
    </div>
    <div class="row mg-t-10">
      <ng-container *ngIf="!utils.isNullOrEmpty(previewFile)">
        <div class="col-md-auto">
          <p class="font-12 genlabel">Prévisualisation avant enregistrement</p>
          <img id="previewImage" src="#" height="64">
        </div>
      </ng-container>

      <div class="col">
        <ng-container *ngIf="!utils.isNullOrEmpty(previewFile)">
          <p class="font-12 genlabel">Image enregistrée</p>
        </ng-container>
        <yo-img-entity class="mg-r-5"
                       (onError)="docError=$event"
                       [displayNoImage]="true"
                       [ordre]="field.ordre"
                       [entityName]="field.entityName"
                       [height]="64"
                       [openable]="true"
                       [refresh]="field.refresh"
                       format="thumb"
                       [entityId]="getEntityId()"
                       [title]="getTitle()"></yo-img-entity>

        <!--n'afficher le bouton de suppression que pour les images qui existent réellement-->
        <ng-container *ngIf="!docError">
          <i style="vertical-align: top;" class="fas fa-times mg-r-20 danger-color cursor"
             (click)="deleteFile(field)" title="Enlever la {{field.label}}" ></i>
        </ng-container>

      </div>
    </div>
  </div>

</ng-template>


<!--TEMPLATE TEXTBOX AVEC PREFIX ET SUFFIX-->
<ng-template #tplTextboxEnhanced let-field="field" let-form="form">

  <div [formGroup]="form">

    <ng-container *ngIf="field.prefix && !field.suffix">
      <div class="input-group input-group-sm ">
        <div class="input-group-prepend " *ngIf="field.type!='hidden'">
          <span class="input-group-text" [innerHTML]="dsSvc.bypassSecurityTrustHtml(field.prefix)"></span>
        </div>
        <input [type]="field.type" [style.max-width.px]="field.width" class="form-control form-control-sm "
               [id]="field.key"
               (change)="changeInput($event)"
               [formControlName]="field.key" [readonly]="field.readonly">

        <div class="input-group-append " *ngIf="field.type!='hidden'">
          <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
        </div>

      </div>

    </ng-container>

    <ng-container *ngIf="!field.prefix && field.suffix">
      <div class="input-group input-group-sm ">
        <input [type]="field.type" [style.max-width.px]="field.width" class="form-control form-control-sm "
               [id]="field.key"
               (change)="changeInput($event)"
               [formControlName]="field.key" [readonly]="field.readonly">
        <div class="input-group-append " *ngIf="field.type!='hidden'">
          <span class="input-group-text" [innerHTML]="dsSvc.bypassSecurityTrustHtml(field.suffix)"></span>
          <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="field.suffix  && field.prefix">

      <div class="input-group input-group-sm ">
        <div class="input-group-prepend " *ngIf="field.type!='hidden'">
          <span class="input-group-text" [innerHTML]="dsSvc.bypassSecurityTrustHtml(field.prefix)"></span>
        </div>
        <input [type]="field.type" [style.max-width.px]="field.width" class="form-control form-control-sm "
               [id]="field.key"
               (change)="changeInput($event)"
               [formControlName]="field.key" [readonly]="field.readonly">
        <div class="input-group-append " *ngIf="field.type!='hidden'">
          <span class="input-group-text" [innerHTML]="dsSvc.bypassSecurityTrustHtml(field.suffix)"></span>
          <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!field.suffix && !field.prefix">
      <div class="input-group input-group-sm ">
        <input [type]="field.type" [style.max-width.px]="field.width" class="form-control form-control-sm"
               [id]="field.key"
               (change)="changeInput($event)"
               [formControlName]="field.key" [readonly]="field.readonly">

        <div class="input-group-append " *ngIf="field.type!='hidden'">
          <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
        </div>
      </div>
    </ng-container>


  </div>
</ng-template>

<!--TEMPLATE TEXTAREA-->
<ng-template #tplTextareaEnhanced let-field="field" let-form="form">
  <div [formGroup]="form">
    <ng-container>
      <div class="input-group input-group-sm ">
        <textarea

          [style.max-width.px]="field.width" class="form-control form-control-sm"
          [id]="field.key"
          [formControlName]="field.key" [readonly]="field.readonly"
          rows="4"
        >
        </textarea>
        <div class="input-group-append ">
          <ng-container *ngTemplateOutlet="tplIsFieldOk;context:{field:field,form:form}"></ng-container>
        </div>
      </div>

    </ng-container>
  </div>
</ng-template>

<!-- TEMPLATE COLOR -->
<ng-template #tplColor let-field="field" let-form="form">
  <!--<pre>{{field.options | json}}</pre>-->
  <div [formGroup]="form">
    <p-dropdown
      filter="true"
      [formControlName]="field.key"
      appendTo="body"
      placeholder="sélection..."
      optionLabel="{{field.optionLabel}}"
      [options]="field.options"
      [disabled]="field.readonly">
      <ng-template let-couleur pTemplate="selectedItem">
        <yo-couleur [hexColor]="couleur.hexa" class="mg-r-5"
                    style="width:16px;vertical-align:middle"></yo-couleur>
        <span style="vertical-align:middle; margin-left: .5em">{{couleur.libelle}}</span>
      </ng-template>
      <ng-template let-couleur pTemplate="item">
        <div style="position: relative;height: 25px;">
          <yo-couleur [hexColor]="couleur.hexa" class="mg-r-5"
                      style="width:24px;position:absolute;top:1px;left:5px"></yo-couleur>
          <div style="font-size:14px;margin-top:4px;margin-left:30px;">{{couleur.libelle}}</div>
        </div>
      </ng-template>
    </p-dropdown>

  </div>
</ng-template>

<!--TEMPLATE PRIMENG RICH TEXT EDITOR-->
<ng-template #tplPrimengEditorEnhanced let-field="field" let-form="form">
  <div [formGroup]="form">
    <ng-container>

      <div>
        <p-editor

          [style.max-width.px]="field.width" class="autoscroll"
          [id]="field.key"
          [formControlName]="field.key" [readonly]="field.readonly"
          [style]="{'height':'100px'}">
          <ng-template pTemplate="header">
            <div class="p-dialog-title">
              <yo-primeng-editor-header></yo-primeng-editor-header>
            </div>
          </ng-template>
        </p-editor>

      </div>

    </ng-container>
  </div>
</ng-template>

<!--CHAMP EST OK-->
<ng-template #tplIsFieldOk let-form="form" let-field="field">

  <label class="input-group-text icon-validation" [for]="field.key"
         [title]="field.label+' n\'est pas correctement renseigné'"
         *ngIf="field.type!='hidden' && form.controls[field.key].dirty && form.controls[field.key].invalid">
    <i class="fa fa-times danger-color "></i>
  </label>

</ng-template>
