import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {DxListComponent} from "devextreme-angular";

export type SelectionModeEnum = "single" | "multiple" | "all" | "none";
export type SelectionAllModeEnum = "page" | "allPages";

@Component({
  selector: 'yo-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() data: any[] = [];
  @Input() displayValue: string = "libelle";
  @Input() selectedItems: any = [];
  @Input() selectedItemKeys: any = [];
  @Input() key: string = "id";
  @Input() searchEnabled: boolean = true;
  @Input() selectionMode: SelectionModeEnum = "single";
  @Input() selectAllMode: SelectionAllModeEnum = "allPages";
  @Input() disabled: boolean = false;

  @Output() onValueChange = new EventEmitter;

  @ViewChild("dxList") dxList: DxListComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  valueChanged(data) {
    this.onValueChange.emit(this.dxList.selectedItems);
  }

}
