import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TypesProduitService} from '../../core/services/entities/types-produit.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {TypeProduitDTO} from "../../core/dtos/type-produit-dto";
import {Subscription} from "rxjs";
import {GraphQLService} from "../../core/services/technique/graphql.service";
import {UtilsService} from "../../core/utils/utils.service";
import {saveAs} from "file-saver";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {ResponseWrapper} from "../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../core/models/is-deletable-object";
import {confirm} from "devextreme/ui/dialog";
import {DxTooltipComponent} from "devextreme-angular";

@Component({
  selector: 'yo-types-produit',
  templateUrl: './types-produit.component.html',
  styleUrls: ['./types-produit.component.scss']
})
export class TypesProduitComponent implements OnInit, OnDestroy {

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/types-de-produit/types-de-produit';

  typesProduit: TypeProduitDTO[] = [];

  selectedRows: number[] = [];

  subGraphQL: Subscription;

  subXls: Subscription;

  subImport: Subscription;

  subTypeProduitSaved: Subscription;

  errors: any[] = [];

  @ViewChild("importTooltip") importTooltip: DxTooltipComponent;

  displayDeletePopupErrors: boolean = false;

  displayImportPopupErrors: boolean = false;

  constructor(public typeProduitSvc: TypesProduitService,
              private readonly graphqlSvc: GraphQLService,
              private utilsSvc: UtilsService,
              private readonly i8nSvc: InternationalizationService,
              private readonly toastSvc: ToastService) { }

  ngOnInit() {
    this.fetchData();
    this.subscribeTypeProduitSaved();
  }

  fetchData = (): void => {
    this.subGraphQL = this.graphqlSvc.sendQuery(`
      {
          allTypesProduit {
              id,
              site { id, libelle },
              libelle,
              code,
              fabrique,
              actif,
              alimentaire
          }
      }
    `)
      .subscribe((res) => {
        this.typesProduit = res.allTypesProduit;
      });
  }

  openDialogEdition = (typeProduit?: TypeProduitDTO): void => {
    this.typeProduitSvc.announceOpeningEditionDialog(typeProduit);
  }

  exportXslx = (): void => {
    this.subXls = this.typeProduitSvc.export(this.selectedRows)
      .subscribe(response => {
        const filename = `types-produit-export-${new Date().getTime()}.xlsx`;
        const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
        this.selectedRows = [];
        saveAs(blob, filename);
      });
  }

  canDelete = (): boolean => this.selectedRows?.length > 0;

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));


    if (!isDeletionValidated)
      return new Promise(null);

    this.typeProduitSvc.delete(this.selectedRows).subscribe(response => {
      const res: any = (response as ResponseWrapper<IsDeletableObject>).one;

      if (res) {
        if (res?.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const labelsError: any = this.i8nSvc.getLabelFromCode(error.code, null);
            const infosLine: string = labelsError.replace('{}', error.args);
            this.errors.push({infosLine});
            const elementToKeep = this.typesProduit.find(u => u.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, idsToDeleteInTable.length > 1 ? `Les types de produit ont bien été supprimés` : `Le type de produit a bien été supprimé`);
        }
        this.typesProduit = this.typesProduit.filter(typeProduit => !idsToDeleteInTable.find(id => id === typeProduit.id));
      }
    });
  }

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
    this.displayImportPopupErrors = false;
  }

  displayImportErrors = (): void => { this.displayImportPopupErrors = true; }

  importXslx = ($event: any): void => {
    this.errors = [];
    if ($event.target.files && $event.target.files.length) {
      const file: File = $event.target.files[0];
      this.subImport = this.typeProduitSvc.importFromXlsx(file)
        .subscribe((response) => {
          const res: any = response.one;
          if (res.allElementsImported) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le fichier a été importé avec succès`);
          } else {
            for (const item of Object.entries(res.reports)) {
              let values: any[] = Array.of(item[1]);
              const labelsError: any[] = values.map(val => val.map(v => this.i8nSvc.getLabelFromCode(v.code, v.args.split(','))).join(', '));
              this.errors.push({infosLine: item[0], labelsError});
            }
          }

          for (const item of res.elementsImported) {
            let index = this.typesProduit.findIndex(atelier => atelier.id == item.id);
            if (index >= 0)
              this.typesProduit[index] = item;
            else
              this.typesProduit.push(item)
          }

          if (this.errors?.length)
            this.displayImportErrors();

          $event.target.value = "";
          this.importTooltip.instance.hide();
        });
    }
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subGraphQL);
    this.utilsSvc.unsubscribe(this.subXls);
    this.utilsSvc.unsubscribe(this.subImport);
    this.utilsSvc.unsubscribe(this.subTypeProduitSaved);
  }

  calculateSiteValue = (typeProduit: TypeProduitDTO): string => typeProduit?.site?.libelle;

  subscribeTypeProduitSaved = (): void => {
    this.subTypeProduitSaved = this.typeProduitSvc.typeProduitSaved$
      .subscribe(() => this.fetchData());
  }

}
