<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <!--<yo-button
        iconClass="fa fa-plus"
        label="CRÉER"
        tooltip="Créer une implémentation d'un modèle de conditionnement plat"
        (onClick)="openEditObject(undefined)"
        [disabled]="!canEdit()">
      </yo-button>-->
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-trash"
        (onClick)="deleteValues()"
        [disabled]="!selectedRows.length"
        buttonClass="cta-delete"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="associationsList"
  keyExpr="udpMcpId"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="udpMcpId" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="udpLibelle" caption="Unité de production" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="mcpLibelle" caption="Modèle de conditionnement plat" [width]="300"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openEditObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="canEdit()?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="canEdit()?'Modifier le modèle':'Voir le modèle'">
    </yo-cell-button>
    <yo-cell-button (yoNavigation)="visualize(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fas fa-calculator"
                    yoTooltip="Visualiser l'implémentation du modèle">
    </yo-cell-button>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} association(s)">
  </dxo-pager>

</dx-data-grid>
<yo-dialog-edit-implementation-modele-conditionnement-produit></yo-dialog-edit-implementation-modele-conditionnement-produit>
