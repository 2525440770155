import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProduitDTO} from "../../../../../core/dtos/produit-dto";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import CustomStore from "devextreme/data/custom_store";
import {Observable, Subscription} from "rxjs";
import {UdpMcpProduitsService} from "../../../../../core/services/conditionnements/plat/udp-mcp-produits.service";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {UniteDeMesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {DeclinaisonDTO} from "../../../../../core/dtos/declinaison-dto";
import ModeleConditionnementPlatDTO from "../../../../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import {ProduitsService} from "../../../../../core/services/entities/produits.service";
import {DevextremeService, FilterItem} from "../../../../../core/services/technique/devextreme.service";
import {FamillesProduitService} from "../../../../../core/services/entities/familles-produit.service";
import {FamilleProduitDTO} from "../../../../../core/dtos/famille-produit-dto";
import {SiteDTO} from "../../../../../core/dtos/site-dto";
import {DeclinaisonsService} from "../../../../../core/services/entities/declinaisons.service";
import {TacheDTO} from "../../../../../core/dtos/tache-dto";
import {TachesService} from "../../../../../core/services/taches/taches.service";
import {RegimeAlimentaireService} from "../../../../../core/services/entities/regime-alimentaire.service";
import {RegimeAlimentaireDTO} from "../../../../../core/dtos/regime-alimentaire-dto";
import {
  DxDataGridComponent,
  DxNumberBoxComponent,
  DxTagBoxComponent,
  DxTextBoxComponent,
  DxTreeViewComponent,
} from "devextreme-angular";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {
  ImplementationModelesConditionnementsPlatsService
} from "../../../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {BindPlatsMcpModel} from "../../../../../core/models/gestion-conditionnements/bind-plats-mcp-model";
import {
  Udp_ModeleConditionnementPlat__ProduitDTO
} from "../../../../../core/dtos/unite-de-production_modele-conditionnement-plat__produit.dto";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {GraphQLService} from "../../../../../core/services/technique/graphql.service";
import {TypeProduitDTO} from "../../../../../core/dtos/type-produit-dto";
import DataSource from "devextreme/data/data_source";

@Component({
  selector: 'yo-dialog-ajout-mcp-plats',
  templateUrl: './dialog-ajout-mcp-plats.component.html',
  styleUrls: ['./dialog-ajout-mcp-plats.component.scss']
})
export class DialogAjoutMcpPlatsComponent implements OnInit, OnDestroy {

  allFamillesProduits: FamilleProduitDTO[] = [];
  uniteAConditionner: UniteDeMesureDTO;
  declinaisons: DeclinaisonDTO[] = [];
  sitesUtilisateurConnecte: SiteDTO[] = [];
  allDeclinaisons: DeclinaisonDTO[] = [];
  allTaches: TacheDTO[] = [];
  allRegimes: RegimeAlimentaireDTO[] = [];

  dataSource: CustomStore;

  treeBoxValue: string[];
  treeDataSource: any;

  private subTypeProduit: Subscription;
  subOpenDialog: Subscription;
  subFamillesProduits: Subscription;
  subDeclinaisons: Subscription;
  subTaches: Subscription;
  subRegimes: Subscription;

  openDialog = false;
  hasIDistri = false;
  displayFormNonNourri = false;
  dialogTitle = 'Liaison du modèle avec des plats';

  model: ModeleConditionnementPlatDTO;

  allMode: string;
  checkBoxesMode: string;

  @ViewChild("gridPlatsToAdd") grid: DxDataGridComponent;
  declinaisonIdList: number[] = [];
  tachesIdList: number[] = [];
  regimeIdList: number[] = [];
  familleProduitIdList: number[] = [];
  siteIdList: number[] = [];
  typesProduitList: TypeProduitDTO[] = [];
  code: string;
  platLibelle: string;
  optionsProduitsRatacheOptions: Object[];

  isActionDisabled: boolean = true;

  @ViewChild("dxTypeFab", {static: false}) dxTypeFab: DxTagBoxComponent;
  @ViewChild("dxRegime", {static: false}) dxRegime: DxTagBoxComponent;
  @ViewChild("dxFamilleProduit", {static: false}) dxFamilleProduit: DxTagBoxComponent;
  @ViewChild("dxTreeView", {static: false}) dxTreeView: DxTreeViewComponent;
  @ViewChild("dxSites", {static: false}) dxSites: DxNumberBoxComponent;
  @ViewChild("dxCode", {static: false}) dxCode: DxTextBoxComponent;
  @ViewChild("dxLabel", {static: false}) dxLabel: DxTextBoxComponent;

  selectAll: boolean = false;
  selectedTypeProduits: number[];
  selectedProduitRatache: number;

  constructor(public utils: UtilsService,
              private udpMcpProduitsSvc: UdpMcpProduitsService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private gds: GenericDatagridService,
              private produitSvc: ProduitsService,
              private dxSvc: DevextremeService,
              private familleProduitSvc: FamillesProduitService,
              private declinaisonEntitySvc: DeclinaisonsService,
              private tachesSvc: TachesService,
              private regimeEntitySvc: RegimeAlimentaireService,
              private implMcpSvc: ImplementationModelesConditionnementsPlatsService,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFamillesProduits);
    this.utils.unsubscribe(this.subDeclinaisons);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subTaches);
    this.utils.unsubscribe(this.subRegimes)
  }

  ngOnInit(): void {
    this.initTypeProduit();
    this.initOpenDialogSubscription();
    this.initHasGestionIDistri();
    this.loadFilterFamillesProduitsSubscription();
    this.loadFilterDeclinaisonsSubscription();
    this.loadFilterRegimesSubscription();
    this.loadFilterSites();
    this.loadFilterTachesSubscription();
    this.dataSource = this.initCustomStore();
    this.initOptionsProduitsRatacheOptions();
  }

  initOpenDialogSubscription = (): void => {
    this.subOpenDialog = this.udpMcpProduitsSvc.bindWithPlatsAsked$.subscribe(model => {
      this.openDialog = true;
      this.displayFormNonNourri = model.mode === 'PIECE_NON_PROPORTIONNELLE';
      this.model = model;
    });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  closeDialog = (): void => {
    this.dxTypeFab.instance.reset();
    this.dxRegime.instance.reset();
    this.dxFamilleProduit.instance.reset();
    this.dxSites.instance.reset();
    this.dxCode.instance.reset();
    this.dxLabel.instance.reset();
    this.dxTreeView?.instance?.unselectAll();
    this.grid.instance.deselectAll();
    this.grid.instance.state(null);
    this.familleProduitIdList = [];
    this.openDialog = false;
  };

  loadFilterFamillesProduitsSubscription = (): void => {
    this.subFamillesProduits = this.gds.getAll(this.familleProduitSvc.getEntityName(), this.familleProduitSvc.getSort(), true).subscribe(response => {
      this.allFamillesProduits = response.resultList.filter((r: FamilleProduitDTO) => r.fabrique === true);
    });
  };

  loadFilterDeclinaisonsSubscription = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subDeclinaisons = this.graphQlSvc.sendQuery(`
      {
          allDeclinaisons(filters: {
          siteIds: [${idsSites}]
        }) {
              id,
              libelle
          }
      }
    `).subscribe(response => {
      this.allDeclinaisons = response.allDeclinaisons;
    });
  };

  loadFilterRegimesSubscription = (): void => {
    this.subRegimes = this.getRegimesAlimentaires().subscribe(response => {
      this.allRegimes = response.allRegimesAlimentaires;
    });
  };

  getRegimesAlimentaires = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allRegimesAlimentaires(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
        }
      }
    `);
  };

  loadFilterTachesSubscription = (): void => {
    this.subTaches = this.tachesSvc.getAll()
      .subscribe(response => {
        this.allTaches = response.resultList;
      });
  };

  loadFilterSites = (): void => {
    this.sitesUtilisateurConnecte = this.auth2Svc.utilisateur.sites;
  };

  onChangeDeclinaisonFilter = ($event): void => {
    this.declinaisonIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeTypeFabricationFilter = ($event): void => {
    this.tachesIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeRegimeFilter = ($event): void => {
    this.regimeIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeFamilleProduitFilter = ($event): void => {
    this.familleProduitIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeSiteFilter = ($event): void => {
    this.siteIdList = $event.value;
    this.grid.instance.refresh();
  };

  onChangeCodeFilter = ($event): void => {
    this.code = $event.value;
    this.grid.instance.refresh();
  };

  onChangePlatLibelleFilter = ($event): void => {
    this.platLibelle = $event.value;
    this.grid.instance.refresh();
  };

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  onChangeParentDenreeSelection = (event: any): void => {
    const selectedNodeKeys: any = event.component.getSelectedNodeKeys();
    this.familleProduitIdList = this.getIdFamilleProduitListSelected(selectedNodeKeys);
    this.grid.instance.refresh();
  }

  getIdFamilleProduitListSelected = (idList: number[]): number[] => {
    let result = [];

    idList.forEach(id => {
      this.allFamillesProduits.forEach(fp => {
        if (!this.utils.isNullOrEmpty(fp.parent) && fp.parent.id === id) {
          result.push(fp.id);
          result = [...result].concat(this.reflexionSearchId([...this.allFamillesProduits], fp.id));
        }
      })
    });

    result = [...result].concat(idList);
    return [...new Set(result)];
  }

  reflexionSearchId = (familleProduitList: FamilleProduitDTO[], idSearch: number): number[] => {
    let idResultList: number[] = [];
    familleProduitList.forEach(item => {
      if (!this.utils.isNullOrEmpty(item.parent) && item.parent.id === idSearch) {
        idResultList.push(item.id);
        idResultList = [...idResultList].concat(this.reflexionSearchId([...familleProduitList], item.id));
      }
    });
    return idResultList;
  }

  initCustomStore = (): CustomStore => new CustomStore({
    key: 'id',
    load: async (loadOptions: any) => {
      let pageSize: number = loadOptions.take || this.grid.instance.pageSize();
      let page: number = this.grid.instance.pageIndex() + 1;
      const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

      if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id' && this.selectAll) {
        // Si je coche tout => Il faut omettre la pagination
        page = null;
        pageSize = null;
      }

      const args = {
        code: this.code || '',
        label: this.platLibelle || '',
        declinaisonIds: this.declinaisonIdList,
        familleProduitIds: this.familleProduitIdList,
        regimeIds: this.regimeIdList,
        sitesIds: this.siteIdList.length ? this.siteIdList : this.auth2Svc.utilisateur.sites.map(item => item.id),
        tachesIds: this.tachesIdList,
        typesProduitsIds: this.selectedTypeProduits || '',
        produitRatache: this.selectedProduitRatache || "both",
        page,
        size: pageSize,
      };

      return this.produitSvc.searchByFilters(args)
        .toPromise()
        .then(response => {
          let data = response.resultList;
          let totalCount = response.totalElements;
          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;
          return {data, totalCount};
        });
    }
  });

  canSave = (): boolean => this.hasIDistri;

  save = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    if (!rowsSelected.length) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner au minimum un plat à ajouter`);
    } else {
      this.implMcpSvc.saveMultiple({mcpId: this.model.id, platsIdList: rowsSelected} as BindPlatsMcpModel)
        .subscribe(response => {
          const productsAlreadyUsed: ProduitDTO[] = response.resultList;
          if (productsAlreadyUsed?.length)
            this.implMcpSvc.announceProductsAlreadyUsed(productsAlreadyUsed);
          this.implMcpSvc.announceBindPlatsDone();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Plat(s) associé(s) avec succès`);
          this.closeDialog();
        });
    }
  };

  cellClick = (e: any) => this.selectAll = (e.rowType === 'header');

  private initTypeProduit = (): void => {
    this.subTypeProduit = this.graphQlSvc.sendQuery(`
      {
          allTypesProduit(filters: {
            fabrique: true}) {
              id,
              libelle,
              site { id, libelle },
              code,
              fabrique,
              actif
          }
      }
    `)
      .subscribe((res) => {
        this.typesProduitList = res.allTypesProduit;
      });
  }

  onChangeproduitRatache(data: number) {
    this.selectedProduitRatache = data
    this.grid.instance.refresh();
  }


  onChangeTypeProduit(data: number[]) {
    this.selectedTypeProduits = data;
    this.grid.instance.refresh();
  }

  private initOptionsProduitsRatacheOptions() {
    this.optionsProduitsRatacheOptions = [
      {id: "both", libelle: "Tous produits (liés ou non à un modèle de plat)"},
      {id: "linked", libelle: "Produits liés à un modèle de plat"},
      {id: "notLinked", libelle: "Produits non liés à un modèle de plat"},
    ];
  }

}
