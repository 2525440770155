import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {
  PreparationConditionnementService
} from "../../../../core/services/conditionnements/preparation-conditionnement.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";

@Component({
  selector: 'yo-preparation-calcul-conditionnement-errors',
  templateUrl: './preparation-calcul-conditionnement-errors.component.html',
  styleUrls: ['./preparation-calcul-conditionnement-errors.component.scss']
})
export class PreparationCalculConditionnementErrorsComponent implements OnInit, OnDestroy {

  errors: any = {};
  plcsNotTakenAccount: PointDeLivraisonDTO[];
  displayErrors: boolean = false;
  private subErrors: Subscription;

  constructor(public readonly utils: UtilsService,
              private readonly pcSvc: PreparationConditionnementService) {
  }

  ngOnInit(): void {
    this.initCalculErrors();
  }
  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subErrors);  }

  closeErrors = () => {
    this.displayErrors = false;
  };

  goToPlcPage = (idClient: number, idPlc: number) => window.open(`/gestion-clients(secondary:point-de-livraison/${idClient}/${idPlc}/conditionnement/regles-regroupements)`, '_blank');

  goToProductPage = (idProduct: number) => window.open(`/gestionproduits(secondary:gestionproduits/produit/true/${idProduct}/conditionnement)`, '_blank');


  private initCalculErrors() {
      this.subErrors = this.pcSvc.calculateConditionnementErrors$.subscribe((response) => {
        this.errors.plcsWithoutRulesConfiguration = response.plcsWithoutRulesConfiguration ? response.plcsWithoutRulesConfiguration.map(val => {
          let tmp: string[] = val.split(",");
          return {id: parseInt(tmp[0]), idClient: parseInt(tmp[1]), libelle: tmp[2]}
        }) : [];
        this.errors.contraintesPlcsNotFound = response.contraintesPlcsNotFound || [];
        this.errors.implementationsModelesPlatsNotRealized = response.implementationsModelesPlatsNotRealized ? response.implementationsModelesPlatsNotRealized.map(val => {
          let tmp: string[] = val.split(",");
          return {id: parseInt(tmp[0]), libelle: tmp[1]}
        }) : [];
        this.plcsNotTakenAccount = response.plcsNotTakenAccount;
        this.displayErrors = true;
      })
    }
}
