import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {UtilsService} from "../../../core/utils/utils.service";
import {CommunicationProduitService} from "../../../core/services/entities/referentiel.base/communication-produit-service";
import {ProduitDeclinaisonDTO} from "../../../core/dtos/produit-declinaison-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {RowUpdatingEvent} from "devextreme/ui/data_grid";
import {DxDataGridComponent} from "devextreme-angular";
import {CommunicationProduitValueDTO} from "../../../core/dtos/produit/CommunicationProduitValueDTO";

@Component({
  selector: 'yo-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
  host: {
    class: 'full-size'
  }
})
export class CommunicationComponent implements OnInit, OnDestroy {

  idProduit: number;
  isLoading: boolean;

  communication: CommunicationProduitValuesContainer[] = [];
  produitDeclinaisonList: ProduitDeclinaisonDTO[] = [];

  selectedRows: number[] = [];

  toSave: CommunicationProduitValueDTO[] = [];

  subRoute: Subscription;

  @ViewChild("grid") grid: DxDataGridComponent;

  constructor(public utilsSvc: UtilsService,
              private router: Router,
              private communicationProduitSvc: CommunicationProduitService,
              private auth2Svc: Auth2Service) {
  }

  ngOnInit() {
    this.initRouteSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subRoute);
  }

  initRouteSubscription = () => {
    const regex = new RegExp("\\/(?:true|false)\\/([0-9]+)\\/");
    this.idProduit = parseInt(regex.exec(this.router.url)[1]);
    this.initCommunicationProduitValueList();
  };

  initCommunicationProduitValueList = () => {
    this.communicationProduitSvc.getCommunicationProduitValuesForProduit(this.idProduit).subscribe(result => {
      this.communication = [];
      let pdList = new Map<number, ProduitDeclinaisonDTO>();

      result.forEach(com => {
        let item = this.communication.find(c => c.idCommunicationProduit == com.communicationProduit.id);

        if (!item) {
          item = new CommunicationProduitValuesContainer();
          item.idCommunicationProduit = com.communicationProduit.id;
          item.libelleCommunicationProduit = com.communicationProduit.libelle;
          this.communication.push(item);
        }
        item[this.getProduitDeclinaisonTextProperty(com.produitDeclinaison)] = com;
        pdList.set(com.produitDeclinaison.id, com.produitDeclinaison);
      });

      if (this.communication)
        this.produitDeclinaisonList = Array.from(pdList, ([index, value]) => value);
    });
  }

  getProduitDeclinaisonTextProperty = (declinaison: ProduitDeclinaisonDTO): string => {
    return "communicationProduitValue_".concat(declinaison.libelle);
  }

  save = () => {
    this.waitUntilEditEnd().then(() => {
      this.communicationProduitSvc.saveValues(this.toSave).subscribe(result => {
          if (result && result.resultList) {
            result.resultList.forEach(com => {
              this.communication.find(c => c.idCommunicationProduit == com.communicationProduit.id)[this.getProduitDeclinaisonTextProperty(com.produitDeclinaison)] = com;
            })
            this.toSave = [];
          }
        }
      );
    })
  }

  private waitUntilEditEnd = (): Promise<void> => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!this.isEditing()) {
          clearInterval(interval);
          resolve();
        }
      }, 100)
    });
  }

  private isEditing = (): boolean => {
    return this.grid.editing.editColumnName && this.grid.editing.editRowKey;
  }

  canEdit = (declinaison: ProduitDeclinaisonDTO) => {
    const idsSites: number[] = this.auth2Svc.utilisateur.siteListLocaux.map(s => s.id);
    return idsSites.includes(declinaison.site.id);
  };

  addCellToSaving = ($event: RowUpdatingEvent) => {
    const colName = this.grid.editing.editColumnName.replace(".text", "");
    const com = this.communication.find(item => item.idCommunicationProduit == this.grid.editing.editRowKey);
    let cell = com[colName];
    if (!this.toSave.includes(cell))
      this.toSave.push(cell);
  }

}

class CommunicationProduitValuesContainer {
  idCommunicationProduit: number;
  libelleCommunicationProduit: string;
}

