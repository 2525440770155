import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {ContratMenuConviveDTO} from '../core/dtos/contratmenuconvive-dto';
import {MenusToolbarService} from '../core/services/gestionmenus/menus-toolbar.service';
import {ContratmenuDTO} from '../core/dtos/contratmenu-dto';

@Injectable({
  providedIn: 'root'
})
export class GestionmenusResolverService implements Resolve<GestionMenusSupplier> {

  constructor(private menusToolbarSvc:MenusToolbarService,
             ) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GestionMenusSupplier> | Promise<GestionMenusSupplier> | GestionMenusSupplier => {

     const gmSupplier: GestionMenusSupplier = new GestionMenusSupplier();

    const prepareToolbarMenus$ = this.menusToolbarSvc.prepareToolbarMenus();
    const all$ = combineLatest([prepareToolbarMenus$]);

    return all$.toPromise().then(response=>{
      gmSupplier.prestations = response[0].additionalProperties['prestations'];
      gmSupplier.contrats = response[0].additionalProperties['contrats'];

      gmSupplier.prestations.sort((a, b) => a.libelle.localeCompare(b.libelle));
      gmSupplier.contrats.sort((a, b) => a.libelle.localeCompare(b.libelle));

      return gmSupplier;
    });

  };
}

export class GestionMenusSupplier {

  prestations:ContratMenuConviveDTO[]=[];
  contrats:ContratmenuDTO[]=[];
}
