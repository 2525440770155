<div class="custom-toolbar">
  <div class="custom-toolbar-element custom-button-container-right">
    <yo-button
      buttonClass="cta-success"
      iconClass="fa fa-save"
      (onClick)="save()">
    </yo-button>
  </div>
</div>

<dx-data-grid
  #grid
  [dataSource]="communication"
  keyExpr="idCommunicationProduit"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [height]="utilsSvc.getWindowAvailableHeight(194)"
  width="100%"
  [(selectedRowKeys)]="selectedRows"
  columnResizingMode="widget"
  [loadPanel]="{enabled: isLoading}"
  [columnWidth]="produitDeclinaisonList?.length >= 3 ? 400 : undefined"
  (onRowUpdating)="addCellToSaving($event)">
  <dxo-paging [enabled]=" false"></dxo-paging>
  <dxo-editing
    mode="cell"
    [allowUpdating]="true"
    [allowAdding]="false"
    [allowDeleting]="false">
  </dxo-editing>
  <dxi-column dataField="libelleCommunicationProduit"
              caption="Communication produit"
              width="250"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              [allowEditing]="false"
              [fixed]="true">
  </dxi-column>
  <dxi-column *ngFor="let declinaison of produitDeclinaisonList"
              [caption]="declinaison.libelle"
              dataField="{{getProduitDeclinaisonTextProperty(declinaison)}}.text"
              [allowEditing]="canEdit(declinaison)"
  ></dxi-column>
</dx-data-grid>
