import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpService} from "../technique/http.service";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {UtilsService} from "../../utils/utils.service";
import {saveAs as fs_saveAs} from "file-saver";
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {URL_POST_PRINT_FICHE_TECHNIQUE} from "./produits.service";

@Injectable({
  providedIn: 'root'
})
export class FacturationService {

  private subjectOpenDialogJoinBonReception = new Subject<any>();

  openDialogJoinBonReception$ = this.subjectOpenDialogJoinBonReception.asObservable();

  private subjectRefreshInvoices = new Subject<any>();

  refreshInvoices$ = this.subjectRefreshInvoices.asObservable();

  private subjectOpenDialogDetailsInvoice = new Subject<any>();

  openDialogDetailsInvoice$ = this.subjectOpenDialogDetailsInvoice.asObservable();

  constructor(private httpSvc: HttpService,
              private utilsSvc: UtilsService) {}

  printInvoices = (ids: number[]) => {
      this.httpSvc.get(`dolrest/facturation/print-invoices?ids=${ids}`, null, 'blob', new HttpHeaders().set('Accept', 'application/pdf'))
        .subscribe(response => {
          const blob = new Blob([response], {type: 'application/pdf'});
          fs_saveAs(blob, `factures-${new Date().getTime()}`);
      });
  }

  bindInvoice = (idBonReception: number, idProvider: number, numBill: string, dateBill: Date) => {
    const url = `dolrest/facturation/bind-invoice?idBonReception=${idBonReception}&idProvider=${idProvider}&numBill=${numBill || ''}${dateBill ? "&dateBill=" + dateBill.toDateString() : ''}`;
    return this.httpSvc.post(url, null);
  }

  bindInvoices = (idsBonReception: number[], idProvider: number, numBill: string, dateBill: Date) => {
    const url = `dolrest/facturation/bind-invoice-multiple?idsBonReception=${idsBonReception}&idProvider=${idProvider}&numBill=${numBill || ''}&dateBill=${dateBill || ''}`;
    return this.httpSvc.post(url, null);
  }

  fetchFiltersSearch: () => Observable<ResponseWrapper<any>> = () => this.httpSvc.get(`dolrest/facturation/filters-search`);

  fetchInvoicesByFilters = (sitesSelected?: number[], numsBill?: string[], providersIds?: number[], status?: string[], page?: number, size?: number) => {
    let url = `dolrest/facturation/fetchByFilters?1=1`;
    if (sitesSelected) url += `&idsSites=${sitesSelected}`;
    if (numsBill) url += `&numsBill=${numsBill}`;
    if (providersIds) url += `&providersIds=${providersIds}`;
    if (status) url += `&status=${status}`;
    if (page !== null && page !== undefined && size !== null && size !== undefined) url += `&page=${page}&size=${size}`;
    return this.httpSvc.get(url);
  }

  fetchNumberInvoiceFromOrderReceipt = (idBonReception: number): Observable<ResponseWrapper<string>> => this.httpSvc.get(`dolrest/facturation/fetch-invoice-from-order-receipt/${idBonReception}`);

  fetchNumbersInvoiceInWaitingValidation = (idSiteBonReception: number, idFournisseur: number): Observable<ResponseWrapper<string>> => this.httpSvc.get(`dolrest/facturation/numbers-invoice-waiting-validation?idSiteBonReception=${idSiteBonReception}&idFournisseur=${idFournisseur}`);

  update = (currentBill: any): Observable<ResponseWrapper<any>> => {
    let formData = new FormData();
    formData.set('invoiceUpdated', JSON.stringify(currentBill));
    return this.httpSvc.put(`dolrest/facturation/update`, formData);
  }

  validInvoices = (invoices: number[]): Observable<ResponseWrapper<any>> => this.httpSvc.put(`dolrest/facturation/valid-invoices?ids=${invoices}`, null);

  deleteInvoices = (invoices: number[]): Observable<ResponseWrapper<any>> => this.httpSvc.delete(`dolrest/facturation/delete-invoices?ids=${invoices}`, null);

  fetchById = (id): Observable<any> => this.httpSvc.get(`dolrest/facturation/fetch/${id}`);

  fetchBonsReceptionsForBilling = (idsProvider?: number[], startDelivery?: Date, endDelivery?: Date, currentIdSite?: number): Observable<ResponseWrapper<any[]>> => {
    let url: string = `dolrest/facturation/bons-receptions-for-billing?1=1`;
    if (idsProvider && idsProvider.length)
      url += `&idsProviders=${idsProvider}`;
    if (startDelivery)
      url += `&startDelivery=${this.utilsSvc.convertYYYYMMdd(startDelivery.getTime())}`;
    if (endDelivery)
      url += `&endDelivery=${this.utilsSvc.convertYYYYMMdd(endDelivery.getTime())}`;
    if (currentIdSite)
      url += `&idSite=${currentIdSite}`;
    return this.httpSvc.get(url);
  }

  announceOpenDialogJoinBonReception = (invoice: any): void => {
    this.subjectOpenDialogJoinBonReception.next(invoice);
  }

  announceRefreshInvoices = (): void => {
    this.subjectRefreshInvoices.next(null);
  }

  announceOpenDialogDetailsInvoice = (currentInvoice: any): void => {
    this.subjectOpenDialogDetailsInvoice.next(currentInvoice);
  }

}
