<div class="d-flex flex-column">
  <div class="mg-b-15">
    <h5 class="mg-b-0 mg-r-15">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [width]="800"></yo-help>
      <label class="badge badge-secondary">
        {{'SORTIES DE PRODUCTION - ' + planProduction?.libelle | uppercase }}
      </label>
    </h5>
    <div class="d-flex float-right justify-content-between  mg-t-5">
      <div class="mg-r-5">
        <yo-button label="Valider les sorties sélectionnée(s)" iconClass="fas fa-check"
                   [disabled]="!selectedDetails.length" (onClick)="validerSorties()"></yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          tooltip="Appliquer les modifications aux ligne(s) selectionnée(s)"
          label="Traitement en masse"
          iconClass="fas fa-edit"
          [disabled]="!selectedDetails.length"
          (onClick)="openMassiveTreatmentDialog()">
        </yo-button>
      </div>
      <div class="mg-r-5">
        <yo-button
          tooltip="Options d'affichage"
          iconClass="fa fa-desktop"
          (onClick)="openOptionsDisplayDialog()">
        </yo-button>
      </div>
      <div>
        <yo-button
          iconClass="fas fa-print"
          [disabled]="isDisabledPrintBtn()"
          (onClick)="openPrintSortiesProductionSettings()">
        </yo-button>
      </div>
    </div>
  </div>

  <div>
    <dx-tab-panel
      #tabPanel
      [dataSource]="chooseView"
      [selectedItem]="selectedVue"
      [loop]="false"
      [animationEnabled]="true"
      [swipeEnabled]="true"
      (onSelectionChanged)="changeView($event)"
    >
      <div *dxTemplate="let view of 'title'">
        <span>{{ view.label }}</span>
      </div>
      <div *dxTemplate="let view of 'item'">
        <div class="tabpanel-item">
          <ng-container [ngSwitch]="view.value">

            <ng-container *ngSwitchCase="VUE_SORTIES_POUR_PRODUCTION.DENREE">
              <ng-container *ngTemplateOutlet="tplVueDenree"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="VUE_SORTIES_POUR_PRODUCTION.STOCK">
              <ng-container *ngTemplateOutlet="tplVueStock"></ng-container>
            </ng-container>

          </ng-container>
        </div>
      </div>

    </dx-tab-panel>

  </div>

  <div class="d-flex mg-t-3">
    <div class="mg-r-20">
      <i class="fas fa-square col-editable-color mg-r-5 border"></i>La colonne est modifiable
    </div>
    <div class="mr-auto">
      <strong>{{utils.getTotalRecordsLabel('sortie', planProduction?.sorties.length)}}</strong>
    </div>
  </div>

  <div class="d-flex mg-t-3 text-center" id="footer-step">
    <div class="col-lg-12 text-center">
      <yo-button
        (onClick)="generatePlanProduction()"
        buttonClass="cta-edit-or-watch"
        label="Précédent"
        tooltip="Génération du plan de production"
        iconClass="fas fa-angle-left">
      </yo-button>
    </div>
  </div>

</div>

<!--TEMPLATE VUE DENREE-->
<ng-template #tplVueDenree>

  <dx-data-grid
    [dataSource]="planProduction?.sorties"
    keyExpr="id"
    [height]="700"
    width="100%"
    [hoverStateEnabled]="true"
    [showBorders]="true"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditingStart)="onEditingStart($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    #grid>

    <dxi-column dataField="status" caption="Statut" alignment="center" cellTemplate="tplStatut" width="100"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="dateSortie" dataType="date" caption="Date de sortie" alignment="left"
                cellTemplate="tplDateSortie" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="uniteProductionEquipe.uniteDeProductionLibelle" caption="Unité de production"
                alignment="left" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="uniteProductionEquipe.zoneDeProductionLibelle" caption="Atelier" alignment="left"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="zoneDeStockage.libelle" caption="Zone de stockage" alignment="left"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="denree.libelle" caption="Déclinaison" alignment="left" [allowEditing]="false"></dxi-column>
    <dxi-column alignment="left" dataField="pointDeLivraison.libelle" caption="Point de livraison"
                [visible]="isColumnPlcVisible()" [allowEditing]="false"></dxi-column>
    <dxi-column alignment="left" dataField="repas.libelle" caption="Repas" [visible]="isColumnMealVisible()"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="dateConsommation" dataType="date" caption="Date de consommation" alignment="left"
                cellTemplate="tplDateConso" [visible]="isColumnDateConsoVisible()" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUniteTechnique.value" caption="Qté Prévue UT" alignment="right"
                cellTemplate="tplQuantitePrevueUt" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUniteStockage.value" caption="Qté Prévue US" alignment="right"
                cellTemplate="tplQuantitePrevueUs" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantiteReelleUniteStockage.value" caption="Qté Réelle US" alignment="right"
                headerCellTemplate="updateHeaderCellTemplate" cellTemplate="tplQuantiteReelleUs" [allowEditing]="true">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" [min]="0"
                           message="La Qté Réelle US doit être supérieure ou égale à 0"></dxi-validation-rule>
    </dxi-column>

    <dxo-selection
      [selectAllMode]="true"
      [showCheckBoxesMode]="'always'"
      mode="multiple"
    ></dxo-selection>

    <dxo-editing
      mode="cell"
      [allowUpdating]="true">
    </dxo-editing>

    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Rechercher..."></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>

    <dxo-header-filter
      [visible]="true"></dxo-header-filter>

    <dxo-filter-builder>
    </dxo-filter-builder>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder-popup
      [position]="popupPosition">
    </dxo-filter-builder-popup>

    <div *dxTemplate="let cell of 'tplQuantitePrevueUt'">
      {{cell.row.data.quantitePrevueUniteTechnique.value | number:'1.4-4'}} <span
      [title]="cell.row.data.quantitePrevueUniteTechnique.uniteDeMesure.abreviation">{{cell.row.data.quantitePrevueUniteTechnique.uniteDeMesure.abreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'tplQuantitePrevueUs'">
      {{cell.row.data.quantitePrevueUniteStockage.value | number:'1.4-4'}} <span
      [title]="cell.row.data.quantitePrevueUniteStockage.udmUniteStockage.abreviation">{{cell.row.data.quantitePrevueUniteStockage.udmUniteStockage.abreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'tplQuantiteReelleUs'">
      {{cell.row.data.quantiteReelleUniteStockage.value | number:'1.4-4'}} <span
      [title]="cell.row.data.quantiteReelleUniteStockage.udmUniteStockage.abreviation">{{cell.row.data.quantiteReelleUniteStockage.udmUniteStockage.abreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'tplStatut'">
        <span class="fa-stack" [title]="cell.row.data.status">
              <i [class]="initClassAccordingStatus(cell.row.data.status)"></i>
              <strong *ngIf="cell.row.data.status === 'PROPOSITION_DE_SORTIE'"
                      class="fa-stack-1x color-proposition-sortie">
                PS
              </strong>
              <strong *ngIf="cell.row.data.status === 'SORTIE_VALIDEE'" class="fa-stack-1x color-sortie-validee">
                SV
              </strong>
              <strong *ngIf="cell.row.data.status === 'RUPTURE_PARTIELLE'" class="fa-stack-1x color-rupture-partielle">
                RP
              </strong>
            <strong *ngIf="cell.row.data.status === 'SORTIE_RUPTURE'" class="fa-stack-1x color-sortie-rupture">
              RS
            </strong>
        </span>
    </div>

    <div *dxTemplate="let cell of 'tplDateSortie'">
      {{ utils.convertddMMYYYYSlashVersion(cell.row.data.dateSortie) }}
    </div>

    <div *dxTemplate="let cell of 'tplDateConso'">
      {{ utils.convertddMMYYYYSlashVersion(cell.row.data.dateConsommation) }}
    </div>

    <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
      <div title="colonne modifiable">
        {{cell.column.caption}}
      </div>
    </div>

  </dx-data-grid>

</ng-template>

<ng-template #tplVueStock>

  <dx-data-grid
    [dataSource]="planProduction?.sorties"
    keyExpr="id"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [height]="700"
    width="100%"
    [hoverStateEnabled]="true"
    [showBorders]="true"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    #grid>

    <dxi-column dataField="status" caption="Statut" alignment="center" cellTemplate="tplStatut" [width]="isImageSet() ? 120 : 100"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="dateSortie" caption="Date de sortie" alignment="left" cellTemplate="tplDateSortie"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="uniteProductionEquipe.uniteDeProductionLibelle" caption="Unité de production"
                alignment="left" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="uniteProductionEquipe.zoneDeProductionLibelle" caption="Atelier" alignment="left"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="zoneDeStockage.libelle" caption="Zone de stockage" alignment="left"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="denree.libelle" caption="Déclinaison" alignment="left" cellTemplate="tplDenree"
                [allowEditing]="false"></dxi-column>
    <dxi-column alignment="left" dataField="pointDeLivraison.libelle" caption="Point de livraison"
                [visible]="isColumnPlcVisible()" [allowEditing]="false"></dxi-column>
    <dxi-column alignment="left" dataField="repas.libelle" caption="Repas" [visible]="isColumnMealVisible()"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="dateConsommation" caption="Date de consommation" alignment="left" cellTemplate="tplDateConso"
                [visible]="isColumnDateConsoVisible()" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="stockDlc" caption="DLC" alignment="left" cellTemplate="tplDlc"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUniteTechnique.value" caption="Qté Prévue UT" alignment="right"
                cellTemplate="tplQuantitePrevueUt" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUniteStockage.value" caption="Qté Prévue US" alignment="right"
                cellTemplate="tplQuantitePrevueUs" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantiteReelleUniteStockage.value" caption="Qté Réelle US" alignment="right"
                headerCellTemplate="updateHeaderCellTemplate" cellTemplate="tplQuantiteReelleUs" [allowEditing]="true">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" [min]="0"
                           message="La Qté Réelle US doit être supérieure ou égale à 0"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="quantiteStock.value" caption="Qté en stock" alignment="right"
                cellTemplate="tplQuantiteEnStock"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="stock.lotArticle" cellTemplate="tplLotArticle" caption="Lot" alignment="left"
                [allowEditing]="false"></dxi-column>

    <dxo-selection
      [selectAllMode]="true"
      [showCheckBoxesMode]="'always'"
      mode="multiple"
    ></dxo-selection>

    <dxo-editing
      mode="cell"
      [confirmDelete]="false"
      [allowUpdating]="true">
    </dxo-editing>

    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Rechercher..."></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>

    <dxo-header-filter
      [visible]="true"></dxo-header-filter>

    <dxo-filter-builder>
    </dxo-filter-builder>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder-popup
      [position]="popupPosition">
    </dxo-filter-builder-popup>

    <div *dxTemplate="let cell of 'tplQuantitePrevueUt'">
      {{cell.row.data.quantitePrevueUniteTechnique.value | number:'1.4-4'}} <span
      [title]="cell.row.data.quantitePrevueUniteTechnique.uniteDeMesure.abreviation">{{cell.row.data.quantitePrevueUniteTechnique.uniteDeMesure.abreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'tplQuantitePrevueUs'">
      {{cell.row.data.quantitePrevueUniteStockage.value | number:'1.4-4'}} <span
      [title]="cell.row.data.quantitePrevueUniteStockage.udmUniteStockage.abreviation">{{cell.row.data.quantitePrevueUniteStockage.udmUniteStockage.abreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'tplQuantiteReelleUs'" [class]="statutRupturePartielleCss(cell.row.data.status)">
      {{cell.row.data.quantiteReelleUniteStockage.value | number:'1.4-4'}} <span
      [title]="cell.row.data.quantiteReelleUniteStockage.udmUniteStockage.abreviation">{{cell.row.data.quantiteReelleUniteStockage.udmUniteStockage.abreviation}}</span>
    </div>

    <div *dxTemplate="let cell of 'tplQuantiteEnStock'" [class]="statutRupturePartielleCss(cell.row.data.status)">
      <ng-container *ngIf="cell.row.data.quantiteStock">
        {{ cell.row.data.quantiteStock.value | number:'1.4-4' }} <span
        [title]="cell.row.data.quantiteStock.uniteDeMesure.abreviation ">{{cell.row.data.quantiteStock.uniteDeMesure.abreviation }}</span>
      </ng-container>
    </div>

    <div *dxTemplate="let cell of 'tplStatut'" [class]="'custom-button-container' + (isImageSet() ? '-left' : '')">
        <span class="fa-stack" [title]="cell.row.data.status">
              <i [class]="initClassAccordingStatus(cell.row.data.status)"></i>
              <strong *ngIf="cell.row.data.status === 'PROPOSITION_DE_SORTIE'"
                      class="fa-stack-1x color-proposition-sortie">
                PS
              </strong>
              <strong *ngIf="cell.row.data.status === 'SORTIE_VALIDEE'" class="fa-stack-1x color-sortie-validee">
                SV
              </strong>
              <strong *ngIf="cell.row.data.status === 'RUPTURE_PARTIELLE'" class="fa-stack-1x color-rupture-partielle">
                RP
              </strong>
            <strong *ngIf="cell.row.data.status === 'SORTIE_RUPTURE'" class="fa-stack-1x color-sortie-rupture">
              RS
            </strong>
        </span>
      <yo-button *ngIf="cell.row.data?.stock?.images?.length > 0"
                 iconClass="fas fa-camera"
                 [label]="getImageCount(cell.row.data?.stock?.images)"
                 buttonClass="cta-secondary"
                 (onClick)="openImageDialog(cell.row.data?.stock?.images)"
                 tooltip="Consulter la ou les photos"
      ></yo-button>
    </div>

    <div *dxTemplate="let cell of 'tplDateSortie'">
      {{ utils.convertddMMYYYYSlashVersion(cell.row.data.dateSortie) }}
    </div>

    <div *dxTemplate="let cell of 'tplDlc'">
      {{ cell.row.data.stock && cell.row.data.stock.dlc ? utils.convertddMMYYYYSlashVersion(cell.row.data.stock.dlc) : '' }}
    </div>

    <div *dxTemplate="let cell of 'tplDateConso'">
      {{ utils.convertddMMYYYYSlashVersion(cell.row.data.dateConsommation) }}
    </div>

    <div *dxTemplate="let cell of 'tplDenree'">
      <i [title]="handleLotArticleDisplay(cell.row.data.stock?.lotArticle)"
         *ngIf="!cell.row.data.stock || !cell.row.data.stock.id"
         class="fas fa-exclamation-triangle space yoni-color"></i>
      <i [title]="handleArticleDisplay()"
         *ngIf="isHandleArticle(cell.row.data)"
         class="fas fa-exclamation-triangle space yoni-color"></i>
      {{ cell.row.data.denree.libelle }}
    </div>

    <div *dxTemplate="let cell of 'tplLotArticle'">
      {{ handleLotArticleDisplay(cell.row.data.stock.lotArticleFournisseur ? cell.row.data.stock.lotArticleFournisseur : cell.row.data.stock.lotArticle) }}
    </div>

    <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
      <div title="colonne modifiable">
        {{cell.column.caption}}
      </div>
    </div>

  </dx-data-grid>

</ng-template>

<dx-popup
  title="Options d'affichage"
  [showTitle]="true"
  [height]="'auto'"
  [width]="550"
  (onHiding)="closeOptionsDisplayDialog()"
  [(visible)]="displayOptionsDisplayDialog"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row">
    <div class="col-md-12">
      <label>Détail des lignes par : </label>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valuePlc" text="Point de livraison client"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueMeal" text="Repas"></dx-check-box>
    </div>
    <div class="col-md-3">
      <dx-check-box [(ngModel)]="valueConsumptionDate" text="Date de consommation"></dx-check-box>
    </div>
  </div>
  <div class="row float-right mg-t-15">
    <div class="col-md-12">
      <yo-button label="Enregistrer" buttonClass="cta-success" iconClass="fas fa-save"
                 (onClick)="applyDisplayOptions()"></yo-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  title="Traitement en masse des sorties de production"
  [showTitle]="true"
  [height]="'auto'"
  [width]="'90wh'"
  (onHiding)="closeDialogMassiveTreatment()"
  [(visible)]="displayDialogMassiveTreatment"
  [resizeEnabled]="true"
  [showCloseButton]="true"
>
  <div class="row mg-b-10">
    <div class="col-md-7"><label>Quantité réelle US à ajuster</label></div>
    <div class="col-md-5"><input type="number" class="text-right mg-r-5 full-width" [(ngModel)]="massiveQteReelleUs"
                                 pKeyFilter="pnum"/></div>
  </div>
  <div *ngIf="!valuePlc && !valueMeal && !valueConsumptionDate" class="row mg-b-10">
    <div class="col-md-7"><label>Arrondir la quantité réelle US au supérieur ? </label></div>
    <div class="col-md-5">
      <dx-check-box [(ngModel)]="aroundQteReelleUs"></dx-check-box>
    </div>
  </div>
  <div class="row mg-b-10">
    <div class="col-md-12 text-right">
      <yo-button iconClass="fas fa-check"
                 class="mg-r-5"
                 [disabled]="(massiveQteReelleUs === undefined && massiveQteReelleUs === null) || (aroundQteReelleUs === undefined && aroundQteReelleUs === null)"
                 (onClick)="applyMassiveTreatment()"></yo-button>
      <yo-button iconClass="fas fa-times" (onClick)="closeDialogMassiveTreatment()"
                 buttonClass="cta-delete"></yo-button>
    </div>
  </div>
</dx-popup>

<yo-print-sorties-production-settings [selectedSorties]="this.selectedDetails"></yo-print-sorties-production-settings>

<yo-image-list-popup
  [isOpened]="isImagePopupOpened"
  (onClose)="onImagePopupClose($event)"
  [values]="this.selectedImages"
  [disabled]="true">
</yo-image-list-popup>
