import {Component, OnInit, ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";
import {CatalogueAchatDTO} from "../../../../../core/dtos/catalogue-achat-dto";
import {Subscription} from "rxjs";
import {CataloguesAchatsService} from "../../../../../core/services/entities/catalogues-achats.service";
import {ToastService} from "../../../../../core/services/technique/toast.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../../core/constants";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {UnitesDeMesuresService} from "../../../../../core/services/entities/unites-de-mesures.service";
import {UniteDeMesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {LookUpComponent} from "../../../../../shared/ui/lookup/lookup.component";
import {CatalogueAchatPrixPeriodeDTO} from "../../../../../core/dtos/catalogue-achat-prix-periode-dto";

@Component({
  selector: 'yo-dialog-save-period-price',
  templateUrl: './dialog-save-period-price.component.html',
  styleUrls: ['./dialog-save-period-price.component.scss']
})
export class DialogSavePeriodPriceComponent implements OnInit {

  @ViewChild('articleForm') articleForm: NgForm;
  @ViewChild('uniteDeFacturation') uniteDeFacturation: LookUpComponent;
  @ViewChild('uniteDeCommande') uniteDeCommande: LookUpComponent;
  @ViewChild('uniteStockage') uniteStockage: LookUpComponent;

  displayDialog: boolean = false;
  catalogueAchat: CatalogueAchatDTO;
  caPP: CatalogueAchatPrixPeriodeDTO;
  unitesMesure: UniteDeMesureDTO[] = [];

  subOpenDialog: Subscription;
  subSavePeriodPrice: Subscription;

  title: string;

  ratioUCUF: number;
  ratioUSUT: number;

  isSameUCUF: boolean;
  isSameUSUT: boolean;

  constructor(public utils: UtilsService,
              private utilsSvc: UtilsService,
              public catalogueAchatSvc: CataloguesAchatsService,
              private toastSvc: ToastService,
              private udmSvc: UnitesDeMesuresService) {
  }

  ngOnInit(): void {
    this.openDialogSaveSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subSavePeriodPrice);
  }

  closeDialog = () => {
    this.caPP = null;
    this.catalogueAchat = null;
    this.ratioUCUF = null;
    this.ratioUSUT = null;
    this.isSameUCUF = null;
    this.isSameUSUT = null;
    this.displayDialog = false;
  }

  initData = () => {
    this.unitesMesure = this.udmSvc.unitesDeMesureEnvironnement;

    if (this.caPP.id) {
      this.title = 'MODIFIER PERIODE DE PRIX';
    } else {
      this.title = 'AJOUT PERIODE DE PRIX';
    }

    this.ratioUCUF = this.caPP.ratioUniteCommandeUniteBase / this.caPP.ratioUniteFacturationUniteBase;
    this.ratioUSUT = this.caPP.ratioUniteStockageUniteBase / this.catalogueAchat.ratioUniteTechnique;

    this.isSameUCUF = this.caPP.uniteDeCommande.id === this.caPP.uniteDeFacturation.id;
    if (this.isSameUCUF) this.ratioUCUF = 1;

    this.isSameUSUT = this.catalogueAchat.uniteTechnique.id === this.caPP.uniteDeStockage.id;
    if (this.isSameUSUT) this.ratioUSUT = 1;
  }

  openDialogSaveSubscription = () => {
    this.subOpenDialog = this.catalogueAchatSvc.openDialogSavePricePeriod$
      .subscribe((item) => {
        this.catalogueAchat = item.catalogueAchat;
        if (item.isUpdate) {
          this.caPP = item.caPP;
          if(!this.utils.isCollectionNullOrEmpty(this.caPP.groupeAchatCaList))
            this.caPP.prix = this.caPP.groupeAchatCaList[0].prixUF.value;
        } else {
          this.initCaPP();
        }
        this.displayDialog = true;

        this.initData();
      });
  }

  initCaPP = () => {
    this.caPP = new CatalogueAchatPrixPeriodeDTO();
    this.caPP.dateEffective = new Date();
    this.caPP.prix = this.catalogueAchat.prix;
    this.caPP.libelle = this.catalogueAchat.produitArticle?.libelle;
    this.caPP.referenceArticle = this.catalogueAchat.produitArticle?.reference;
    this.caPP.actif = this.catalogueAchat.actif;
    this.caPP.minimumCommande = this.catalogueAchat.minimumDeCommande;
    this.caPP.conditionnePar = this.catalogueAchat.conditionnePar;
    this.caPP.uniteDeCommande = this.catalogueAchat.uniteDeCommande;
    this.caPP.uniteDeFacturation = this.catalogueAchat.uniteDeFacturation;
    this.caPP.uniteDeStockage = this.catalogueAchat.uniteDeStockage;
    this.caPP.ratioUniteCommandeUniteBase = this.catalogueAchat.ratioUniteCommandeUniteBase;
    this.caPP.ratioUniteFacturationUniteBase = this.catalogueAchat.ratioUniteFacturationUniteBase;
    this.caPP.ratioUniteStockageUniteBase = this.catalogueAchat.ratioUniteStockageUniteBase;
    this.caPP.catalogueAchat = this.catalogueAchat;
    this.caPP.groupeAchatCaList = this.catalogueAchat.groupeAchatCaList;
  }

  save = () => {
    this.subSavePeriodPrice = this.catalogueAchatSvc.savePricePeriod(this.caPP).subscribe(() => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la période de prix réalisée avec succès`);
      this.catalogueAchatSvc.announceSavedPricePeriod();
      this.closeDialog();
    });
  }

  isFormValid = (): boolean => {
    return this.articleForm?.valid &&
      this.uniteDeFacturation?.getSelectedItem() &&
      this.uniteDeCommande?.getSelectedItem() &&
      this.uniteStockage?.getSelectedItem();
  }

  onChange = (id: number, propriete: string): void => {
    this.caPP[propriete] = this.unitesMesure.find(unite => unite.id == id);

    this.isSameUCUF = this.caPP.uniteDeCommande.id === this.caPP.uniteDeFacturation.id;
    if (this.isSameUCUF) this.ratioUCUF = 1;
    this.onChangeRatioUC();

    this.isSameUSUT = this.catalogueAchat.uniteTechnique.id === this.caPP.uniteDeStockage.id;
    if (this.isSameUSUT) this.ratioUSUT = 1;
    this.onChangeRatioUS();
  }

  canModify = () => this.utilsSvc.isNullOrEmpty(this.caPP) || this.caPP.id == 0;

  onChangeRatioUC = () => this.caPP.ratioUniteCommandeUniteBase = this.ratioUCUF * this.caPP.ratioUniteFacturationUniteBase;

  onChangeRatioUCUF = () => {
    if (!this.isSameUCUF) this.ratioUCUF = this.caPP.ratioUniteCommandeUniteBase / this.caPP.ratioUniteFacturationUniteBase;
    else this.caPP.ratioUniteFacturationUniteBase = this.caPP.ratioUniteCommandeUniteBase;
  }

  onChangeRatioUS = () => this.caPP.ratioUniteStockageUniteBase = this.ratioUSUT * this.catalogueAchat.ratioUniteTechnique;

  onChangeRatioUSUT = () => {
    if (!this.isSameUSUT) this.ratioUSUT = this.caPP.ratioUniteStockageUniteBase / this.catalogueAchat.ratioUniteTechnique;
  }

  onPriceUpdated = () => {
    this.caPP.groupeAchatCaList[0].prixUF.value = this.caPP.prix;
  };
}
