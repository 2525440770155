import {SiteDTO} from "../../site-dto";
import {TypeProduitDTO} from "../../type-produit-dto";

export class CommunicationProduitDTO {
  id: number;
  site: SiteDTO;
  typeProduit: TypeProduitDTO;
  code: string;
  libelle: string;
  actif: boolean;
}
