import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DATES_FORMAT, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {UtilsService} from "../../core/utils/utils.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {Subscription} from "rxjs";
import {OrganisationsTourneesService} from "../../core/services/gestion-production/organisations-tournees.service";
import {OrganisationTourneesDto} from "../../core/dtos/organisation-tournees-dto";
import {confirm} from "devextreme/ui/dialog";
import {OrganisationTourneeInfoDto} from "../../core/dtos/organisation-tournee-info-dto";
import moment from "moment";
import {cloneDeep as _cloneDeep} from 'lodash'
import {OverlayPanel} from "primeng/overlaypanel";
import {SiteDTO} from "../../core/dtos/site-dto";

@Component({
  selector: 'yo-organisations-tournees',
  templateUrl: './organisations-tournees.component.html',
  styleUrls: ['./organisations-tournees.component.scss']
})
export class OrganisationsTourneesComponent implements OnInit, OnDestroy {

  pathHelpFile: string = HELP_FOLDERS.ORGANISATIONS_TOURNEES + '/organisations';

  selectedRows: number[] = [];
  errors: any[] = [];

  displayDeletePopupErrors: boolean = false;

  organisationsTournees: OrganisationTourneeInfoDto[] = [];

  subTourneeSaved$: Subscription;

  subTournees$: Subscription;

  subRefreshList$: Subscription;

  subDuplicate$: Subscription;

  organisationToDuplicate: OrganisationTourneeInfoDto

  /**
   * site selectionné pour la duplication d'un produit
   */
  duplicationSiteSelected: SiteDTO;

  /**
   * La p-overlayPanel pour la duplication de produit
   */
  @ViewChild('opDuplication') opDuplication: OverlayPanel;

  constructor(public auth2Svc: Auth2Service,
              private i8nSvc: InternationalizationService,
              public utilsSvc: UtilsService,
              public orgTourneesSvc: OrganisationsTourneesService,
              public toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subTourneeSaved$);
    this.utilsSvc.unsubscribe(this.subTournees$);
    this.utilsSvc.unsubscribe(this.subRefreshList$);
  }

  initSubscriptions = (): void => {
    this.initSubscriptionOrganisationsTournees();
    this.initSubscriptionRefreshList();
  }

  initSubscriptionOrganisationsTournees = (): void => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    this.subTournees$ = this.orgTourneesSvc.findAll(idsSites)
      .subscribe((response: any) => {
        this.organisationsTournees = response.resultList;
      });
  }

  calculateSiteValue = (org: OrganisationTourneesDto): string => {
    return org?.site?.libelle
  };

  initSubscriptionRefreshList = () => {
    this.subRefreshList$ = this.orgTourneesSvc.refreshList$.subscribe(() => {
      const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
      this.orgTourneesSvc.findAll(idsSites)
        .subscribe((response: any) => {
          this.organisationsTournees = response.resultList;
        });
    })
  }

  deleteValues = async (): Promise<void> => {
    this.errors = [];
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_ORG_TOURNEES_DELETION_PLURAL" : "CONFIRM_ORG_TOURNEES_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.orgTourneesSvc.deleteOrganizationsByFilters(this.selectedRows).subscribe(response => {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `TOURNEE_ORG_TOURNEES_COMPLETED_PLURAL` : `TOURNEE_ORG_TOURNEES_COMPLETED_SINGULAR`, null));
      this.orgTourneesSvc.findAll(idsSites)
          .toPromise()
          .then(response => this.organisationsTournees = response.resultList);
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
  }


  openDialogEdition = (orga?: OrganisationTourneeInfoDto) => {
    this.orgTourneesSvc.announceOpenEditionDialog(orga);
  };

  showPeriodes = (orga : OrganisationTourneesDto) => {
    return orga.periods.map(item => moment(item.start).format(DATES_FORMAT.DD_MM_YYYY) + ' - ' + moment(item.end).format(DATES_FORMAT.DD_MM_YYYY)).join(", ")
  }

  openPanelDuplication = ($event, rowData: OrganisationTourneeInfoDto): void => {
    this.organisationToDuplicate = _cloneDeep(rowData);
    this.opDuplication.toggle($event)
  };

  duplicateOrganisation = (): void => {
    this.organisationToDuplicate.site = this.duplicationSiteSelected
    this.opDuplication.hide();
    this.subDuplicate$ = this.orgTourneesSvc.duplicateOrganisationTournee(this.organisationToDuplicate)
      .subscribe(response => {
      const msg = `Organisation dupliquée avec succès.`;
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, msg);
      this.organisationsTournees.push(response.one.organizationRound);
      this.openDialogEdition(response.one.organizationRound);
    });
  }
}
