import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MenusPlanning2Service} from "../../../core/services/gestionmenus/menus-planning2.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {DATEPICKER_FR} from "../../../core/constants";
import {debounceTime, filter, switchMap} from "rxjs/operators";
import {DisplayEgalimResponse} from "../../../core/dtos/gestionmenus/planning/egalim/display-egalim-response-dto";
import {UnitesDeProductionService} from "../../../core/services/entities/unites-de-production.service";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {DxBarGaugeComponent} from "devextreme-angular";
import {MenusToolbarService} from "../../../core/services/gestionmenus/menus-toolbar.service";


@Component({
  selector: 'yo-menus-egalim',
  templateUrl: './menus-egalim.component.html',
  styleUrls: ['./menus-egalim.component.scss']
})
export class MenusEgalimComponent implements OnInit, OnDestroy {

  period: string;
  subAnalyseEgalim: Subscription;
  subChangeValues: Subscription;
  private subRegime: Subscription;


  selectedPeriode: Date[] = [new Date(), new Date()];
  effectif: number = 1;
  localeFr: any = DATEPICKER_FR;
  idContratMenuConvive: number;

  dataGraphic: DisplayEgalimResponse;

  subjectSelectedEffectif: BehaviorSubject<any> = new BehaviorSubject(undefined);
  selectedEffectif$: Observable<any> = this.subjectSelectedEffectif.asObservable();

  subjectSelectedPeriode: BehaviorSubject<any> = new BehaviorSubject(undefined);
  selectedPeriodes$: Observable<any> = this.subjectSelectedPeriode.asObservable();

  subjectEgalimThresholdBio: BehaviorSubject<any> = new BehaviorSubject(undefined);

  selectedEgalimThresholdBio$: Observable<any> = this.subjectEgalimThresholdBio.asObservable();

  subjectEgalimThresholdSustainableProducts: BehaviorSubject<any> = new BehaviorSubject(undefined);

  selectedEgalimThresholdSustainableProducts$: Observable<any> = this.subjectEgalimThresholdSustainableProducts.asObservable();

  egalimThresholdBio: number;

  egalimThresholdSustainableProducts: number;
  private selectedRegime: number;
  regime$ = this.menusToolbarSvc.regime$;

  udp: string;

  @ViewChild("gauge") dataBarGauge: DxBarGaugeComponent;

  constructor(public mp2Svc: MenusPlanning2Service,
              private udpSvc: UnitesDeProductionService,
              public utils: UtilsService,
              private graphQlSvc: GraphQLService,
              private cd: ChangeDetectorRef,
              private menusToolbarSvc: MenusToolbarService,) {
  }

  ngOnInit(): void {
    this.idContratMenuConvive = this.mp2Svc.planningMenus.contratMenuConviveList && this.mp2Svc.planningMenus.contratMenuConviveList.length ? this.mp2Svc.planningMenus.contratMenuConviveList[0].id : -1;
    if (this.idContratMenuConvive > -1)
      this.graphQlSvc.sendQuery(`
        {
          allUnitesProduction(filters: {
            idsSites: [${this.mp2Svc.planningMenus.contratMenuConviveList[0].contratMenuSiteId}]
          }) {
            libelle,
            egalimThresholdBio,
            egalimThresholdSustainableProducts
          }
        }
    `).subscribe(response => {
        const udp: any = response.allUnitesProduction[0];
        this.udp = udp.libelle;
        this.egalimThresholdBio = udp.egalimThresholdBio;
        this.egalimThresholdSustainableProducts = udp.egalimThresholdSustainableProducts;
        const dates = this.mp2Svc.planningMenus.dateList;
        this.selectedPeriode = [new Date(dates[0].date), new Date(dates[dates.length - 1].date)];
        this.getRegime();
        this.findAnalyseEgalimSubscription();
        this.changeValuesSubscription();
      });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAnalyseEgalim);
    this.utils.unsubscribe(this.subChangeValues);
  }

  findAnalyseEgalimSubscription = () => {
    this.subAnalyseEgalim = this.mp2Svc.findAnalyseEgalim(this.idContratMenuConvive, this.effectif, this.selectedPeriode[0], this.selectedPeriode[1], this.selectedRegime)
      .subscribe(response => {
        this.dataGraphic = response.one;
      });
  }

  changeValuesSubscription: () => void = () => {
    this.dataGraphic = null;
    const all$ = combineLatest([this.selectedEffectif$, this.selectedPeriodes$, this.selectedEgalimThresholdBio$, this.selectedEgalimThresholdSustainableProducts$]);
    this.subChangeValues = all$.pipe(
      debounceTime(500),
      filter(data => this.effectif && this.selectedPeriode && this.selectedPeriode.length > 0),
      switchMap(response => this.mp2Svc.findAnalyseEgalim(this.idContratMenuConvive, this.effectif, this.selectedPeriode[0], this.selectedPeriode[1], this.selectedRegime, this.egalimThresholdBio, this.egalimThresholdSustainableProducts))
    ).subscribe(response => {
      this.cd.markForCheck();
      this.dataBarGauge.instance.beginUpdate();
      this.dataGraphic = response.one;
      this.dataBarGauge.instance.endUpdate();
      this.cd.markForCheck();
    });
  }

  canDisplay: () => (boolean) = () =>
    !this.utils.isNullOrEmpty(this.mp2Svc.planningMenus) && !this.utils.isCollectionNullOrEmpty(this.mp2Svc.planningMenus.contratMenuConviveList);

  changeSeuilBio = (): void => {
    this.subjectEgalimThresholdBio.next(this.egalimThresholdBio);
  }

  changeSeuilProduitsDurable = (): void => {
    this.subjectEgalimThresholdSustainableProducts.next(this.egalimThresholdSustainableProducts);
  }

  getText = (item: any, value: string) => (item.index === 0) ? `Pourcentage de produits durables - ${value} % (${this.dataGraphic.totalMontantProduitsDurablesPeriod} €)` : `Pourcentage de produits bio ${value} % (${this.dataGraphic.totalMontantProduitsBioPeriod} €)`;

  customizeTooltip = (arg): any => ({
    text: this.getText(arg, arg.valueText),
  });

  customizeText = (arg): any => this.getText(arg.item, arg.text);

  customizeTextLabel: (arg) => string = arg => `${arg.valueText} %`;

  getPeriod = ():string => "Période du " + this.selectedPeriode[0].toLocaleDateString() + " au " + this.selectedPeriode[1].toLocaleDateString();

  private getRegime() {
    this.subRegime = this.regime$.subscribe(response => {
      this.selectedRegime = response.id;
    });

  }

  onDateFilterValueChange = (data: any) => {
      this.selectedPeriode = [data[0], data[1]];
    this.subjectSelectedPeriode.next(this.selectedPeriode);
  };

}
