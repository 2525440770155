import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FacturationService} from "../../../../core/services/entities/facturation.service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {DxDataGridComponent} from "devextreme-angular";

@Component({
  selector: 'yo-dialog-join-bon-reception',
  templateUrl: './dialog-join-bon-reception.component.html',
  styleUrls: ['./dialog-join-bon-reception.component.scss']
})
export class DialogJoinBonReceptionComponent implements OnInit, OnDestroy {

  subOpenDialogJoinBonReception: Subscription;

  subBonsReceptions: Subscription;

  subInvoices: Subscription;

  subDetails: Subscription;

  displayDialog: boolean = false;

  providersSelected: number[] = [];

  dateLivraisonStartSelected: Date;

  dateLivraisonEndSelected: Date;

  bonsReceptions: any[] = [];

  currentNumberInvoice: string = '';

  selectedRows: number[] = [];

  bonsReceptionsUsedByCurrentInvoice: number[] = [];

  currentIdSite: number;


  @ViewChild("grid") grid: DxDataGridComponent;

  constructor(private facturationSvc: FacturationService,
              private utilsSvc: UtilsService) {}

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialogJoinBonReception);
    this.utilsSvc.unsubscribe(this.subBonsReceptions);
    this.utilsSvc.unsubscribe(this.subInvoices);
    this.utilsSvc.unsubscribe(this.subDetails);
  }

  ngOnInit(): void {
    this.subOpenDialogJoinBonReception = this.facturationSvc.openDialogJoinBonReception$
      .subscribe((invoice: any) => {
        this.currentNumberInvoice = invoice.number;
        this.providersSelected.push(invoice?.provider?.id);
        this.displayDialog = true;
        this.fetchInvoice(invoice.id);
      });
  }

  fetchInvoice = (id: number): void => {
    this.subDetails = this.facturationSvc.fetchById(id)
      .subscribe((response) => {
        const currentInvoice = response.one;
        this.currentIdSite = currentInvoice.site?.id;
        this.bonsReceptionsUsedByCurrentInvoice = currentInvoice.rows.map(row => row.bonReceptionLigne.idBonReception);
        this.fetchBonsReceptionsForBilling(true);
      });
  }

  closeDialog = (): void => {
    this.currentNumberInvoice = '';
    this.providersSelected = [];
    this.bonsReceptionsUsedByCurrentInvoice = [];
    this.dateLivraisonStartSelected = undefined;
    this.dateLivraisonEndSelected = undefined;
    this.displayDialog = false;
  }

  refreshDataSource = (): void => {
    this.fetchBonsReceptionsForBilling(true);
  }

  fetchBonsReceptionsForBilling = (refresh: boolean) => {
    this.subBonsReceptions = this.facturationSvc.fetchBonsReceptionsForBilling(this.providersSelected, this.dateLivraisonStartSelected, this.dateLivraisonEndSelected, this.currentIdSite)
      .subscribe(response => {
        this.bonsReceptions = response.resultList;
        if (refresh) this.grid.instance.refresh();
      });
  }

  applyBinding = (): void => {
    this.subInvoices = this.facturationSvc.bindInvoices(this.selectedRows, this.providersSelected[0], this.currentNumberInvoice, null)
      .subscribe(() => {
        this.facturationSvc.announceRefreshInvoices();
        this.closeDialog();
      });
  }


}
