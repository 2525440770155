import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {BonCfDTO} from '../../dtos/boncfs-dto';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {BoncfStatutDTO} from '../../dtos/boncf-statut-d-t-o';
import {ObjectDTO} from '../../dtos/object-dto';
import {Subject} from 'rxjs';
import _, {cloneDeep as _cloneDeep, startCase as _startCase} from 'lodash';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {GenericRequestSupplier, Predicat, Search, Sort} from '../../suppliers/generics/generic-request-supplier';
import {MailHistoService} from './mail-histo.service';
import * as moment from 'moment';
import {UniteDeProductionDTO} from '../../dtos/unite-de-production-dto';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../dtos/unite-de-production__secteur-fournisseur-dto';
import {SearchSupplier} from '../../suppliers/search-supplier';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {CommandesService} from '../gestion-commandes/commandes.service';
import {PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {SecteurDTO} from "../../dtos/secteur-dto";
import {HttpService} from "../technique/http.service";
import {ChoixQteCalculPrixBonCfDetailEnum} from "../../enums/choix-qte-calcul-prix-bon-cf-detail-enum";
import {BonReceptionLigneDTO} from "../../dtos/bon-reception-ligne-dto";
import {MailHistoDTO} from "../../dtos/mail-histo-dto";


@Injectable({
  providedIn: 'root'
})
export class BoncfService extends GenericHandler<BonCfDTO> {

  private subjectOpenReassignDialog = new Subject<SecteurDTO>();
  openReassignDialog$ = this.subjectOpenReassignDialog.asObservable();

  private subjectBonCf = new Subject<BonCfDTO>();
  bonCf$ = this.subjectBonCf.asObservable();

  private subjectReassignBonCfResult = new Subject<BonCfDTO>();
  reassignBonCfResult$ = this.subjectReassignBonCfResult.asObservable();

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private gds: GenericDatagridService,
              private mailHistoSvc: MailHistoService,
              private httpSvc: HttpService,
              public commandesSvc: CommandesService) {
    super(utils, auth2Svc, router, http, title);
  }

  announceHistoriqueMailBonCf(bonCf: BonCfDTO) {
    this.subjectBonCf.next(bonCf);
  }

  createEmptyDTO(): BonCfDTO {

    return new BonCfDTO();
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  createEmptyDTOStatut(statut: BoncfStatutDTO): BonCfDTO {

    const bonCf = new BonCfDTO();
    bonCf.bonCfStatut = statut;
    bonCf.site = this.auth2Svc.utilisateur.sitePrincipal;


    return bonCf;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEntityName(): string {
    return "bonCf";
  }

  getFieldsSpec(dto: BonCfDTO, statut: BoncfStatutDTO): FormFieldBaseSupplier<any>[] {
    return undefined;
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "GESTION DES BONS DE COMMANDE FOURNISSEUR";
  }

  getFields(dto: BonCfDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }


  filterMailsHisto(bonCf: BonCfDTO): GenericRequestSupplier {

    const grs = new GenericRequestSupplier(this.mailHistoSvc.getEntityName());

    const search = new Search();
    search.predicats = [];

    const predicat1 = new Predicat();
    predicat1.path = 'mailhisto.extraInfos';
    predicat1.operator = PREDICAT_OPERATOR.Equals;
    predicat1.type = PREDICAT_TYPE.String;
    predicat1.value = bonCf.mailHisto.extraInfos;

    search.predicats.push(predicat1);
    grs.search = search;

    const sort = new Sort();
    sort.dir = PREDICAT_DIR.Descendant;
    sort.path = 'mailhisto.sentDate';

    search.sorts = [];
    search.sorts.push(sort);

    return grs;
  }

  /**
   * Permet de savoir si on est sur une date de livraison fournisseur
   * @param dateInstance metadata object to represent a Date with "day", "month" and "year" properties
   * @param joursLivraisons  jours de livraisons de l'unite de production pour le secteur fournisseur
   * @param udpJoursLivraison jours de livraison de l'unite de production
   * @param delaiLivraison
   * @param heureLimite
   * @return {boolean}
   */
  isDateFournisseur(dateInstance: any, joursLivraisons, udpJoursLivraison, delaiLivraison: number, heureLimite: Date): boolean {
    const dateFirst = this.computeFirstDateLivraison(joursLivraisons, udpJoursLivraison, delaiLivraison, heureLimite);

    const date = new Date(dateInstance.year, dateInstance.month, dateInstance.day);

    if (moment(date).isSameOrAfter(moment(dateFirst), 'days')) {
      return this.utils.isDateOk(date, joursLivraisons);
    }

    return false;
  }

  /**
   * Si inclusive
   * Récupérer la premiere date dispo par rapport rapport à une liste de jour
   * Sinon
   * Récupérer la premiere prochaine date dispo par rapport rapport à une liste de jour
   *
   * @param joursDispos
   * @param date
   * @param inclusive si date === premiere date dispo
   */
  getFirstDate(joursDispos: number[], date: Date, inclusive: boolean) {
    const weekDay = moment(date).clone().isoWeekday();
    let decalageJoursReel = 0;
    let firstDate = undefined;
    let found = false;

    for (const jourDispo of joursDispos) {

      // si jour liv correspond à la date et qu'on est en inclusif (on prend meme si egal)
      if (inclusive === true
        && jourDispo === weekDay) {
        firstDate = moment(date).clone().toDate();
        found = true;
        break;
      }
      // si pas de jourLiv à la date donnée, on prend le premier jourLiv dispo
      else if (jourDispo > weekDay) {
        decalageJoursReel = jourDispo - weekDay;
        firstDate = moment(date).add(decalageJoursReel, 'days').toDate();
        found = true;
        break;
      }
    }

    // si date > à tous les jours liv de la semaine , on prend le premier jour liv de la semaine suivante
    if (!found) {
      decalageJoursReel = 7 - weekDay + joursDispos[0];
      firstDate = moment(date).add(decalageJoursReel, 'days').toDate();
    }


    return {decalage: decalageJoursReel, firstDate};
  }

  /**
   * Créer une date limite
   * @param dateLimiteFake contient les heures et minutes à prendre en compte mais pas le jour
   * @param date contient le jour à prendre en compte
   */
  getDateLimite(dateLimiteFake: Date, date: Date) {

    const heureLimite = dateLimiteFake.getHours();
    const minutesLimite = dateLimiteFake.getMinutes();

    const dateLimite = _cloneDeep(date);
    dateLimite.setHours(heureLimite);
    dateLimite.setMinutes(minutesLimite);
    dateLimite.setSeconds(0);

    return dateLimite;

  }

  /**
   * Calculer la premiere date de livraison disponible,
   * Selon un delai de livraison ou une heure limite
   *
   *  Exemple :  delai livraison 3j : si L/M/M/J/V et actif L/J/V et date = J alors J/L/M --> donne le J
   *
   * @param joursLivraisonUdpSf  jours de livraisons de l'unite de production pour le secteur fournisseur
   * @param udpJoursLivraison jours de livraison de l'unite de production
   * @param delaiLivraison
   * @param dateLimiteFake
   * @return {Date}
   */
  computeFirstDateLivraison(joursLivraisonUdpSf: number[], udpJoursLivraison: number[], delaiLivraison: number, dateLimiteFake: Date): Date {

    const dateNow = new Date();
    let firstDateLiv = dateNow;
    let dateLimite;

    // s'il y a un délai de livraison
    if (delaiLivraison > 0) {

      dateLimite = this.getDateLimite(dateLimiteFake, firstDateLiv);

      // Recuperer le 1er jour dispo dans le calendrier de l'udp
      const resultFirstDayUdpJoursLiv = this.getFirstDayUdpJourLiv(dateNow, dateLimite, udpJoursLivraison, joursLivraisonUdpSf, delaiLivraison);

      // Compter le nombre de jours reel à décaler
      const delaiLivraisonJoursReel = this.computeDelaiLivraisonJour(resultFirstDayUdpJoursLiv, udpJoursLivraison);

      const jourLivFake = moment(dateNow).clone().add(delaiLivraisonJoursReel, 'days').toDate();
      firstDateLiv = this.getFirstDate(joursLivraisonUdpSf, jourLivFake, true).firstDate;
    }
    // si pas de delai livraison on verifie l'heure limite
    else {

      firstDateLiv = this.getFirstDate(joursLivraisonUdpSf, dateNow, true).firstDate;
      dateLimite = this.getDateLimite(dateLimiteFake, firstDateLiv);


      // si heure limite depassée
      if (moment(firstDateLiv).isAfter(moment(dateLimite))) {
        firstDateLiv = this.getFirstDate(joursLivraisonUdpSf, firstDateLiv, false).firstDate;
      }

    }

    return firstDateLiv;
  }

  /**
   * Récupere le premier jour faisant partie des jours de livraison de l'unite de production
   * @param date
   * @param udpJoursLivraisons
   * @param delaiLivraisonJours
   * @return {{delaiLivraisonJoursReel: number, delaiLivraisonJours: number, indexDay: number}}
   */
  private getFirstDayUdpJourLiv(date: Date, dateLimite: Date, udpJoursLivraisons: number[], udpSfJoursLiv: number[], delaiLivraisonJours: number): CalculJourLivraison {

    let weekDay = moment(date).isoWeekday();

    let found = false;
    let indexDay = 0;
    let delaiLiv = _cloneDeep(delaiLivraisonJours);
    let delaiJoursReel = 0;

    // si heure limite dépassée
    if (moment(date).isAfter(moment(dateLimite))) {

      const resultFirstDate = this.getFirstDate(udpJoursLivraisons, date, false);
      delaiJoursReel += resultFirstDate.decalage;

      weekDay = moment(resultFirstDate.firstDate).isoWeekday();

    }

    for (let i = 0; i < udpJoursLivraisons.length; i++) {

      const udpJourLivraison = udpJoursLivraisons[i];

      if (weekDay === udpJourLivraison) {
        indexDay = i;
        found = true;
        break;
      } else if (weekDay < udpJourLivraison) {
        indexDay = i;
        found = true;
        delaiJoursReel += udpJourLivraison - weekDay;
        delaiLiv--;
        break;
      }

      i++;
    }

    if (!found) {
      delaiLiv--;
      delaiJoursReel += 7 - weekDay + udpJoursLivraisons[0];
    }


    return {indexDay, delaiLivraisonJours: delaiLiv, delaiLivraisonJoursReel: delaiJoursReel};

  }

  private computeDelaiLivraisonJour(model: CalculJourLivraison, udpJoursLivraison: number[]): number {

    let indexDay = model.indexDay;
    let delaiLivraisonJours = model.delaiLivraisonJours;
    let delaiLivraisonJoursReel = model.delaiLivraisonJoursReel;


    for (let i = 0; i < delaiLivraisonJours; i++) {

      // si dateliv est dernier element
      if (indexDay === (udpJoursLivraison.length - 1)) {

        const dernierJour = udpJoursLivraison[udpJoursLivraison.length - 1];

        delaiLivraisonJoursReel += 7 - dernierJour + udpJoursLivraison[0];
        indexDay = 0;

      } else {

        delaiLivraisonJoursReel += udpJoursLivraison[indexDay + 1] - udpJoursLivraison[indexDay];
        indexDay++;

      }


    }

    return delaiLivraisonJoursReel;

  }


  getDataTableauDeBord(datesPeriode: Date[], udp_list: UniteDeProductionDTO[], udpSf_list: UniteDeProduction__SecteurFournisseurDTO[], statut_code: string, egalimThresholdBio?: number, egalimThresholdSustainableProducts?: number): any {

    const ssWrapper = new SearchSupplierWrapper();

    ssWrapper.filtersMap['statutCode'] = new SearchSupplier(statut_code);

    // filtre des dates de livraison fournisseur
    if (!this.utils.isCollectionNullOrEmpty(datesPeriode)) {

      const startDate = datesPeriode[0].getTime();
      let stopDate = _cloneDeep(startDate);
      if (datesPeriode[1]) {
        stopDate = datesPeriode[1].getTime();
      }

      ssWrapper.filtersMap['startDateLivraison'] = new SearchSupplier(startDate);
      ssWrapper.filtersMap['stopDateLivraison'] = new SearchSupplier(stopDate);
    }

    // filtre des unités de production
    if (!this.utils.isCollectionNullOrEmpty(udp_list)) {
      let idsUdps: number[] = [];
      for (const udp of udp_list) {
        idsUdps.push(udp.id);
      }
      ssWrapper.filtersMap['udps'] = new SearchSupplier(undefined, idsUdps);
    }

    // filtre des fournisseurs
    if (!this.utils.isCollectionNullOrEmpty(udpSf_list)) {
      let idsSfs: number[] = [];
      for (const udpSf of udpSf_list) {
        idsSfs.push(udpSf.fournisseurId);
      }
      ssWrapper.filtersMap['ffList'] = new SearchSupplier(undefined, idsSfs);
    }

    let urlParams: string = `?sort=asc&page=0&size=1000`;
    if (egalimThresholdBio !== undefined && egalimThresholdBio !== null) urlParams += `&egalimThresholdBio=${egalimThresholdBio}`;
    if (egalimThresholdSustainableProducts !== undefined && egalimThresholdSustainableProducts != null) urlParams += `&egalimThresholdSustainableProducts=${egalimThresholdSustainableProducts}`;

    return this.commandesSvc.getDataTableauDeBord(ssWrapper, urlParams);
  }

  announceOpenReassignDialog = (bonCf: BonCfDTO): void => {
    this.subjectOpenReassignDialog.next(bonCf);
  }

  announceReassignBonCfResult = (reassignResult: any): void => {
    this.subjectReassignBonCfResult.next(reassignResult);
  }

  reassignBonCf(bonCf: BonCfDTO, fournisseurId: number, dateLivraison: Date, choixQte: ChoixQteCalculPrixBonCfDetailEnum) {
    let params: HttpParams = new HttpParams()
      .set('bonCfId', bonCf.id)
      .set('fournisseurId', fournisseurId)
      .set('dateLivraison', dateLivraison.toDateString())
      .set('choixQte', choixQte);

    return this.httpSvc.post("dolrest/gestion-commandes-fournisseurs/reassign-bon-commande", null, params);
  }

  isQuantityMinimumReceived(bonCf: BonCfDTO) {
    let quantities = new Map<number, number>();

    if (!bonCf || !bonCf.bonReceptionList || bonCf.bonReceptionList.length == 0)
      return false;

    Object.entries(_.groupBy(bonCf.bonReceptionList
        .map(bon => bon.bonReceptionLigneList)
        .reduce((bon1, bon2) => bon1.concat(bon2)),
      "idCatalogueAchat"))
      .forEach((couple: [string, Array<BonReceptionLigneDTO>]) => {
        let index = parseInt(couple[0])
        couple[1].forEach(values => {
          if (!quantities.has(index))
            quantities.set(index, 0);
          quantities.set(index, quantities.get(index) + values.quantiteRecue);
        })
      })

    for (const detail of bonCf.details) {
      if (detail.quantiteACommanderAjustee > quantities.get(detail.idCatalogueAchat))
        return false;
    }
    return true;
  }
}

interface CalculJourLivraison {
  indexDay: number;
  delaiLivraisonJours: number;
  delaiLivraisonJoursReel: number;
}
