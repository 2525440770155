<dx-toolbar class="yo-toolbar">
  <dxi-item location="before" *ngIf="canEdit()">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <yo-button
        class="mg-r-15"
        label="AJOUTER"
        tooltip="Ajouter un ou plusieurs point(s) de livraison client"
        iconClass="fa fa-plus"
        (onClick)="openDialogAjoutPlcs()">
      </yo-button>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        type="button"
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="deleteValues()"
        [disabled]="!canEdit() || !selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>
<dx-data-grid
  [dataSource]="plcList"
  keyExpr="keyPlcPrestation"
  valueExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="libelleSite" caption="Site"
              cellTemplate="siteCellTemplate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="libelleClient"
              caption="Client"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="libelle" caption="Point de livraison client"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="offreAlimentaire.libelle" caption="Offre alimentaire" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="prestation.libelle" caption="Prestation" [allowFiltering]="true"></dxi-column>

  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteId]="convertNumber(cell.row.data.idSite)" [siteLibelle]="cell.row.data.libelleSite"
             [yoDelayMsTooltip]="1500"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>

<yo-dialog-ajout-mcplc-plc></yo-dialog-ajout-mcplc-plc>
