import {FormFieldBaseSupplier} from './form-fieldbase-supplier';

export class FormFieldDropdownSupplier extends FormFieldBaseSupplier<any> {
  controlType = 'dropdown';
  options: any[] = [];
  optionLabel:string;
  dto;

  constructor(options: any) {
    super(options);
    this.options = options['options'] || [];
    this.dto = options['dto'];
    this.optionLabel = options['optionLabel'] || 'libelle';
  }

  updateOptions(newOptions: any[]) {
    this.options = newOptions;
  }

}
