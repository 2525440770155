import {SiteDTO} from "../../site-dto";

export class FamilleEgalimDTO {
  id: number;
  site: SiteDTO;
  libelle: string;
  code: string;
  ordre: number;
  actif: boolean;
}
