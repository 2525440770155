import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {MailHistoDTO} from '../../dtos/mail-histo-dto';
import {EMAIL_STATUT} from '../../constants';
import {HttpService} from "../technique/http.service";
import {Subject} from "rxjs";
import {Action} from "rxjs/internal/scheduler/Action";


@Injectable({
  providedIn: 'root'
})
export class MailHistoService extends GenericHandler<MailHistoDTO> {

  private subjectDisplayEmailHisto = new Subject<MailHistoDTO[]>();
  displayEmailHisto$ = this.subjectDisplayEmailHisto.asObservable();

  constructor(utils: UtilsService,
              auth2Svc: Auth2Service,
              router: Router,
              http: HttpClient,
              title: Title,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);
  }

  createEmptyDTO(): MailHistoDTO {
    return undefined;
  }

  getAllFromEnvironnement(): void {
  }

  getCreateNewObjectLabel(): string {
    return "";
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  getEntityName(): string {
    return "mailHisto";
  }

  getFields(dto: ObjectDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getOas(): boolean {
    return false;
  }

  getSort(): string[] {
    return [];
  }

  getTitle(): string {
    return "HISTORIQUE DES MAILS";
  }

  getTotalRecordsLabel(): string {
    return "";
  }

  getShortLabel(statut: number) {
    let label = '';

    if (statut === EMAIL_STATUT.EN_COURS_ENVOI)
      label = 'EC';
    else if (statut === EMAIL_STATUT.ENVOYE)
      label = 'ENV';
    else if (statut === EMAIL_STATUT.LU)
      label = 'LU';
    else if (statut === EMAIL_STATUT.EN_ERREUR)
      label = 'ERR';

    return label;
  }

  getLabelStatut(statut: number) {
    let label = '';

    if (statut === EMAIL_STATUT.EN_COURS_ENVOI)
      label = `Mail en cours d'envoi`;
    else if (statut === EMAIL_STATUT.ENVOYE)
      label = 'Mail envoyé';
    else if (statut === EMAIL_STATUT.LU)
      label = 'Mail lu par le destinataire';
    else if (statut === EMAIL_STATUT.EN_ERREUR)
      label = 'Mail en erreur';

    return label;
  }

  getEmailStatutBadge = (statut: number) => {
    switch (statut) {
      case EMAIL_STATUT.EN_COURS_ENVOI:
        return "badge-warning";
      case EMAIL_STATUT.ENVOYE:
        return "badge-primary";
      case EMAIL_STATUT.LU:
        return "badge-success";
      case EMAIL_STATUT.EN_ERREUR:
        return "badge-danger";
      default:
        return "";
    }
  }

  findByFilters(code: string) {
    let body = {extraInfos: code};
    return this.httpSvc.post(`dolrest/email/find`, body);
  }

  openEmailHistoDialog = (code: string, callback: Function) => {
    this.findByFilters(code).subscribe(result => {
      if (result && !result.inError) {
        let mailHisto: MailHistoDTO = result.resultList.length > 0 ? result.resultList[0] : null;
        callback(mailHisto);
        this.subjectDisplayEmailHisto.next(result.resultList);
      }
    })
  }
}
