<p-dialog header="Gérer la grille"
          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'780px'}"
          [(visible)]="openDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>

    <div class="fiche-id-size">
      <p-panel header="Associations conditionnements / déclinaisons" [toggleable]="true"
               [style]="{'margin-bottom': '10px'}">

        <div class="row">
          <div class="col-md-6">
            <div class="p-inputgroup input-group-sm ">
              <p-listbox [options]="conditionnementsVariantesList"
                         [style.width]="'100%'"
                         [listStyle]="{'height':'350px'}"
                         formControlName="conditionnementsVariantes"
                         [filter]="true"
                         [multiple]="true"
                         [checkbox]="true"
                         filterPlaceHolder="Conditionnements / Variantes"
                         optionLabel="label">
              </p-listbox>
            </div>
          </div>

          <div class="col-md-6">
            <div class="p-inputgroup input-group-sm ">
              <p-listbox [options]="declinaisonsList"
                         [style.width]="'100%'"
                         [listStyle]="{'height':'350px'}"
                         [filter]="true"
                         [multiple]="true"
                         [checkbox]="true"
                         formControlName="declinaisons"
                         filterPlaceHolder="Déclinaisons de référence"
                         optionLabel="libelle">
              </p-listbox>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between mg-t-10">
          <div>
            <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
          </div>
          <yo-button
            tooltip="Enregistrer"
            type="submit"
            iconClass="fas fa-save"
            buttonClass="cta-success"
            [disabled]="!canModify"></yo-button>
        </div>

      </p-panel>

    </div>
  </form>

</p-dialog>
