import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {ToastService} from "../../../core/services/technique/toast.service";
import {UtilsService} from "../../../core/utils/utils.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {FamilleEgalimDTO} from "../../../core/dtos/referentiel/reception/famille-egalim-dto";
import {FamilleEgalimService} from "../../../core/services/entities/famille-egalim.service";

@Component({
  selector: 'yo-famille-egalim-dialog',
  templateUrl: './famille-egalim-dialog.component.html',
  styleUrls: ['./famille-egalim-dialog.component.scss']
})
export class FamilleEgalimDialogComponent implements OnInit {

  subOpenDialog: Subscription

  displayDialog = false;

  familleEgalim: FamilleEgalimDTO;
  idSelectedSite: number;

  form: FormGroup = new FormGroup({});

  dialogTitle: string = '';
  sitePlaceholder: string = 'Selectionner le site...';

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly toastSvc: ToastService,
              public readonly familleEgalimsSvc: FamilleEgalimService,
              public readonly utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.familleEgalimsSvc.openDialog$
      .subscribe((fe: FamilleEgalimDTO) => {
        this.displayDialog = true;
        if (!fe) {
          this.familleEgalim = new FamilleEgalimDTO();
          this.familleEgalim.id = 0;
          this.dialogTitle = 'Création d\'une famille EGAlim';
        } else {
          this.familleEgalim = fe;
          this.dialogTitle = 'Modification d\'une famille EGAlim';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      let newFamilleEgalim: FamilleEgalimDTO = {...this.familleEgalim};
      newFamilleEgalim.site = {id: this.idSelectedSite} as SiteDTO;
      newFamilleEgalim.libelle = this.form.controls['libelle'].value;
      newFamilleEgalim.code = this.form.controls['code'].value;
      newFamilleEgalim.ordre = this.form.controls['ordre'].value;
      newFamilleEgalim.actif = this.form.controls['actif'].value;
      this.familleEgalimsSvc.save(newFamilleEgalim)
        .subscribe((result) => {
          if (result && result.one) {
            this.familleEgalimsSvc.announceFamilleEgalimSaved(result.one);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la famille EGAlim réalisée avec succès`);
            this.closeDialog();
          }
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.familleEgalim?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.familleEgalim?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.familleEgalim?.libelle),
      code: new FormControl(this.familleEgalim?.code),
      ordre: new FormControl(this.familleEgalim?.ordre),
      actif: new FormControl(this.familleEgalim?.actif != null ? this.familleEgalim.actif : true),
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.familleEgalimsSvc.canModify(this.familleEgalim);
  }

}
