import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {SiteResolverService} from '../../site-resolver.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {TreeNode} from 'primeng/api';
import {SiteService} from '../../../core/site.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ContratmenuDTO} from '../../../core/dtos/contratmenu-dto';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {TreeNodeData} from '../../../core/tree-node-data';
import {ContratMenuService} from '../../../core/services/gestioncontrats/contrat-menu.service';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-ficheidentite-site',
  templateUrl: './ficheidentite-site.component.html',
  styleUrls: ['./ficheidentite-site.component.scss'],
})
export class FicheidentiteSiteComponent implements OnInit, OnDestroy {

  site: SiteDTO;
  isPopupDisplayed: boolean = false;
  dialogTitle: string = "Créer un site";

  // Subscriptions
  private subSave: Subscription;
  subSites: Subscription;
  subContrats: Subscription;
  subOpenDialog: Subscription;
  subDeleteSite: Subscription;

  // La form englobante
  formGroupCtrl: FormGroup;

  // Donnnées liées aux contrats
  listeAllContratsMenusActifs: ContratmenuDTO[];
  mapContratsMenus: Map<number, ContratmenuDTO[]>;

  /**
   * Liste de TOUS les Site actifs et ayant au moins un ContratMenu actif.
   */
  listeAllSitesActifsWithContratMenu: SiteDTO[];

  selectedContratsMenus: TreeNode[] = [];
  selectedContratsMenusReferent: TreeNode[] = [];

  rootContratsMenus: TreeNode;
  rootContratsMenusRererent: TreeNode = null;
  siteReferent: SiteDTO = new SiteDTO();

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private utils: UtilsService,
              private siteResolverService: SiteResolverService,
              private contratMenuService: ContratMenuService,
              private siteSvc: SiteService,
              private toastSvc: ToastService,
              private contratMenuSvc: ContratMenuService) {
  }

  ngOnInit() {
    this.initForm();
    this.initListeAllSitesActifsWithContratMenuActifs();
    this.initListeAllContratsMenusActifs();
    this.openDialogEditionSubscription();
  }

  /**
   * Initialisation des contrôles de la form
   */
  initForm(): void {
    // Initialisation de la form
    this.formGroupCtrl = this.fb.group({
      libelle: this.site?.libelle,
      reference: this.site?.reference,
      actif: this.site?.actif,
      adresse1: this.site?.adresse1,
      adresse2: this.site?.adresse2,
      adresse3: this.site?.adresse3,
      telephone: this.site?.telephone,
      fax: this.site?.fax,
      codePostal: this.site?.codePostal,
      ville: this.site?.ville,
      email: this.site?.email,
    });
  }

  private initListeAllContratsMenusActifs = (): void => {
    this.subContrats = this.contratMenuSvc.getListeAllContratsMenusActifs()
      .subscribe((contrats: ContratmenuDTO[]) => {
        this.listeAllContratsMenusActifs = contrats;
      });
  }

  private initListeAllSitesActifsWithContratMenuActifs = (): void => {
    this.subSites = this.siteSvc.findAllActifsWithContratMenu()
      .subscribe(sites => {
        this.listeAllSitesActifsWithContratMenu = sites as SiteDTO[];
      });
  }

  private openDialogEditionSubscription() {
    this.subOpenDialog = this.siteSvc.openDialog$
      .subscribe((site: SiteDTO) => {
        this.isPopupDisplayed = true;
        if (!site) {
          this.site = new SiteDTO();
          this.dialogTitle = 'Créer un site';
        } else {
          this.site = site;
          this.dialogTitle = 'Modification d\'un site';
        }
        this.initForm();
        const filteredListeAllSitesActifsWithContratMenu = this.listeAllSitesActifsWithContratMenu.filter(site => site.id != this.site.id);
        this.mapContratsMenus = this.buildMapContratsMenus(filteredListeAllSitesActifsWithContratMenu, this.listeAllContratsMenusActifs);
        this.siteReferent = new SiteDTO();
        this.updateData();
      });
  }

  private buildMapContratsMenus(listeAllSitesActifs: SiteDTO[], listeAllContratsMenusActifs: ContratmenuDTO[]): Map<number, ContratmenuDTO[]> {

    let map = new Map<number, ContratmenuDTO[]>();
    listeAllSitesActifs.forEach(siteDTO => {
      let contratmenuDTOArray: ContratmenuDTO[] = FicheidentiteSiteComponent.findContratMenuBySiteId(siteDTO.id, listeAllContratsMenusActifs);
      map.set(siteDTO.id, contratmenuDTOArray);
    });

    return map;
  }

  /**
   * Renvoie un tableau contenant les {@link ContratmenuDTO} issus du tableau arrayContratmenuDTO passé en paramètre
   * et dont le champ siteId est égal au paramètre siteId.
   * @param {number} siteId
   * @param {ContratmenuDTO[]} arrayContratmenuDTO
   * @returns {ContratmenuDTO[]}
   */
  static findContratMenuBySiteId(siteId: number, arrayContratmenuDTO: ContratmenuDTO[]): ContratmenuDTO[] {
    let contratArray = [];
    arrayContratmenuDTO.forEach(contratmenuDTO => {
      if (contratmenuDTO.site.id == siteId) {
        contratArray.push(contratmenuDTO);
      }

    })
    return contratArray;
  }


  static createTreeSite(siteDTO: SiteDTO, selectedContratsMenus: TreeNode[], listeAllContratsMenusActifs: ContratmenuDTO[], selectable: boolean): TreeNode {

    // La racine de l'arbre qui sera renvoyée par la fonction.
    let root: TreeNode = {
      children: []
    };

    // this.selectedContratsMenus = [];
    while (selectedContratsMenus.length > 0) {
      selectedContratsMenus.shift();
    }

    // Récupération de tous les contrats menus actifs.
    let arrayContratsMenus: ContratmenuDTO[] = listeAllContratsMenusActifs;


    // Iteration sur les contrats menus
    arrayContratsMenus.forEach(contratmenuDTO => {

      let childrenTreeNodeContratMenuConvives: TreeNode[] = [];

      // Itération sur les contrats menus convives du contrat menu courant
      contratmenuDTO.contratMenuConvives.forEach(contratMenuConviveDTO => {
        if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {

          let treeNodeData: TreeNodeData;

          treeNodeData = new TreeNodeData(contratMenuConviveDTO, "ContratMenuConviveDTO");
          let contratMenuConvive: TreeNode;
          if (contratMenuConviveDTO.modeleNutritionnel != null) {
            contratMenuConvive = FicheidentiteSiteComponent.createTreeNode("(" + contratMenuConviveDTO.id + ")" +
              contratMenuConviveDTO.convive.libelle +
              " - Modèle nutri : " + contratMenuConviveDTO.modeleNutritionnel.libelle +
              " " + contratMenuConviveDTO.site.libelle,
              treeNodeData,
              selectable,
              null);
          } else {
            contratMenuConvive = FicheidentiteSiteComponent.createTreeNode("(" + contratMenuConviveDTO.id + ")" +
              contratMenuConviveDTO.convive.libelle,
              treeNodeData,
              selectable,
              null);
          }
          childrenTreeNodeContratMenuConvives.push(contratMenuConvive);
          if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
            if (selectable) {
              contratMenuConvive.selectable = true;
            }
          } else {
            if (selectable) {
              contratMenuConvive.selectable = false;
            }
          }
        }
      });

      if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
        let treeNodeData: TreeNodeData;
        treeNodeData = new TreeNodeData(contratmenuDTO, "ContratmenuDTO");
        let treeNodeContratMenu: TreeNode = FicheidentiteSiteComponent.createTreeNode("(" + contratmenuDTO.id + ")" + contratmenuDTO.libelle + " - " + contratmenuDTO.code + "(" + contratmenuDTO.site.libelle + ")"
          , treeNodeData, selectable, childrenTreeNodeContratMenuConvives);

        root.children.push(treeNodeContratMenu);
        if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
          if (selectable) {
            treeNodeContratMenu.selectable = true;
          }
        } else {
          if (selectable) {
            treeNodeContratMenu.selectable = false;
          }
        }
      }
    });

    return root;
  }

  static createTreeNode(label: string, data: TreeNodeData, selectable: boolean, children: TreeNode[]): TreeNode {
    let treeNode: TreeNode = {
      label: label,
      data: data,
      children: children,
      selectable: selectable,
      expanded: true,
      styleClass: "large"
    };
    return treeNode;
  }

  save = (): void => {
    let newSite: SiteDTO = this.mapProprietesSiteAvecForm();
    this.subSave = this.siteSvc.save(newSite).subscribe(data => {
      if (data.inError) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Sauvegarde impossible : ${data.resultMessage}`);
      } else {
        this.site = data.one;
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le site ${this.site.libelle} a été enregistré avec succès`);
        this.siteResolverService.siteAnnounceSource.next(this.site);
        this.isPopupDisplayed = false;
      }
    }, err => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Impossible d'enregistrer le site ${this.site.libelle}`));
  };

  /**
   * Suppression du site passé en paramètre.
   * @param {SiteDTO} siteDTO
   */
  delete(siteDTO: SiteDTO) {
    this.subDeleteSite = this.siteSvc.deleteSite(siteDTO);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDeleteSite);
    this.utils.unsubscribe(this.subOpenDialog);
    this.utils.unsubscribe(this.subSites);
    this.utils.unsubscribe(this.subContrats);
    this.utils.unsubscribe(this.subSave);
  }

  updateData() {
    if (this.mapContratsMenus != undefined) {
      this.selectedContratsMenus = [];
      this.rootContratsMenus = FicheidentiteSiteComponent.createTreeSite(this.site, this.selectedContratsMenus, this.listeAllContratsMenusActifs, true);
      this.rootContratsMenusRererent = FicheidentiteSiteComponent.createTreeSite(this.siteReferent, this.selectedContratsMenusReferent, this.listeAllContratsMenusActifs, true);
    }
  }

  /**
   * Renvoie la différence entre les 2 listes (i.e. le tableau des éléments appartenant au preier tableau mais pas au second.
   * @param {ContratMenuConviveDTO[]} oldArray
   * @param {ContratMenuConviveDTO[]} newArray
   * @returns {ContratMenuConviveDTO[]}
   */
  diff(oldArray: ContratMenuConviveDTO[], newArray: ContratMenuConviveDTO[]): ContratMenuConviveDTO[] {
    let array: ContratMenuConviveDTO[] = [];
    if (oldArray != null) {
      oldArray.forEach(contratMenuConviveDTO => {
        let contratMenuConvive: ContratMenuConviveDTO = this.findContratsMenusConvivesDTOByIdFromArrayContratMenuConviveDTO(contratMenuConviveDTO.id, newArray);
        if (contratMenuConvive == null) {
          array.push(contratMenuConviveDTO);
        }
      })
    }
    return array;
  }

  findContratsMenusConvivesDTOByIdFromArrayContratMenuConviveDTO(id: number, arrayContratMenuConviveDTO: ContratMenuConviveDTO[]): ContratMenuConviveDTO {

    for (let contratMenuConviveDTO of arrayContratMenuConviveDTO) {
      if (contratMenuConviveDTO.id == id) {
        return contratMenuConviveDTO;
      }
    }
    return null;
  }

  help(): DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = `Fiche d’un site`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Identité d’un <b>site</b>`;
    p1.lines = [
      `Permet de renseigner l’identité d’un <b>site</b> avec 2 notions :`,
      `<ul> <li>un libellé</li> <li>une référence</li> </ul>`,
      `Pour être utilisable, vous devez activer un <b>site</b> `,
    ];

    dms.content = {
      intro: `Un <b>site</b> est un paramètre référentiel que l’on peut assimiler à un sac dans lequel on stockera les données créées par un site à des fins de partage ou pas dans un <b>environnement</b>.`,
      paragraphes: [p1]
    };

    return dms;
  }

  closeDialog = (): void => {
    this.isPopupDisplayed = false;
  }

  private mapProprietesSiteAvecForm = (): SiteDTO => {
    let result: SiteDTO = {...this.site};
    Object.keys(this.formGroupCtrl.controls).forEach(propriete => {
      if (this.formGroupCtrl.controls[propriete].value !== "")
        result[propriete] = this.formGroupCtrl.controls[propriete].value;
    });
    return result;
  }
}
