import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ObjectDTO} from '../../dtos/object-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {ProduitDeclinaisonDTO} from '../../dtos/produit-declinaison-dto';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {ProduitAllergeneDTO} from '../../dtos/produit-allergene-dto';
import {AllergeneDTO} from '../../dtos/allergene-dto';
import {startCase as _startCase} from 'lodash';
import {HttpService} from '../technique/http.service';


export const URL_POST_SAVE_ALLERGENES = `dolrest/gestionproduits/produit/save/allergenes`;

@Injectable({
  providedIn: 'root'
})
export class ProduitAllergeneService extends GenericHandler<ProduitAllergeneDTO> {


  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title,
              private httpSvc: HttpService) {
    super(utils, auth2Svc, router, http, title);

  }


  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());

  createEmptyDTO = (): ProduitAllergeneDTO => undefined;

  createEmptyProduitAllergene = (produitDeclinaison: ProduitDeclinaisonDTO, allergene: AllergeneDTO) => {
    const pa = new ProduitAllergeneDTO();

    pa.id = 0;

    pa.actif = false;
    pa.allergeneActif = allergene.actif;
    pa.allergeneLibellelong = allergene.appellationInformationConsommateur;
    pa.allergeneId = allergene.id;
    pa.allergeneLibelle = allergene.libelle;
    pa.allergeneCode = allergene.code;

    pa.produitDeclinaisonId = produitDeclinaison.id;
    pa.produitDeclinaisonLibelle = produitDeclinaison.libelle;
    pa.produitDeclinaisonActif = produitDeclinaison.actif;


    return pa;
  };

  /**
   * Enregistrer les allergenes d'un produit
   * @param produitsAllergenes
   */
  saveAllergenes = (produitsAllergenes: ProduitAllergeneDTO[], idProduit: number): Observable<ResponseWrapper<ProduitAllergeneDTO>> => this.httpSvc.post(URL_POST_SAVE_ALLERGENES + `/${idProduit}`, produitsAllergenes);

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => '';

  getEditObjectLabel = (data: ObjectDTO): string => '';

  getEntityName = (): string => 'produitAllergene';

  getFields = (dto: ObjectDTO): FormFieldBaseSupplier<any>[] => [];

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => [];

  getTitle = (): string => 'GESTION DES ALLERGÈNES PAR PRODUIT';

  getProduitsAllergene = (idProduit: number) => this.httpSvc.get(`dolrest/gestionproduits/produitAllergenes/${idProduit}`);
}
