import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {saveAs as fs_saveAs} from "file-saver";
import {USE_VIEW, VUE_SORTIES_POUR_PRODUCTION} from "../../../../core/constants";
import {PlanProductionDTO} from "../../../../core/dtos/plan-production-dto";
import {ProductionService} from "../../../../core/services/gestion-production/production.service";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {FilterPrintSortiesProductionDTO} from "../../../../core/dtos/gestion-production/filter-print-sorties-production-dto";
import {SortiePourProductionDTO} from "../../../../core/dtos/sortie-pour-production-dto";
import {F} from "@angular/cdk/keycodes";

@Component({
  selector: 'yo-print-sorties-production-settings',
  templateUrl: './print-sorties-production-settings.component.html',
  styleUrls: ['./print-sorties-production-settings.component.scss']
})
export class PrintSortiesProductionSettingsComponent implements OnInit, OnDestroy {

  @Input() selectedSorties :SortiePourProductionDTO[];

  displayDialog: boolean = false;
  form: FormGroup;
  selectedVue: VUE_SORTIES_POUR_PRODUCTION;
  planProduction: PlanProductionDTO;

  subOpenPrintSortiesProductionSettings: Subscription;

  constructor(private prodSvc: ProductionService,
              private utils: UtilsService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initOpenPrintSortiesProductionSettings();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenPrintSortiesProductionSettings);
  }

  initOpenPrintSortiesProductionSettings = () => {
    this.subOpenPrintSortiesProductionSettings = this.prodSvc.openPrintSortiesProductionSettings$.subscribe((data) => {
      this.planProduction = data.planProduction;
      this.selectedVue = data.selectedVue;
      this.displayDialog = true;
    })
  }

  initForm = (): void => {
    this.form = new FormGroup({
      numeroLot: new FormControl(false, Validators.required),
      dlc: new FormControl(false, Validators.required),
      qteTotalUS: new FormControl(false, Validators.required),
      commentaire: new FormControl(false, Validators.required),
      disablePageBreak: new FormControl(false, Validators.required),
      disableQuantityZero: new FormControl(true, Validators.required)
    });
  };

  printVueAtelier = () => {
    this.prodSvc.printSortiesProduction(this.planProduction.id, this.getCurrentView(), USE_VIEW.ATELIER, this.getFilterPrintSortiesProduction())
      .subscribe((response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `sorties-production-vue-atelier-${this.planProduction.id}-${new Date().getTime()}.pdf`);
      });
  };


  printVueDenree = () => {
    this.prodSvc.printSortiesProduction(this.planProduction.id, this.getCurrentView(), USE_VIEW.DENREE, this.getFilterPrintSortiesProduction())
      .subscribe((response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `sorties-production-vue-denree-${this.planProduction.id}-${new Date().getTime()}.pdf`);
      });
  };


  printVueZoneStockage = () => {
    this.prodSvc.printSortiesProduction(this.planProduction.id, this.getCurrentView(), USE_VIEW.ZONE_STOCKAGE, this.getFilterPrintSortiesProduction())
      .subscribe((response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        fs_saveAs(blob, `sorties-production-vue-zone-stockage-${this.planProduction.id}-${new Date().getTime()}.pdf`);
      });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  }

  private getSortiesIdList(): number[] {
    return this.selectedSorties.map(sortie => sortie.id)
  }

  private getCurrentView(): string {
    return this.selectedVue === VUE_SORTIES_POUR_PRODUCTION.DENREE ? 'DENREE' : 'STOCK';
  }

  private getFilterPrintSortiesProduction() {
    const filterPrintSortiesProduction: FilterPrintSortiesProductionDTO = new FilterPrintSortiesProductionDTO();
    filterPrintSortiesProduction.showNumeroLot = this.form.controls["numeroLot"].value;
    filterPrintSortiesProduction.showDlc = this.form.controls["dlc"].value;
    filterPrintSortiesProduction.showQteTotaleUS = this.form.controls["qteTotalUS"].value;
    filterPrintSortiesProduction.showCommentaire = this.form.controls["commentaire"].value;
    filterPrintSortiesProduction.disablePageBreak = this.form.controls["disablePageBreak"].value;
    filterPrintSortiesProduction.disableQuantityZero = this.form.controls["disableQuantityZero"].value;
    filterPrintSortiesProduction.selectedIds = this.getSortiesIdList();
    return filterPrintSortiesProduction;
  }
}
