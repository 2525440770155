import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ListeBesoinsDto} from '../../../../core/dtos/liste-besoins-dto';
import {catchError, debounceTime, delay, filter, switchMap, tap} from 'rxjs/operators';
import {BesoinsSupplier} from '../besoins-resolver.service';
import {BesoinsService} from '../../../../core/services/gestion-commandes/besoins.service';
import {ListeBesoinsDenreeDto} from '../../../../core/dtos/liste-besoins-denree-dto';
import {SelectItem} from 'primeng/api';
import {
  FS_ROUTES,
  HELP_FOLDERS,
  MSG_KEY,
  MSG_SEVERITY,
  UI_COLORS,
  USER_PREFERENCE,
  USER_TYPE_PREFERENCE
} from '../../../../core/constants';
import {PreferencesUtilisateurService} from '../../../../core/services/preferences-utilisateur.service';
import {PreferenceutilisateurDTO} from '../../../../core/dtos/preferenceutilisateur-dto';
import {DATAGRID_ROW_TYPES} from '../../../../core/services/technique/devextreme.service';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {WorkflowInstanceDTO} from '../../../../core/dtos/workflow-instance-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {DxDataGridComponent} from 'devextreme-angular';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {CatalogueAchatDTO} from '../../../../core/dtos/catalogue-achat-dto';
import {UniteDeProduction__SecteurFournisseurDTO} from '../../../../core/dtos/unite-de-production__secteur-fournisseur-dto';
import {BesoinOrigineViewModel} from '../view-models/besoin-origine-view-model';
import {ErreurFonctionnelleDto} from '../../../../core/dtos/erreur-fonctionnelle-dto';
import {WorkflowInstanceService} from '../../../../core/services/entities/workflow-instance.service';
import {ErreurFonctionnelleService} from '../../../../core/services/technique/erreur-fonctionnelle.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {GroupeAchatCaViewModel} from "../../../../core/models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";
import {UpdateGacaViewModel} from "../../../../shared/article/grille-remplacer-article/grille-remplacer-article.component";
import {ToastService} from "../../../../core/services/technique/toast.service";

export enum LbdViews {
  FABRICATION = 'fabrication',
  ACHAT = 'achat',
  DATE = 'date',
  TOUT = 'tout'
}

@Component({
  selector: 'yo-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;
  @ViewChild('opInfosDenree', {static: false}) opInfosDenree: OverlayPanel;


  lbdViews: SelectItem[] = [
    {label: 'Achats', value: LbdViews.ACHAT},
    {label: 'Dates', value: LbdViews.DATE},
    {label: 'Fabrication', value: LbdViews.FABRICATION},
    {label: 'Tout', value: LbdViews.TOUT},
  ];

  selectedLbdView = LbdViews.ACHAT;

  selectedBesoinsDenree: ListeBesoinsDenreeDto[] = [];
  localdata: ListeBesoinsDenreeDto[] = [];
  subParent: Subscription;
  subLbdView: Subscription;
  subPlatsDemandeurs: Subscription;
  subReglePrefereCalculTermine: Subscription;
  listeBesoin: ListeBesoinsDto;
  workflowInstance: WorkflowInstanceDTO;

  articlesDisponibles: CatalogueAchatDTO[] = [];
  articleSelected: CatalogueAchatDTO;
  prixLeMoinsCher: number;
  udpSfPrefereList: UniteDeProduction__SecteurFournisseurDTO[];
  selectedGacaViewModel: GroupeAchatCaViewModel;
  selectedLbd: ListeBesoinsDenreeDto;
  platsDemandeurs: any[];
  opInfosDenreeHasToBeVisible = false;
  noDataText: string = "En cours de chargement...";
  dropDownOptions = {width: 750};

  displayBulkEditionArticleDialog: boolean = false;

  pathFile: string = HELP_FOLDERS.LISTE_BESOINS + '/liste-besoins-articles';

  constructor(public utils: UtilsService,
              private route: ActivatedRoute,
              private wkfInstanceSvc: WorkflowInstanceService,
              private erreurSvc: ErreurFonctionnelleService,
              private besoinsSvc: BesoinsService,
              private prefUsrSvc: PreferencesUtilisateurService,
              private toastSvc: ToastService,
              private router: Router) {
  }


  ngOnInit(): void {
    // scruter le changement de vue et déclencher le recalcul de l'affichage
    this.lbdViewObservable();
    // si un calcul d'article prefere est terminé sur la liste de besoins, on doit rafraichir la grille
    this.reglePrefereCalculTermineObservable();
    // lors du passage de la souris sur la denree, on recupere les infos sur l'origine de la denree
    this.infosDenreesObservable();

    this.loadGrid();
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subParent);
    this.utils.unsubscribe(this.subLbdView);
    this.utils.unsubscribe(this.subReglePrefereCalculTermine);
  }


  infosDenreesObservable = () => {
    // afficher les plats demandeurs d'une denree
    let eventOpInfosDenree = null;
    this.subPlatsDemandeurs = this.besoinsSvc.platsDemandeurs$.pipe(
      tap(response => eventOpInfosDenree = response),
      tap(response => this.platsDemandeurs = []),
      debounceTime(300),
      filter(response => this.opInfosDenreeHasToBeVisible),
      switchMap(response => this.besoinsSvc.getPlatsDemandeursOfArticle(this.selectedLbd)),
    ).subscribe(response => {
      if (response) {
        this.platsDemandeurs = response.resultList;
        this.opInfosDenree.show(eventOpInfosDenree);
      }
    });
  };

  initErreurMessage = () => {
    this.erreurSvc.getErreurList(this.wkfInstanceSvc.getEntityName(), this.workflowInstance.id).subscribe((response: ResponseWrapper<ErreurFonctionnelleDto>) => {
      response.resultList.forEach((erreur: ErreurFonctionnelleDto) => {
        // pensement en attendant de réecrire le traitement... => suppression des doublons :
        let detailsUniques = [... new Set(erreur.detail.split(',')) ];
        if (detailsUniques.length > 5) {
          detailsUniques = detailsUniques.slice(0, 5);
          detailsUniques.push("...");
        }
        const strDetailsUniques = detailsUniques.join(',');
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, erreur.message);
      });
    });
  };


  isCellEditable = (wantToEdit: boolean) => {
    if (!this.utils.isNullOrEmpty(this.listeBesoin)
      && this.besoinsSvc.canModify(this.listeBesoin)
      && wantToEdit === true
    ) {
      return true;
    }
    return false;
  };

  reglePrefereCalculTermineObservable = () => {
    this.subReglePrefereCalculTermine = this.besoinsSvc.calculReglePrefereTermine$
      .pipe(
        switchMap(response => this.besoinsSvc.getLbdList(this.workflowInstance.id)),
        catchError(err => this.utils.handleError(err)))
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.localdata = response.resultList;
        }
      });
  };


  lbdViewObservable = () => {
    this.subLbdView = this.besoinsSvc.lbdView$.pipe(
      tap(data => {
        this.grid.loadPanel.enabled = true;
      }),
      delay(50),
      catchError(err => this.utils.handleError(err)))
      .subscribe(response => {
        try {
          switch (response.value) {
            case LbdViews.ACHAT:
              this.showViewAchats(this.grid);
              break;
            case LbdViews.FABRICATION:
              this.showViewFabrications(this.grid);
              break;
            case LbdViews.DATE:
              this.showViewDates(this.grid);
              break;
            case LbdViews.TOUT:
              this.showViewAll(this.grid);
              break;
            default:
              break;
          }
        } finally {
          this.grid.loadPanel.enabled = false;
        }
      })
  };

  loadGrid = () => {
    this.noDataText = "En cours de chargement...";
    this.utils.unsubscribe(this.subParent);
    this.subParent = this.route.parent.data
      .pipe(
        // @ts-ignore
        delay(10),
        switchMap((data: { besoinsSupplier: BesoinsSupplier }) => {
          this.workflowInstance = data.besoinsSupplier.workflowInstance;
          this.listeBesoin = data.besoinsSupplier.listeBesoin;
          this.initErreurMessage();
          return this.besoinsSvc.getLbdList(this.workflowInstance.id)
        }),
        switchMap((response: ResponseWrapper<ListeBesoinsDenreeDto>) => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.localdata = response.resultList;
            this.noDataText = "Pas de données";
            // clear selected rows
            if (this.grid !== undefined) {
              this.grid.instance.clearSelection();
              this.selectedBesoinsDenree = [];
            }
            // selectionner la vue à partir des preferences utilisateur
            this.selectLbdViewFromPreference(USER_PREFERENCE.LISTEBESOINS_ARTICLES_CHOIX_VUE);
          }
          this.grid.instance.endCustomLoading();
          return of(response);
        }),
        catchError(err => this.utils.handleError(err))
      ).subscribe(reponse => {
      });
  };

  selectLbdViewFromPreference = (prefKey: string) => {
    const prefVue: PreferenceutilisateurDTO = this.prefUsrSvc.getPreferenceUtilisateur(prefKey);
    if (!this.utils.isNullOrEmpty(prefVue)) {
      this.changeLbdView({value: prefVue.valeur});
    }
  };

  changeLbdView = ($event: any) => {
    this.besoinsSvc.announceLbdView($event);
  };

  private showViewAchats = (devExtremeGrid: DxDataGridComponent) => {

    devExtremeGrid.instance.columnOption('id', 'visible', false);
    devExtremeGrid.instance.columnOption('uniteDeProductionLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('produitDeclinaisonLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('denreeInitialeLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('catalogueAchatLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('fournisseurLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('besoin', 'visible', false);
    devExtremeGrid.instance.columnOption('besoinAjuste', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUt', 'visible', true);
    devExtremeGrid.instance.columnOption('qteUc', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUc', 'visible', true);
    devExtremeGrid.instance.columnOption('qteUf', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUf', 'visible', true);
    devExtremeGrid.instance.columnOption('prixUnitaireUF', 'visible', true);
    devExtremeGrid.instance.columnOption('prixQuantiteUf', 'visible', true);
    devExtremeGrid.instance.columnOption('dateLivFournisseur', 'visible', true);
    devExtremeGrid.instance.columnOption('dateAppro', 'visible', false);
    devExtremeGrid.instance.columnOption('dateSortie', 'visible', false);
    devExtremeGrid.instance.columnOption('dateFabrication', 'visible', false);
    devExtremeGrid.instance.columnOption('dateConsommation', 'visible', true);
    devExtremeGrid.instance.columnOption('zoneDeProdLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('equipeLibelle', 'visible', false);
  };

  private showViewFabrications = (devExtremeGrid: DxDataGridComponent) => {
    devExtremeGrid.instance.columnOption('id', 'visible', false);
    devExtremeGrid.instance.columnOption('uniteDeProductionLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('produitDeclinaisonLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('denreeInitialeLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('catalogueAchatLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('fournisseurLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('besoin', 'visible', false);
    devExtremeGrid.instance.columnOption('besoinAjuste', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUt', 'visible', true);
    devExtremeGrid.instance.columnOption('qteUc', 'visible', false);
    devExtremeGrid.instance.columnOption('udmUc', 'visible', false);
    devExtremeGrid.instance.columnOption('qteUf', 'visible', false);
    devExtremeGrid.instance.columnOption('udmUf', 'visible', false);
    devExtremeGrid.instance.columnOption('prixUnitaireUF', 'visible', false);
    devExtremeGrid.instance.columnOption('prixQuantiteUf', 'visible', false);
    devExtremeGrid.instance.columnOption('dateLivFournisseur', 'visible', true);
    devExtremeGrid.instance.columnOption('dateAppro', 'visible', true);
    devExtremeGrid.instance.columnOption('dateSortie', 'visible', true);
    devExtremeGrid.instance.columnOption('dateFabrication', 'visible', true);
    devExtremeGrid.instance.columnOption('dateConsommation', 'visible', true);
    devExtremeGrid.instance.columnOption('zoneDeProdLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('equipeLibelle', 'visible', true);

  };

  private showViewDates = (devExtremeGrid: DxDataGridComponent) => {
    devExtremeGrid.instance.columnOption('id', 'visible', false);
    devExtremeGrid.instance.columnOption('uniteDeProductionLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('produitDeclinaisonLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('denreeInitialeLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('catalogueAchatLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('fournisseurLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('besoin', 'visible', false);
    devExtremeGrid.instance.columnOption('besoinAjuste', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUt', 'visible', true);
    devExtremeGrid.instance.columnOption('qteUc', 'visible', false);
    devExtremeGrid.instance.columnOption('udmUc', 'visible', false);
    devExtremeGrid.instance.columnOption('qteUf', 'visible', false);
    devExtremeGrid.instance.columnOption('udmUf', 'visible', false);
    devExtremeGrid.instance.columnOption('prixUnitaireUF', 'visible', false);
    devExtremeGrid.instance.columnOption('prixQuantiteUf', 'visible', false);
    devExtremeGrid.instance.columnOption('dateLivFournisseur', 'visible', true);
    devExtremeGrid.instance.columnOption('dateAppro', 'visible', true);
    devExtremeGrid.instance.columnOption('dateSortie', 'visible', true);
    devExtremeGrid.instance.columnOption('dateFabrication', 'visible', true);
    devExtremeGrid.instance.columnOption('dateConsommation', 'visible', true);
    devExtremeGrid.instance.columnOption('zoneDeProdLibelle', 'visible', false);
    devExtremeGrid.instance.columnOption('equipeLibelle', 'visible', false);
  };

  private showViewAll = (devExtremeGrid: DxDataGridComponent) => {
    devExtremeGrid.instance.columnOption('id', 'visible', true);
    devExtremeGrid.instance.columnOption('uniteDeProductionLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('produitDeclinaisonLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('denreeInitialeLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('catalogueAchatLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('fournisseurLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('besoin', 'visible', true);
    devExtremeGrid.instance.columnOption('besoinAjuste', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUt', 'visible', true);
    devExtremeGrid.instance.columnOption('qteUc', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUc', 'visible', true);
    devExtremeGrid.instance.columnOption('qteUf', 'visible', true);
    devExtremeGrid.instance.columnOption('udmUf', 'visible', true);
    devExtremeGrid.instance.columnOption('prixUnitaireUF', 'visible', true);
    devExtremeGrid.instance.columnOption('prixQuantiteUf', 'visible', true);
    devExtremeGrid.instance.columnOption('dateLivFournisseur', 'visible', true);
    devExtremeGrid.instance.columnOption('dateAppro', 'visible', true);
    devExtremeGrid.instance.columnOption('dateSortie', 'visible', true);
    devExtremeGrid.instance.columnOption('dateFabrication', 'visible', true);
    devExtremeGrid.instance.columnOption('dateConsommation', 'visible', true);
    devExtremeGrid.instance.columnOption('zoneDeProdLibelle', 'visible', true);
    devExtremeGrid.instance.columnOption('equipeLibelle', 'visible', true);
  };


  onRowUpdated = ($event: any) => {
    $event.data.modificationManuelle = true;
    this.besoinsSvc.saveLbdList([$event.data])
      .subscribe(result => {
        this.replaceElements(result.resultList);
      });
  };

  showInfosDenree = (lbd: ListeBesoinsDenreeDto, $event) => {
    this.selectedLbd = lbd;
    this.opInfosDenreeHasToBeVisible = true;
    this.besoinsSvc.announcePlatsDemandeurs($event);
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA && event.data.modificationManuelle) {
      event.rowElement.style.backgroundColor = UI_COLORS.MODIFICATION_MANUELLE;
      event.rowElement.className = event.rowElement.className.replace('dx-row-alt', '');
    }
  };

  onCellPrepared = (event: any) => {
    // si cell est un header
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
      if (event.column.dataField === 'produitDeclinaisonLibelle') {
        event.cellElement.style.backgroundColor = UI_COLORS.INFO;
      }
      // si colonne editable
      else if (event.column.allowEditing) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }
  };


  getHelp = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `LISTE DE BESOINS`;

    let p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
      `Besoin ajusté : est exprimé en unité technique. `,
      `Qte UC        : quantité en unité de commande.`,
      `Qte UF        : quantité en unité de facturation. `,
      `PU            : prix unitaire en unité de facturation. `,
      `PU*Qte        : prix unitaire multiplié par la quantité en unite de facturation. `,
    ];

    dms.content = {
      intro: ``,
      paragraphes: [p1]

    };
    return dms;
  };

  exportAll = (): void => {
    this.besoinsSvc.getLbdListXls(this.workflowInstance.id)
      .subscribe((response) => {
        try {
          const blob = new Blob([response], {type: 'application/vnd.ms-excel'});
          fs_saveAs(blob, 'besoins-' + new Date().getTime() + '.xlsx');
        } catch (e) {
          console.error(e);
        }
      });
  }

  onEditingStart = ($event: any) => {
    // console.log('onEditingStart',$event);
    this.selectedLbd = $event.data;

    // si on edite la colonne article, on doit aller chercher les articles dispo dynamiquement pour cette lbd
    if ($event.column.dataField === 'catalogueAchatLibelle') {
      this.articlesDisponibles = [];

      this.besoinsSvc.getArticlesDisponibles(this.selectedLbd.produitDeclinaisonId, this.selectedLbd.uniteDeProductionId, this.selectedLbd.dateLivFournisseur).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.articlesDisponibles = response.resultList;
          this.articleSelected = this.articlesDisponibles.find(article => article.id === this.selectedLbd.catalogueAchatId);
          this.udpSfPrefereList = response.additionalProperties['udpSfPrefereList'];
          this.selectedGacaViewModel = this.initGroupeAchatCaViewModel(this.articlesDisponibles, this.selectedLbd);
        }
      });
    }
  };

  /**
   * Récupérer le gacaViewModel qui se rapproche le plus de la denree
   */
  initGroupeAchatCaViewModel = (articlesDisponibles: CatalogueAchatDTO[], selectedLbd: ListeBesoinsDenreeDto): GroupeAchatCaViewModel => {


    if (!this.utils.isCollectionNullOrEmpty(articlesDisponibles) && !this.utils.isNullOrEmpty(selectedLbd)) {

      let selectedList: GroupeAchatCaViewModel[] = [];

      for (const article of articlesDisponibles) {
        if (!this.utils.isCollectionNullOrEmpty(article.groupeAchatCaList)) {
          for (const gacaVm of article.groupeAchatCaList) {
            if (gacaVm.idCatalogueAchat === selectedLbd.catalogueAchatId) {
              if (gacaVm.prefere) {
                return gacaVm;
              } else {
                selectedList.push(gacaVm);
              }

            }
          }
        }
      }

      if (!this.utils.isCollectionNullOrEmpty(selectedList)) {
        return selectedList[0];
      }

    }

    return null;
  };


  onSelectionChanged = ($event: any, cell: any, component: any) => {
    cell.setValue($event.selectedRowKeys[0]);
    if ($event.selectedRowKeys.length > 0) {
      cell.data.catalogueAchatId = $event.selectedRowsData[0].id;
      this.articleSelected = this.articlesDisponibles.find(article => article.id === $event.selectedRowsData[0].id);
      component.close();
    }
  };

  closeInfosDenree = ($event: MouseEvent) => {
    this.opInfosDenreeHasToBeVisible = false;
    this.opInfosDenree.hide();
  };


  onCloseBulkEditionArticleDialog = () => {
    this.displayBulkEditionArticleDialog = false;
  };

  openBulkEditionDenreeDialog = () => {
    this.displayBulkEditionArticleDialog = true;
  };

  onSelectionChangedDxGrid = (event: any) => {
    this.selectedBesoinsDenree = event.selectedRowsData;
  };


  onEmitBulkDetailSupplier = (supplier: any) => {
    //Affectation des donnnés du bulk
    this.selectedBesoinsDenree.forEach((besoin: ListeBesoinsDenreeDto) => {
      //Date
      besoin.dateSortie = !supplier.dateSortie ? besoin.dateSortie : supplier.dateSortie;
      besoin.dateConsommation = !supplier.dateConso ? besoin.dateConsommation : supplier.dateConso;
      besoin.dateFabrication = !supplier.dateFab ? besoin.dateFabrication : supplier.dateFab;
      besoin.dateAppro = !supplier.dateAppro ? besoin.dateAppro : supplier.dateAppro;
      besoin.dateLivFournisseur = !supplier.dateLiv ? besoin.dateLivFournisseur : supplier.dateLiv;

      //Autre
      besoin.besoinAjuste = supplier.besoinAjuste === null || supplier.besoinAjuste === undefined ? besoin.besoinAjuste : supplier.besoinAjuste;
      besoin.modificationManuelle = true;

    });

    // sauvegarde
    this.besoinsSvc.saveLbdList(this.selectedBesoinsDenree)
      .subscribe(r => {
        this.replaceElements(r.resultList);
      });

    // clear selected rows
    if (this.grid !== undefined) {
      this.grid.instance.clearSelection();
      this.selectedBesoinsDenree = [];
    }
  };

  updatePrixUnitaireLbd = (component: any, $event: UpdateGacaViewModel) => {

    this.selectedLbd.prixUnitaireUF = $event.prixUF;
    this.selectedLbd.catalogueAchatId = $event.catalogueAchatId;
    this.selectedLbd.modificationManuelle = $event.modificationManuelle;

    this.articleSelected = this.articlesDisponibles.find(article => article.id === this.selectedLbd.catalogueAchatId);

    this.besoinsSvc.replaceLbdArticle(this.selectedLbd)
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {

          for (let i = 0; i < this.localdata.length; i++) {
            if (this.localdata[i].id === response.one.id) {
              this.localdata[i] = response.one;
              break;
            }
          }
        }
      });

    component.close();

  };

  onFocusReplaceArticle = ($event: any) => {
    console.log($event)
    if (!this.utils.isCollectionNullOrEmpty(this.articlesDisponibles)
      && !this.utils.isNullOrEmpty(this.selectedLbd)
    ) {
      this.selectedGacaViewModel = this.initGroupeAchatCaViewModel(this.articlesDisponibles, this.selectedLbd);
      this.articleSelected = this.articlesDisponibles.find(article => article.id === this.selectedLbd.catalogueAchatId);
    }
  };

  private replaceElements = (resultList: ListeBesoinsDenreeDto[]) => {
    resultList.forEach(result => {
      let id = this.localdata.findIndex(item => item.id == result.id);
      this.localdata[id] = result;
    })
    this.grid.instance.refresh()
  }

  showArticle = value => this.articlesDisponibles.find(article => article.produitArticle.libelle === value).id;
}



