<dx-list
  #dxList
  [dataSource]="data"
  [searchEnabled]="searchEnabled"
  [searchExpr]="displayValue"
  searchMode="contains"
  keyExpr="{{key}}"
  displayExpr="{{displayValue}}"
  [selectedItems]="selectedItems"
  [selectedItemKeys]="selectedItemKeys"
  (onSelectionChanged)="valueChanged($event)"
  selectionMode="{{selectionMode}}"
  selectAllMode="{{selectAllMode}}}"
  [showSelectionControls]="true"
  height="100%"
  [disabled]="disabled"
>

</dx-list>
