<div class="custom-toolbar">
  <div id="before-toolbar" class="custom-toolbar-subdivision">
    <yo-help lang="fr" [file]="pathFile" [width]="800"></yo-help>
    <yo-commande-fournisseur-date-filter
      (onValueChange)="onDateFilterValueChange($event)"
    >
    </yo-commande-fournisseur-date-filter>

    <dx-text-box
      class="box-size-1"
      placeholder="Filtrer par numéro de commande..."
      (onValueChanged)="onChangeFilterNumCommande($event)"
    >
    </dx-text-box>

    <dx-tag-box
      class="box-size-1"
      [multiline]="false"
      [dataSource]="filterItemsFournisseur"
      placeholder="Filtrer par fournisseur..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      (onValueChanged)="onChangeFilterFournisseurs($event)"
    >
    </dx-tag-box>

    <dx-tag-box
      class="box-size-1"
      [multiline]="false"
      [dataSource]="filterItemsUdp"
      placeholder="Filtrer par unité de production..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      (onValueChanged)="onChangeFilterUdps($event)"
    >
    </dx-tag-box>

    <dx-tag-box
      class="box-size-2"
      [dataSource]="filterItemsListeBesoin"
      placeholder="Filtrer par liste de besoins..."
      displayExpr="value"
      valueExpr="id"
      [searchEnabled]="true"
      [showSelectionControls]="true"
      [multiline]="false"
      (onValueChanged)="onChangeFilterListesBesoins($event)"
    >
    </dx-tag-box>
  </div>
  <div id="after-toolbar" class="custom-toolbar-subdivision">
    <yo-button iconClass="fas fa-play"
               tooltip="Passer à l'étape suivante"
               [disabled]="isActionDisabled"
               (onClick)="menu.toggle($event)">
      <p-menu appendTo="body" #menu [popup]="true" [model]="nextSteps"></p-menu>
    </yo-button>
    <yo-button
      iconClass="fa fa-envelope"
      (onClick)="openPopupSendMail()"
      [disabled]="isActionDisabled"
      tooltip="Envoyer par mail les commandes aux fournisseurs sélectionnés">
    </yo-button>
    <yo-button
      iconClass="fas fa-print"
      (onClick)="printPDFCommandes()"
      [disabled]="isActionDisabled"
      tooltip="Imprimer les commandes sélectionnées au format pdf">
    </yo-button>
    <yo-button
      iconClass="fas fa-code-merge"
      (onClick)="prepareToMergeBonsCf()"
      [disabled]="isActionDisabled"
      tooltip="Fusionner les propositions sélectionnées">
    </yo-button>
    <yo-button
      iconClass="fa fa-trash"
      buttonClass="cta-inner-delete"
      (onClick)="deleteBonCfs()"
      [disabled]="isActionDisabled"
      tooltip="Supprimer les commandes sélectionnées">
    </yo-button>
  </div>
</div>

<dx-data-grid
  (onSelectionChanged)="checkDisabledButtons()"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  (onCellClick)="cellClick($event)"
  #grid>
  <dxo-selection [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode"
                 mode="multiple"></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="120" cellTemplate="templateActions"
              [allowSorting]="false"></dxi-column>
  <dxi-column dataField="numeroBcf" caption="Bon de commande" cellTemplate="templateBonCf"></dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande"></dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" caption="Date livraison"
              cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production"
              cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left"
              cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left"
              cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
    <yo-cell-button (yoNavigation)="openObject(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'fas fa-edit':'fas fa-eye'"
                    [yoTooltip]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'Modifier la commande':'Voir la commande'">
    </yo-cell-button>
    <yo-button
      buttonClass="cta-inner-edit-or-watch"
      iconClass="fas fa-clone"
      size="small"
      (onClick)="prepareToDuplicateBonCf(cell.row.data)"
      tooltip="Dupliquer la proposition sélectionnée">
    </yo-button>
    <yo-button
      buttonClass="cta-inner-edit-or-watch"
      iconClass="fas fa-truck"
      size="small"
      (onClick)="openReassignDialog(cell.row.data)"
      tooltip="Réassigner la proposition sélectionnée">
    </yo-button>
  </div>
  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle"
               [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
      <span *ngIf="isMinimumCommandInError(cell.row.data)">
            <i class="fas fa-cart-arrow-down danger-color space"
               title="Minimum de commande non atteint"></i>
      </span>
      <span *ngIf="isConditionneParInError(cell.row.data)">
            <i class="fas fa-box-open danger-color space"
               title="Conditionné par non respecté"></i>
      </span>
      <span *ngIf="isFraisPortApplyed(cell.row.data)">
            <i class="fas fa-truck danger-color space"
               title="Des frais de port sont appliqués"></i>
      </span>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a title="Aller sur la liste de besoins"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateBonCf'">
    <span class="mg-r-5">{{cell.row.data.numeroBcf}}</span>
    <yo-email-histo-button
      [code]="cell.row.data.numeroBcf"
      [emailHisto]="cell.row.data.mailHisto"
      (onMailHistoRetreive)="onEmailHistoRretreived($event)">
    </yo-email-histo-button>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} commande(s) à valider">
  </dxo-pager>

</dx-data-grid>

<!--TEMPLATE NUMERO BON COMMANDE-->
<ng-template #tplNumeroBcf let-bonCf="bonCf">

  <span class="mg-r-5">{{bonCf.numeroBcf}}</span>
  <span class="mg-r-5" *ngIf="bonCf.mailHisto">

        <!--statut du bon de réception-->
    <label
      [title]="mailHistoSvc.getLabelStatut(bonCf.mailHisto.statut)"
      class="badge mg-r-5 cursor"
      [class.badge-warning]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.EN_COURS_ENVOI"
      [class.badge-primary]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.ENVOYE"
      [class.badge-success]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.LU"
      [class.badge-danger]="bonCf.mailHisto.statut === BONCF_MAIL_STATUT.EN_ERREUR"
      (click)="openHistoriqueMails(bonCf)"
    >

       <span class="fa-layers fa-fw">
    <i class="fas fa-envelope mg-r-5"></i>
    <span class="fa-layers-counter">{{mailHistoSvc.getShortLabel(bonCf.mailHisto.statut)}}</span>
  </span>


    </label>

  </span>
</ng-template>

<!--DIALOG MODIFIER UNE COMMANDE CONFIRMEE-->
<yo-bc-edit-fournisseur [displayDialog]="displayEditDialog" (onSave)="onBonCommandSaved()"
                        (onClose)="displayEditDialog=$event" [bonCf]="bonCf" [lignesBonCf]="lignesBonCf"
                        [bonCfs]="commandesAValider">
</yo-bc-edit-fournisseur>

<yo-merge-boncf
  #mergeComponent
  [displayDialog]="displayMergeDialog"
  [selectedBonCfsToMerge]="selectedBonCfsToMerge"
  [uniteDeProduction]="selectedUniteDeProductionToMerge"
  (onSave)="mergeBonsCf($event)"
  (onClose)="onCloseMerge($event)"></yo-merge-boncf>

<yo-duplication-boncf
  #duplicationComponent
  [displayDialog]="displayDuplicationDialog"
  [selectedBonCfToDuplicate]="selectedBonCfToDuplicate"
  [uniteDeProduction]="selectedUniteDeProductionToDuplicate"
  (onSave)="duplicateBNonCf($event)"
  (onClose)="onCloseDuplication($event)"></yo-duplication-boncf>

<yo-reassignBcFournisseurDialog></yo-reassignBcFournisseurDialog>

<dx-popup
  [showTitle]="true"
  title="Éléments sans correspondance "
  [visible]="displayNoCorrespondancePopup"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeNoCorrespondancePopup(false)"
>
  <label>Les éléments non présents dans cette liste ont bien été réassignés.</label>
  <dx-data-grid
    [dataSource]="noCorrespondanceList"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="referenceArticle" caption="Réf. article" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxi-column dataField="description" caption="Description" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur(s)">
    </dxo-pager>
  </dx-data-grid>
  <div class="d-flex mg-t-10 justify-content-end">
    <yo-button
      class="mg-l-5"
      label="Ouvrir le nouveau bon de commande"
      (onClick)="closeNoCorrespondancePopup(true)"
      iconClass="fas fa-external-link">
    </yo-button>
  </div>
</dx-popup>

<yo-send-mail
  [displayDialog]="displayPopupSendMail"
  (onSave)="sendEmail($event)"
  (onClose)="onCloseSendMail($event)"
></yo-send-mail>
