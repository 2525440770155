import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {PointDeLivraisonDTO} from '../../dtos/point-de-livraison-d-t-o';
import {UtilsService} from '../../utils/utils.service';
import {Auth2Service} from '../security/auth2.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {catchError} from 'rxjs/operators';
import {ContratMenuConviveDecoupage__PlcDTO} from '../../dtos/contratmenuconvivedecoupage__plc-dto';
import {ContratMenuConviveDecoupageDTO} from '../../dtos/contratmenuconvivedecoupage-dto';
import {ContratMenuConviveDTO} from '../../dtos/contratmenuconvive-dto';
import {ContratMenuConvive__PlcDTO} from '../../dtos/contratmenuconvive__plc-dto';
import {startCase as _startCase} from 'lodash';
import {Cmcr__CmcCa__PlcDto} from '../../dtos/cmcr__cmcca__plc-dto';

@Injectable({
  providedIn: 'root'
})
export class CmcdPlcService extends GenericHandler<ContratMenuConviveDecoupage__PlcDTO> {

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService
  ) {
    super(utils, auth2Svc, router, http, title);
  }

  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }


  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES POINTS DE LIVRAISON CLIENT';
  }

  getCreateNewObjectLabel(): string {
    return '';
  }


  getEntityName(): string {
    return 'contratMenuConviveDecoupage__Plc';
  }

  getFields(dto: PointDeLivraisonDTO): FormFieldBaseSupplier<any>[] {

    return undefined;

  }


  getAllFromEnvironnement(): void {
  }


  createEmptyDTO(): ContratMenuConviveDecoupage__PlcDTO {
    return undefined;
  }

  /**
   * Créer un nouvelle cellule de saisie des effectifs prev pour un jour/repas/decoupage/plat
   * @param cmcd
   * @param plc
   * @param ordre
   * @return {ContratMenuConviveDecoupage__PlcDTO}
   */
  initCmcdPlc(cmcd: ContratMenuConviveDecoupageDTO, cmcPlc: ContratMenuConvive__PlcDTO, ordre: number): ContratMenuConviveDecoupage__PlcDTO {

    const cmcdPlc = new ContratMenuConviveDecoupage__PlcDTO();

    cmcdPlc.idRepas = cmcd.repasId;
    cmcdPlc.idDecoupageRepas = cmcd.decoupageRepasId;
    cmcdPlc.nombreChoixMaximum = cmcd.nombreChoixMaximum;
    cmcdPlc.idJourSemaine = cmcd.contratMenuConviveRepasIdJourSemaine;
    cmcdPlc.idContratMenuConviveDecoupage = cmcd.id;
    cmcdPlc.idContratMenuConvivePlc = cmcPlc.id;
    cmcdPlc.effectifPrevu = cmcd.contratMenuConviveRepasEffectifPrevu;
    cmcdPlc.ordre = ordre;


    return cmcdPlc;
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return "";
  }

  saveGrille(contratMenuConvive:ContratMenuConviveDTO,pointDeLivraison:PointDeLivraisonDTO, cmcdPlcList: ContratMenuConviveDecoupage__PlcDTO[],
             cmcrCmcCaPlcList:Cmcr__CmcCa__PlcDto[]) {

    const fd = new FormData();
    fd.set('cmcdPlcList', JSON.stringify(cmcdPlcList));
    fd.set('cmcrCmcCaPlcList', JSON.stringify(cmcrCmcCaPlcList));

    const url = `dolrest/gestioncontrats/prestation/${contratMenuConvive.id}/point-de-livraison/${pointDeLivraison.id}/effectifs-previsionnels`;

    return this.http.post(url, fd)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  }
}
