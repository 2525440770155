<dx-lookup
  #lookup
  [dataSource]="data"
  [valueExpr]="key"
  [value]="getAutoSelectedData()"
  [displayExpr]="displayValue"
  [searchEnabled]="enableSearch"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (onValueChanged)="valueChanged($event)"
  [showClearButton]="showClearButton"
  [width]="width"
>
  <dxo-drop-down-options [showTitle]="false"></dxo-drop-down-options>
</dx-lookup>
