import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FamilleProduitDTO} from '../../../core/dtos/famille-produit-dto';
import {FormControl, FormGroup} from '@angular/forms';
import {FamillesProduitService} from '../../../core/services/entities/familles-produit.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {DxTreeListComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {cloneDeep as _cloneDeep} from 'lodash'
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ToastService} from "../../../core/services/technique/toast.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";


@Component({
  selector: 'yo-dialog-form-famille-produit',
  templateUrl: './dialog-form-famille-produit.component.html',
  styleUrls: ['./dialog-form-famille-produit.component.scss']
})
export class DialogFormFamillesProduitComponent implements OnInit, OnDestroy {

  @ViewChild('parentlist') parentTreeList: DxTreeListComponent;

  displayDialog: boolean = false;
  parentList: FamilleProduitDTO[];

  selectedFamilleProduit: FamilleProduitDTO;
  selectedParent: FamilleProduitDTO;

  form: FormGroup;

  titleDialog: string;
  isReadOnly: boolean;

  subFamilleProduit: Subscription;
  selectedRowKeys: any[] = [];

  idSelectedSite: number;

  sitePlaceholder: String = 'Selectionner le site...';

  constructor(private readonly familleProduitSvc: FamillesProduitService,
              public readonly utils: UtilsService,
              private readonly auth2Svc: Auth2Service,
              private readonly toastSvc: ToastService,
              private readonly i8nSvc: InternationalizationService) {
  }

  ngOnInit(): void {
    this.familleProduitSubscription();
    this.initForm();
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFamilleProduit);
  }

  familleProduitSubscription = () => {
    this.selectedRowKeys = [];
    this.subFamilleProduit = this.familleProduitSvc.familleProduit$.subscribe((response: any) => {

      let familleProduit: FamilleProduitDTO = response.familleProduit;
      this.parentList = response.parents;

      this.displayDialog = true;
      this.selectedFamilleProduit = _cloneDeep(familleProduit);

      if (!familleProduit) {
        this.selectedFamilleProduit = new FamilleProduitDTO();
        this.titleDialog = this.i8nSvc.getLabelFromCode("TITLE_CREATION_FAMILY_PRODUCT", null);
      } else {
        this.rightsCheck();
        this.titleDialog = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_FAMILY_PRODUCT", null);
        this.selectedRowKeys = [];
        if (!this.selectedFamilleProduit.parent) {
          this.selectedParent = this.selectedFamilleProduit.parent;
        } else {
          this.selectedRowKeys.push(this.selectedFamilleProduit.parent.id);
          this.selectedParent = this.selectedFamilleProduit.parent;
        }
      }
      this.initForm();
    });
  };

  initForm = () => {
    this.sitePlaceholder = !this.canModify() ? this.selectedFamilleProduit?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.selectedFamilleProduit?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.selectedFamilleProduit?.libelle),
      fab: new FormControl(this.selectedFamilleProduit?.fabrique != null ? this.selectedFamilleProduit?.fabrique : true),
      actif: new FormControl(this.selectedFamilleProduit?.actif != null ? this.selectedFamilleProduit.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.familleProduitSvc.canModify(this.selectedFamilleProduit);
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = () => {
    this.parentTreeList.instance.clearFilter("row");
    this.displayDialog = false;
    this.selectedParent = undefined;
    if (this.selectedFamilleProduit?.parent) this.selectedFamilleProduit.parent = undefined;
    this.selectedRowKeys = [];
    this.familleProduitSvc.announceFamilleProduitSaved();
  };

  onChangeParentSelection = (event: any) => {
    this.selectedParent = event.selectedRowsData[0];
    this.selectedFamilleProduit.parent = event.selectedRowsData[0];
  };

  rightsCheck = () => {
    if(!this.utils.isNullOrEmpty(this.selectedFamilleProduit)){
      this.isReadOnly = !this.auth2Svc.isSiteLocal(this.selectedFamilleProduit.site.id);
    }
  };

  save = () => {
    if (this.form.valid) {
      if (this.checkIfFamilyParentIsItSelf()) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Le parent selectionné ${this.selectedParent.libelle} ne peut pas être le même famille produit sur lequel vous travaillez`);
        return;
      }

      let familleProduit: any = {...this.selectedFamilleProduit };
      familleProduit.site = {id: this.idSelectedSite } as SiteDTO;
      familleProduit.libelle = this.form.controls['libelle'].value;
      familleProduit.fabrique = this.form.controls['fab'].value;
      familleProduit.actif = this.form.controls['actif'].value;
      delete familleProduit.referenceSite;
      delete familleProduit.codeSite;

      if (this.selectedFamilleProduit?.parent)
        familleProduit.parent = { id: this.selectedFamilleProduit.parent.id } as FamilleProduitDTO;

      this.familleProduitSvc.save(familleProduit)
        .subscribe(() => {
          this.familleProduitSvc.announceFamilleProduitSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la famille de produit réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  checkIfFamilyParentIsItSelf = () => !this.utils.isNullOrEmpty(this.selectedParent) && this.selectedParent.id === this.selectedFamilleProduit.id;

  removeParent = () => {
    this.selectedParent = undefined;
    this.selectedRowKeys = [];
  };
}
