import {Injectable} from "@angular/core";
import {HttpService} from "../../technique/http.service";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../../suppliers/wrappers/response-wrapper";
import {RegleRegimeModel} from "../../../models/gestion-conditionnements/regroupement/regle-regime-model";
import {HttpParams} from "@angular/common/http";
import {ConviveDTO} from "../../../dtos/convive-dto";

export const URL_GET_ALL_PRESTA_REGIMES_BY_ID_PLC = 'dolrest/conditionnements/config-regroupement/list';
export const URL_GET_ALL_RULES_BY_ID_PLC = 'dolrest/conditionnements/config-regroupement/list/rules';
export const URL_DELETE = 'dolrest/conditionnements/config-regroupement/delete';
export const URL_SAVE = 'dolrest/conditionnements/config-regroupement/save';

@Injectable({
  providedIn: 'root'
})
export class ConditionnementRegroupementService {

  constructor(private httpSvc: HttpService) { }

  /**
   * Récupérer toutes les prestations et tous les régimes liés à un plc donné
   * @returns {Observable<ConditionnementVarianteDTO>}
   */
  getAllPrestationsRegimesByIdPlc = (idPlc: number): Observable<ResponseWrapper<ConviveDTO>> => this.httpSvc.get(`${URL_GET_ALL_PRESTA_REGIMES_BY_ID_PLC}/${idPlc}`, null);

  getAllPrestationsRegimesByIdsPlc = (idsPlc: number[]): Observable<ResponseWrapper<any>> => this.httpSvc.get(`dolrest/conditionnements/config-regroupement/list-multiple?idsPlc=${idsPlc}`, null);

  /**
   * Récupérer toutes les prestations et tous les régimes liés à un plc donné
   * @returns {Observable<ConditionnementVarianteDTO>}
   */
  getAllRulesByIdPlc = (idPlc: number): Observable<ResponseWrapper<RegleRegimeModel>> => this.httpSvc.get(`${URL_GET_ALL_RULES_BY_ID_PLC}/${idPlc}`, null);

  getAllRulesByIdsPlc = (idsPlc: number[]): Observable<ResponseWrapper<any>> => this.httpSvc.get(`dolrest/conditionnements/config-regroupement/list/rules-multiple?idsPlc=${idsPlc}`, null);

  /**
   * Supprime les règles sélectionnées
   * @param idsRules Identifiants
   */
  deleteRules = (idsRules: number[], idPlc: number): Observable<any> => {
    const params: HttpParams = new HttpParams().set("idsToDelete", idsRules.join(','))
      .set("idPlc", idPlc);
    return this.httpSvc.delete(URL_DELETE, params);
  };

  /**
   * Enregistre les règles créées
   * @param rules
   */
  saveRules = (rules: RegleRegimeModel[]): Observable<any> => this.httpSvc.post(URL_SAVE, rules);

}
