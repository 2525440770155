import {Component, OnInit} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {Subscription} from "rxjs";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormControl, FormGroup} from "@angular/forms";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {TypeProduitDTO} from "../../../core/dtos/type-produit-dto";
import {TypesProduitService} from "../../../core/services/entities/types-produit.service";

@Component({
  selector: 'yo-type-produit-edition-dialog',
  templateUrl: './type-produit-edition-dialog.component.html',
  styleUrls: ['./type-produit-edition-dialog.component.scss']
})
export class TypeProduitEditionDialogComponent implements OnInit {

  typeProduit: TypeProduitDTO;

  dialogTitle: string;

  subOpenDialog: Subscription;

  displayDialog: boolean = false;

  sitePlaceholder: string;

  idSelectedSite: number;

  form: FormGroup;

  constructor(private utilsSvc: UtilsService,
              private typeProduitSvc: TypesProduitService,
              private i8nSvc: InternationalizationService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.typeProduitSvc.openDialogEdition$
      .subscribe((typeProduit: TypeProduitDTO) => {
        this.displayDialog = true;
        if (!typeProduit) {
          this.typeProduit = new TypeProduitDTO();
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_CREATION_TYPE_PRODUCT", null);
        } else {
          this.typeProduit = typeProduit;
          this.dialogTitle = this.i8nSvc.getLabelFromCode("TITLE_MODIFICATION_TYPE_PRODUCT", null);
        }
        this.initForm();
      });
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.typeProduit?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.typeProduit?.site?.id;
    this.form = new FormGroup({
      libelle: new FormControl(this.typeProduit?.libelle),
      fab: new FormControl(this.typeProduit?.fabrique != null ? this.typeProduit?.fabrique : true),
      alim: new FormControl(this.typeProduit?.alimentaire != null ? this.typeProduit?.alimentaire : true),
      actif: new FormControl(this.typeProduit?.actif != null ? this.typeProduit?.actif : true)
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  canModify = () => {
    return this.typeProduitSvc.canModify(this.typeProduit);
  }

  save = (): void => {
    if (this.form.valid) {
      let typeProduit: TypeProduitDTO = {...this.typeProduit };
      typeProduit.site = {id: this.idSelectedSite } as SiteDTO;
      typeProduit.libelle = this.form.controls['libelle'].value;
      typeProduit.fabrique = this.form.controls['fab'].value;
      typeProduit.alimentaire = this.form.controls['alim'].value;
      typeProduit.actif = this.form.controls['actif'].value;
      this.typeProduitSvc.save(typeProduit)
        .subscribe(response => {
          this.typeProduitSvc.announceTypeProduitSaved();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du type de produit réalisé avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

}
