import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {MailHistoService} from "../../../../core/services/entities/mail-histo.service";
import {MailHistoDTO} from "../../../../core/dtos/mail-histo-dto";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";

@Component({
  selector: 'yo-email-histo-button',
  templateUrl: './email-histo-button.component.html',
  styleUrls: ['./email-histo-button.component.scss']
})
export class EmailHistoButtonComponent implements OnInit, OnDestroy {

  @Input() code: string;
  @Input() emailHisto: MailHistoDTO;
  @Output() onMailHistoRetreive: EventEmitter<MailHistoDTO> = new EventEmitter<MailHistoDTO>();

  private emailHisttoRequestSub: Subscription;

  constructor(public emailHistoSvc: MailHistoService,
              private utilsSvc: UtilsService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.utilsSvc.unsubscribe(this.emailHisttoRequestSub);
  }

  openHistoriqueMails = () => {
    this.emailHistoSvc.openEmailHistoDialog(this.code, (result: MailHistoDTO) => {
      if (result) {
        this.emailHisto = result;
        this.onMailHistoRetreive.next(result);
      }
    })
  }

}
