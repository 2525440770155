<dx-popup
  #popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="isOpened"
  [width]="700"
  height="50vh"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="onCloseDialog()">

  <div id="dialog-container">
    <div class="image-input" *ngIf="!disabled">
      <input #imageInput
             id="imageInput"
             type="file"
             accept="image/*"
             (change)="onImageSelected($event)"
             multiple
             hidden>
      <label for="imageInput">Ajouter une ou plusieurs images</label>
      <span>{{this.values ? null : 'Ajouter une ou plusieurs images'}}</span>
    </div>

    <div class="custom-list">
      <ng-container *ngFor="let image of values">
        <div class="custom-button-container-left">
          <yo-button
            *ngIf="!disabled"
            iconClass="fas fa-trash"
            buttonClass="cta-inner-delete"
            tooltip="Supprimer l'image"
            size="small"
            (onClick)="deleteImage(image)">
          </yo-button>
          <div
            class="image-container"
            (click)="openImage(image)"
            title="Afficher l'image"
          >
            <img [src]="getFileSource(image, 'thumb')" id="letter">
            <i class="fas fa-expand-alt"></i>
          </div>
          <p [textContent]="image.fileName"></p>
        </div>
      </ng-container>
    </div>

    <div class="custom-button-container-right">
      <yo-button
        label="Valider"
        iconClass="fas fa-check"
        buttonClass="cta-success"
        tooltip="Valider les changements effectués"
        (onClick)="closePopup()">
      </yo-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [showTitle]="true"
  [title]="imageSelected?.fileName"
  [(visible)]="displayImagePopup"
  [resizeEnabled]="!fullscreen"
  [showCloseButton]="true"
  (onHiding)="closeImagePopup()"
  (onResize)="updateImageSize()"
  (onContentReady)="updateImageSize()"
  [fullScreen]="fullscreen"
>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
    icon: 'fas fa-rotate-right',
            text: 'Tourner l\'image',
            onClick: rotateImage
        }">
  </dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>

  <div class="popup-preview-image">
    <img #image class="image-keep-ratio"
         [src]="getFileSource(imageSelected, 'original')">
  </div>
</dx-popup>
