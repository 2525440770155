<div class="custom-toolbar">
  <div class="custom-button-container-left custom-toolbar-element">
    <yo-help lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <yo-button
      iconClass="fa fa-plus"
      label="CRÉER"
      (onClick)="openConditionnement(undefined)"
      [disabled]="!canEdit()"
      tooltip="Créer un conditionnement">
    </yo-button>
  </div>
  <div class="custom-button-container-right">
    <yo-button [disabled]="!canEdit() || !selectedRows.length" buttonClass="cta-delete" (onClick)="deleteValues()"
               tooltip="Supprimer les éléments sélectionnés" iconClass="fas fa-trash"></yo-button>
  </div>
</div>

<dx-data-grid
  [dataSource]="conditionnementsList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(115)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [allowColumnResizing]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate"
              alignment="left">
  </dxi-column>
  <dxi-column dataField="site" caption="Site"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              cellTemplate="actionsSiteTemplate"
              [calculateCellValue]="calculateSiteValue"
              alignment="left">
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              alignment="left">
  </dxi-column>
  <dxi-column dataField="abreviation" caption="Abréviation"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              alignment="left">
  </dxi-column>
  <dxi-column dataField="code" caption="Code"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              alignment="left">
  </dxi-column>
  <dxi-column dataField="poidsEnKg" caption="Poids en Kg"
              [filterOperations]="['contains']"
              [allowFiltering]="true"
              alignment="left">
  </dxi-column>

  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">

    <yo-cell-button (yoNavigation)="openConditionnement(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="gds.canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="gds.canModify(cell.row.data)?'Modifier le conditionnement':'Voir le conditionnement'">
    </yo-cell-button>

  </div>

  <div *dxTemplate="let cell of 'actionsSiteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} conditionnements">
  </dxo-pager>

</dx-data-grid>

<yo-conditionnement></yo-conditionnement>

<dx-popup
  [showTitle]="true"
  title="Conditionnements n'ayant pas été supprimés"
  [(visible)]="displayDeletePopupErrors"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (close)="closeErrors()"
>
  <label>Les conditionnements non présents dans cette liste ont bien été supprimés.</label>
  <dx-data-grid
    [dataSource]="errors"
    keyExpr="infosLine"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    columnResizingMode="widget"
  >
    <dxi-column dataField="infosLine" caption="Conditionnement non supprimé" [filterOperations]="['contains']"
                [allowFiltering]="true"></dxi-column>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager
      [showPageSizeSelector]="true"
      [showNavigationButtons]="false"
      [allowedPageSizes]="[20, 50, 100, 200]"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} erreur{{errors && errors.length > 0 ? 's' : ''}}">
    </dxo-pager>
  </dx-data-grid>
</dx-popup>
