import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CelluleTableauDTO} from '../../core/dtos/gestionmenus/planning/cellule-tableau-dto';
import {MenusToolbarService} from '../../core/services/gestionmenus/menus-toolbar.service';
import {UtilsService} from '../../core/utils/utils.service';
import {MENU_PROFIL} from '../../core/constants';

@Component({
  selector: 'yo-famnuts-decoupage',
  templateUrl: './famnuts-decoupage.component.html',
  styleUrls: ['./famnuts-decoupage.component.scss']
})
export class FamnutsDecoupageComponent implements OnInit {

  subDisplayProfil: Subscription;

  displayProfil: boolean = false;

  @Input() cellule: CelluleTableauDTO;

  constructor(public menuToolbarSvc: MenusToolbarService,
              private cd: ChangeDetectorRef,
              public utils: UtilsService) {
  }

  ngOnInit() {

    this.subDisplayProfil = this.menuToolbarSvc.displayProfil$.subscribe(item => {
      this.displayProfil = this.menuToolbarSvc.isProfilToDisplay(item, MENU_PROFIL.DIET);
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDisplayProfil);
  }

  display = () => this.displayProfil;

  getColor = (famille: any) => '#' + famille[0]?.couleur?.hexa ?? '';

  tooltip = () => this.cellule.famillesGemrcn.map(item => item.libelle.toUpperCase()).join('<br><br>');

  label = () => this.cellule.famillesGemrcn.map(item => item.abreviation ? item.abreviation.toUpperCase() : item.code.substr(0, 4)).join(' | ');
}
