<ng-container *ngIf="detailsByPoidsConditionnement?.length && flatResults?.length">
<dx-tab-panel>
  <dxi-item title="Détails complets" icon="fa-solid fa-layer-group">
    <ng-container *ngIf="flatResults?.length">
      <dx-data-grid
        id="tab-calcul-conditionnement"
        [dataSource]="flatResults"
        keyExpr="id"
        width="100%"
        height="350"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [allowColumnReordering]="true"
        columnResizingMode="widget"
        #grid>

        <dxi-column dataField="atelierLabel" caption="Atelier" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="equipeLabel" caption="Equipe" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="pointDeLivraison.libelle" caption="Point livraison client" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true" cellTemplate="plcTemplate">
        </dxi-column>
        <dxi-column dataField="prestationLabel" caption="Prestation" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="prestaTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="decliTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="regimeLabel" caption="Régime" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="regimeTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="poidsNetUc" caption="Poids net UC" [width]="100"
                    [filterOperations]="['contains']" cellTemplate="poidsNetUcTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="qteConditionnee" caption="Quantité conditionnée" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="qteConditionneeTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="nbPiecesCalculated" caption="Nombre pièce(s)" cellTemplate="nbPiecesTemplate" [width]="'auto'" [filterOperations]="['contains']" [allowGrouping]="true"></dxi-column>
        <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="varianteLabel" caption="Variante" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="queueUc" caption="Reste" [width]="100"
                    [filterOperations]="['contains']"
                    cellTemplate="resteTemplate"
                    [allowGrouping]="true">
        </dxi-column>

        <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [width]="100"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="100"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>

        <div *dxTemplate="let cell of 'plcTemplate'" class="text-center">
          {{cell.value}}
        </div>

        <div *dxTemplate="let cell of 'decliTemplate'">
          {{cell.value}}
        </div>

        <div *dxTemplate="let cell of 'prestaTemplate'">
          {{cell.value}}
        </div>

        <div *dxTemplate="let cell of 'regimeTemplate'">
          {{cell.value}}
        </div>

        <div *dxTemplate="let cell of 'poidsNetUcTemplate'">
          {{cell.value}} Kg
        </div>

        <div *dxTemplate="let cell of 'qteConditionneeTemplate'">
          {{cell.value}}
        </div>

        <div *dxTemplate="let cell of 'resteTemplate'">
          <span [className]="cell.value ? 'reste' : ''">{{cell.value || ''}} {{cell.value ? 'Kg' : ''}}</span>
        </div>

        <div *dxTemplate="let cell of 'nbPiecesTemplate'">
          {{cell.value}} {{ cell.value ? cell.row.data.uniteAConditionner : '' }}
        </div>

        <dxo-summary>
          <dxi-group-item
            column="qteConditionnee"
            summaryType="sum"
            displayFormat="Quantité conditionnée : {0}">
          </dxi-group-item>
          <dxi-group-item
            column="queueUc"
            summaryType="sum"
            displayFormat="Reste : {0}">
            <dxo-value-format type="fixedPoint" precision="3"></dxo-value-format>
          </dxi-group-item>
          <dxi-group-item
            column="nbCommandes"
            summaryType="sum"
            displayFormat="Nb.commandes : {0}">
          </dxi-group-item>
          <dxi-group-item
            column="poidsNetUc"
            summaryType="sum"
            valueFormat="##0.0000"
            displayFormat="Poids net UC : {0}">
          </dxi-group-item>
        </dxo-summary>


        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} conditionnement(s)">
        </dxo-pager>

      </dx-data-grid>
    </ng-container>
  </dxi-item>
  <dxi-item title="Détails sommaires" icon="fa-solid fa-layer-group">
    <ng-container *ngIf="detailsByPoidsConditionnement?.length">
      <dx-data-grid
        id="tab-calcul-conditionnement-sommaires"
        [dataSource]="detailsByPoidsConditionnement"
        keyExpr="id"
        width="100%"
        height="350"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true"
        [allowColumnReordering]="true"
        columnResizingMode="widget"
        (onRowPrepared)="onRowPreparedDetailsSommaires($event)"
        #grid>

        <dxi-column dataField="atelierLabel" caption="Atelier" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="equipeLabel" caption="Equipe" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="pointLivraisonLabel" caption="Point livraison client" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true" cellTemplate="plcTemplate">
        </dxi-column>
        <dxi-column dataField="prestationLabel" caption="Prestation" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="prestaTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="declinaisonLabel" caption="Déclinaison" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="decliTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="regimeLabel" caption="Régime" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="regimeTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="poidsNetUc" caption="Poids net UC" [width]="100"
                    [filterOperations]="['contains']" cellTemplate="poidsNetUcTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="qteConditionnee" caption="Quantité conditionnée" [width]="'auto'"
                    [filterOperations]="['contains']" cellTemplate="qteConditionneeTemplate"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="nbPiecesCalculated" caption="Nombre pièce(s)" cellTemplate="nbPiecesTemplate" [width]="'auto'" [filterOperations]="['contains']" [allowGrouping]="true"></dxi-column>
        <dxi-column dataField="conditionnementLabel" caption="Conditionnement" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="varianteLabel" caption="Variante" [width]="'auto'"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="queueUc" caption="Reste" [width]="100"
                    [filterOperations]="['contains']"
                    cellTemplate="resteTemplate"
                    [allowGrouping]="true">
        </dxi-column>

        <dxi-column dataField="dateConditionnement" caption="Date conditionnement" [width]="100"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>
        <dxi-column dataField="dateFabrication" caption="Date fabrication" [width]="100"
                    [filterOperations]="['contains']"
                    [allowGrouping]="true">
        </dxi-column>

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

        <dxo-pager
          [showPageSizeSelector]="true"
          [showNavigationButtons]="false"
          [allowedPageSizes]="[20, 50, 100, 200]"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} conditionnement(s)">
        </dxo-pager>

        <div *dxTemplate="let cell of 'plcTemplate'" class="text-center">
          <ng-container *ngIf="!cell.value && cell.row.data.pointLivraisonRegroupe && !cell.row.data.reste">
            <i class="fa-solid fa-layer-group reste"></i>
          </ng-container>
          <ng-container *ngIf="cell.value">
            {{cell.value}}
          </ng-container>
        </div>

        <div *dxTemplate="let cell of 'decliTemplate'">
          <ng-container *ngIf="!cell.value && cell.row.data.declinaisonRegroupee && !cell.row.data.reste">
            <i class="fa-solid fa-layer-group reste"></i>
          </ng-container>
          <ng-container *ngIf="cell.value">
            {{cell.value}}
          </ng-container>
        </div>

        <div *dxTemplate="let cell of 'prestaTemplate'">
          <ng-container *ngIf="!cell.value && cell.row.data.prestationRegroupee && !cell.row.data.reste">
            <i class="fa-solid fa-layer-group reste"></i>
          </ng-container>
          <ng-container *ngIf="cell.value">
            {{cell.value}}
          </ng-container>
        </div>

        <div *dxTemplate="let cell of 'regimeTemplate'">
          <ng-container *ngIf="!cell.value && cell.row.data.regimeRegroupe && !cell.row.data.reste">
            <i class="fa-solid fa-layer-group reste"></i>
          </ng-container>
          <ng-container *ngIf="cell.value">
            {{cell.value}}
          </ng-container>
        </div>

        <div *dxTemplate="let cell of 'poidsNetUcTemplate'">
          {{cell.value}} Kg
        </div>

        <div *dxTemplate="let cell of 'qteConditionneeTemplate'">
          {{cell.value}}
        </div>

        <div *dxTemplate="let cell of 'resteTemplate'">
          <span [className]="cell.value ? 'reste' : ''">{{cell.value || ''}} {{cell.value ? 'Kg' : ''}}</span>
        </div>

        <div *dxTemplate="let cell of 'nbPiecesTemplate'">
          {{cell.value}} {{ cell.value ? cell.row.data.uniteAConditionner : '' }}
        </div>

      </dx-data-grid>
    </ng-container>
  </dxi-item>
</dx-tab-panel>
</ng-container>
