<div class="card mg-b-10">
  <div class="card-header">

    <div class="row">
      <div class="col-md-auto">
        Analyse diététique pour la prestation <strong> {{ghs?.prestation?.contratMenuLibelle}}
        - {{ghs?.prestation?.libelle}}
        - <i class="fab fa-first-order fa-xs  prefere-color" *ngIf="ghs?.contrainteAlimentaire?.referent"
             title="Régime référent"
             ></i> {{ghs?.contrainteAlimentaire?.libelle}}
      </strong>
      </div>
      <div class="col text-right" *ngIf="isGridDisplayable()">
        <!--      CALENDRIER-->

        <label class="genlabel mg-r-5">Sélection de la période</label>

        <p-calendar
          firstDayOfWeek="1"
          class="mg-r-40"
          dateFormat="dd/mm/yy"


          selectionMode="range"
          [numberOfMonths]="2"
          [readonlyInput]="true"
          [(ngModel)]="ghs.dateDebEtFin"
          (onSelect)="changeDates($event)"
          [locale]="localeFr"
          [showIcon]="true"
          [showWeek]="true"
        >
        </p-calendar>

      </div>
    </div>


  </div>
  <div class="card-body" style="height:calc(100vh - 80px); overflow: auto">

    <ng-container *ngIf="isGridDisplayable()">

      <!--      ANALYSE  PAR PLAN ALIMENTAIRE-->
      <ng-container *ngFor="let pa of ghs.plansAlimentaires">

        <p-accordion [multiple]="false">
          <p-accordionTab>
            <ng-template pTemplate="header">

                <span class="badge badge-light mg-r-10 font-14">
            <label class="genlabel mg-r-5">Période</label>
            <strong class="mg-r-5">{{getPeriodeLabel(pa)}}</strong>
            </span>

              <span class="badge badge-light mg-r-10 font-14">
            <label class="genlabel mg-r-5">Modèle de menu</label>
            <strong class="mg-r-5">{{pa.cmcMn.modeleNutritionnelLibelle}}</strong>
            </span>

              <span class="badge badge-light mg-r-10 font-14">
            <label class="genlabel mg-r-5">Cycle en semaine </label>
            <strong class="mg-r-5">{{pa.cmcMn?.modeleNutritionnelNbItemsParCycle}}</strong>
            </span>

              <span class="badge badge-light mg-r-10 font-14">
            <label class="genlabel mg-r-5">Nombre de repas comptabilisés sur la période </label>
            <strong class="mg-r-5">{{pa?.nbRepasComptabilises}}</strong>
            </span>
            </ng-template>

            <div *ngIf="!isPeriodeGemrcnExists(pa)">
              <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>Aucune grille GEMRCN
              paramétrée {{pa.cmcMn.modeleNutritionnelLibelle}}.
            </div>

            <div *ngIf="isPeriodeGemrcnExists(pa)">
              <p-table
                [value]="pa.lignePlanAlimGemrcnList"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th [style.width.px]="colFamilleGemrcnWidth" rowspan="2" [style.width.px]="colFamilleGemrcnWidth">
                      Famille GEMRCN
                    </th>
                    <ng-container *ngFor="let col of pa.colonnePlanAlimGemrcnList">
                      <th colspan="3">
                    <span>{{mnGroupeGemrcnSvc.getCmcMnGroupeLibelle(col.cmcMnGroupeGemrcn)}} ({{col.nbRepas}}
                      repas)
                      </span>
                      </th>
                    </ng-container>
                  </tr>
                  <tr>
                    <ng-container *ngFor="let col of pa.colonnePlanAlimGemrcnList">
                      <th>Règle recommandée</th>
                      <th>Règle appliquée à la période</th>
                      <th>{{mnGroupeGemrcnSvc.getTooltipChoixGermcn(col.cmcMnGroupeGemrcn.choix)}}</th>
                    </ng-container>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td [style.width.px]="colFamilleGemrcnWidth">
                      <yo-famille [ligne]="rowData"></yo-famille>
                    </td>

                    <ng-container *ngFor="let col of pa.colonnePlanAlimGemrcnList">
                      <td class="text-right">
                        <ng-container
                          *ngTemplateOutlet="tplRegleOriginale;context:{regle:getRegleGemrcn(pa,rowData.familleGemrcn.id,col.cmcMnGroupeGemrcn.id)}"></ng-container>
                      </td>
                      <td class="text-right">
                        <ng-container
                          *ngTemplateOutlet="tplReglePeriode;context:{regle:getRegleGemrcn(pa,rowData.familleGemrcn.id,col.cmcMnGroupeGemrcn.id)}"></ng-container>
                      </td>

                      <ng-container *ngIf="col.cmcMnGroupeGemrcn.choix===CHOIX_DIRIGE">
                        <ng-container
                          *ngTemplateOutlet="tplChoixDirige;context:{regle:getRegleGemrcn(pa,rowData.familleGemrcn.id,col.cmcMnGroupeGemrcn.id)}"></ng-container>
                      </ng-container>
                      <ng-container *ngIf="col.cmcMnGroupeGemrcn.choix===CHOIX_MULTIPLE">
                        <ng-container
                          *ngTemplateOutlet="tplChoixMultiple;context:{regle:getRegleGemrcn(pa,rowData.familleGemrcn.id,col.cmcMnGroupeGemrcn.id)}"></ng-container>
                      </ng-container>

                    </ng-container>

                  </tr>
                </ng-template>

              </p-table>
            </div>


          </p-accordionTab>
        </p-accordion>


      </ng-container>


    </ng-container>

  </div>

</div>


<!--TEMPLATE FAMILLE-->
<ng-template #tplFamille let-ligne="ligne">

  <yo-couleur [hexColor]="ligne.familleGemrcn.couleur.hexa"></yo-couleur>
  {{ligne.familleGemrcn.libelle}}

</ng-template>

<!--TEMPLATE REGLE ORIGINALE-->
<ng-template #tplRegleOriginale let-regle="regle">
  <ng-container *ngIf="regle">
    {{regle.baseNumerator}} / {{regle.baseDenominator}} {{ggSvc.getOperatorViewValue(regle.operator) }}
  </ng-container>

</ng-template>

<!--TEMPLATE REGLE PERIODE-->
<ng-template #tplReglePeriode let-regle="regle">

  <ng-container *ngIf="regle">
    {{regle.numerator | number:'1.0-4'}} / {{regle.denominator}} {{ggSvc.getOperatorViewValue(regle.operator) }}
  </ng-container>


</ng-template>


<!--TEMPLATE CHOIX DIRIGÉ-->
<ng-template #tplChoixDirige let-regle="regle">

  <td [class.bg-checked]="regle.choixDirigeOk" [class.bg-edit-color]="!regle.choixDirigeOk" class="text-right">
    <span class="mg-r-5">{{regle.countChoixDirige}} / {{regle.denominator}}</span>
    <yo-check [checked]="regle.choixDirigeOk"></yo-check>
  </td>


</ng-template>

<!--TEMPLATE CHOIX MULTIPLE-->
<ng-template #tplChoixMultiple let-regle="regle">

  <td [class.bg-checked]="regle.choixMultipleOk" [class.bg-edit-color]="!regle.choixMultipleOk" class="text-right">
    <span class="mg-r-5">{{regle.countChoixMultiple}} / {{regle.denominator}}</span>
    <yo-check [checked]="regle.choixMultipleOk"></yo-check>
  </td>

</ng-template>

