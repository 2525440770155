import {ObjectDTO} from './object-dto';
import {BonReceptionLigneLitigeDTO} from './bon-reception-ligne__litige-dto';
import {BonReceptionLigneMotifNonConfoDTO} from './bon-reception-ligne__motif-non-confo-dto';
import {LitigeDTO} from './litige-dto';
import {MotifNonConformiteDTO} from './motif-non-conformite-dto';
import {AppellationDTO} from './appellations-dto';
import {UniteDeProduction__ZoneDeStockageDTO} from './unite-de-production__zone-stockage-dto';
import {BonReceptionLigneAppellationDTO} from './bon-reception-ligne__appellation-dto';
import {LotMarchePdLumpCaDto} from './gerstion-marche/lot-marche-pd-lump-ca-dto';
import {OrigineDTO} from "./origine-dto";
import {ZoneDeStockageDTO} from "./zone-de-stockage-dto";
import {CatalogueAchatDTO} from "./catalogue-achat-dto";
import {ReceptionStatutDTO} from "./reception-statut-dto";
import {ImageBase64DTO} from "./images/ImageBase64DTO";
import {LotArticleDTO} from "./lot-article-dto";

export class BonReceptionLigneDTO extends ObjectDTO {

  quantiteCommandee: number;
  quantiteDejaReceptionnee: number;
  quantiteRecue: number;

  prixUnitaireFacture: number;
  prixUnitairePrev: number;
  totalFacture: number;
  totalFactureTTC: number;

  dlc: Date;
  temperatureCelsius: number;

  naissance: OrigineDTO;
  elevage: OrigineDTO;
  abattage: OrigineDTO;
  origine: OrigineDTO;
  idOrigine : number;

  remarque: string;

  tauxDeRemise: number;
  montantAvoir: number;

  modificationColisage: string;

  lotManuel:boolean;

  // reception statut
  receptionStatutCode: string;
  idReceptionStatut: number;
  receptionStatut: ReceptionStatutDTO;

  // bon cf detail
  idBonCfDetail: number;

  // stock mouvement
  idStockMouvement: number;

  // lot article
  numeroLotInterne: string;
  numeroLotFabriquant: string;
  idLotArticle: number;

  lotArticle: LotArticleDTO;

  // produit article
  denominationArticle: string;
  referenceArticle: string;

  // catalogue achat
  catalogueAchat: CatalogueAchatDTO;
  uniteDeCommande: string;
  uniteDeCommandeAbreviation: string;
  uniteDeFacturation: string;
  uniteDeFacturationAbreviation: string;
  uniteDeStockage: string;
  uniteDeStockageAbreviation: string;
  produitCarne : boolean;

  ratioUniteDeStockageUniteBase: number;
  ratioUniteDeFacturationUniteBase: number;
  ratioUniteDeCommandeUniteBase: number;
  idCatalogueAchat: number;


  // bon reception
  idBonReception: number;

  // unite de production bon cf
  idUniteDeProduction: number;
  libelleUniteDeProduction: string;

  // unite de production du bon reception ligne
  udpZdsIdUdp: number;

  // udp zds (unite de prod zone de stockage)
  idUdpZds: number;

  // zone de stockage
  idZds: number;
  libelleZds: string;
  zoneDeStockage: ZoneDeStockageDTO;

  // bon reception ligne litige
  bonReceptionLigneLitigeList: BonReceptionLigneLitigeDTO[] = [];
  // permet l'affichage dans dialog-article-reception-grid.component
  dxLitigeList: LitigeDTO[] = [];

  // bon reception ligne motif non confo
  bonReceptionLigneMotifNonConfoList: BonReceptionLigneMotifNonConfoDTO[] = [];
  // permet l'affichage dans dialog-article-reception-grid.component
  dxMotifNonConfoList: MotifNonConformiteDTO[] = [];

  bonReceptionLigneAppellationList:BonReceptionLigneAppellationDTO[] = [];
  // permet l'affichage dans dialog-article-reception-grid.component
  dxAppellationList: AppellationDTO[] = [];


  // transient
  selectedUdpZds:UniteDeProduction__ZoneDeStockageDTO;

  // site
  siteId: number;

  // produit decli
  tauxTva: number;

  // infos du marche
  lmPdUpCa:LotMarchePdLumpCaDto;
}
