import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {CommunicationProduitDTO} from "../../../../core/dtos/referentiel/base.communication.produit/communication-produit-dto";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {ToastService} from "../../../../core/services/technique/toast.service";
import {CommunicationProduitService} from "../../../../core/services/entities/referentiel.base/communication-produit-service";
import {UtilsService} from "../../../../core/utils/utils.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {TypeProduitDTO} from "../../../../core/dtos/type-produit-dto";
import {TypesProduitService} from "../../../../core/services/entities/types-produit.service";

@Component({
  selector: 'yo-communication-produit-dialog',
  templateUrl: './communication-produit-dialog.component.html',
  styleUrls: ['./communication-produit-dialog.component.scss']
})
export class CommunicationProduitDialogComponent implements OnInit {

  subOpenDialog: Subscription
  subTypesProduit: Subscription

  displayDialog = false;

  typeProduitList: TypeProduitDTO[] = [];

  communicationProduit: CommunicationProduitDTO;
  idSelectedSite: number;
  idSelectedTypeProduit: number;

  form: FormGroup = new FormGroup({});

  dialogTitle: string = '';
  sitePlaceholder: string = 'Selectionner le site...';

  constructor(private readonly auth2Svc: Auth2Service,
              private readonly toastSvc: ToastService,
              public readonly communicationProduitsSvc: CommunicationProduitService,
              private readonly typeProduitSvc: TypesProduitService,
              public readonly utilsSvc: UtilsService) {
  }

  ngOnInit() {
    this.initTypeProduitList();
    this.initForm();
    this.openDialogEditionSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog);
    this.utilsSvc.unsubscribe(this.subTypesProduit);
  }

  initTypeProduitList = () => {
    this.subTypesProduit = this.typeProduitSvc.getAll().subscribe(result => {
        this.typeProduitList = result;
      }
    );
  }

  openDialogEditionSubscription = (): void => {
    this.subOpenDialog = this.communicationProduitsSvc.openDialog$
      .subscribe((fe: CommunicationProduitDTO) => {
        this.displayDialog = true;
        if (!fe) {
          this.communicationProduit = new CommunicationProduitDTO();
          this.communicationProduit.id = 0;
          this.dialogTitle = 'Création d\'une communication de produit';
        } else {
          this.communicationProduit = fe;
          this.dialogTitle = 'Modification d\'une communication de produit';
        }
        this.initForm();
      });
  };

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  save = (): void => {
    if (this.form.valid) {
      let newCommunicationProduit: CommunicationProduitDTO = {...this.communicationProduit};
      newCommunicationProduit.site = {id: this.idSelectedSite} as SiteDTO;
      newCommunicationProduit.typeProduit = {id: this.idSelectedTypeProduit} as TypeProduitDTO;
      newCommunicationProduit.code = this.form.controls['code'].value;
      newCommunicationProduit.libelle = this.form.controls['libelle'].value;
      newCommunicationProduit.actif = this.form.controls['actif'].value;
      this.communicationProduitsSvc.save(newCommunicationProduit)
        .subscribe((result) => {
          if (result && result.one) {
            this.communicationProduitsSvc.announceCommunicationProduitSaved(result.one);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde de la communication de produit réalisée avec succès`);
            this.closeDialog();
          }
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.sitePlaceholder = !this.canModify() ? this.communicationProduit?.site?.libelle : 'Selectionner le site...';
    this.idSelectedSite = this.communicationProduit?.site?.id;
    this.idSelectedTypeProduit = this.communicationProduit?.typeProduit?.id;
    this.form = new FormGroup({
      code: new FormControl(this.communicationProduit?.code),
      libelle: new FormControl(this.communicationProduit?.libelle),
      actif: new FormControl(this.communicationProduit?.actif != null ? this.communicationProduit.actif : true),
    });
  };

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  onChangeTypeProduit = (id: number): void => {
    this.idSelectedTypeProduit = id;
  }

  canModify = () => {
    return this.communicationProduitsSvc.canModify(this.communicationProduit);
  }

}
