<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="700"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <form (ngSubmit)="save()" [formGroup]="form">

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Site <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <yo-lookup
          #site
          [data]="findAllLocalSites()"
          [selectedItem]="selectedSite"
        >
        </yo-lookup>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-4 genlabel required" title="Libellé">Libellé <span class="danger-color">*</span></div>
      <div class="col-md-8">
        <dx-text-box class='inputfield' placeholder="Libellé" formControlName="libelle"
                     [disabled]="!canModify()"></dx-text-box>
      </div>
    </div>
    <div class="dialog-line">
      <div class="custom-button-container-right">
        <yo-button
          type="submit"
          iconClass="fas fa-save"
          tooltip="Enregistrer"
          buttonClass="cta-success"
          [disabled]="!canModify()">
        </yo-button>
        <yo-button
          buttonClass="cta-delete"
          (onClick)="closeDialog()"
          iconClass="fas fa-times"
        >
        </yo-button>
      </div>
    </div>
  </form>
</dx-popup>

<ng-template #tplError let-form="form" let-property="property" let-label="label">
  <div class="error"
       *ngIf="form.controls[property].invalid && (form.controls[property].dirty || form.controls[property].touched )">

    <pre>{{form.controls[property].validators | json}}</pre>

    <div class="pg-2" *ngIf="form.controls[property].hasError('required')">
      Le champ {{label}} est obligatoire.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('minlength')">
      Le champ {{label}} ne peut avoir moins de {{form.controls[property].errors.minlength.requiredLength}} caractères.
    </div>
    <div class="pg-2" *ngIf="form.controls[property].hasError('maxlength')">
      Le champ {{label}} ne doit pas dépasser {{form.controls[property].errors.maxlength.requiredLength}} caractères.
    </div>

  </div>

</ng-template>
