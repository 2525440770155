import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionUnitesproductionRoutingModule} from './gestion-unitesproduction-routing.module';
import {GestionUproRootComponent} from './gestion-upro-root/gestion-upro-root.component';
import {GrilleUnitesProductionComponent} from './grille-unites-production/grille-unites-production.component';
import {SharedModule} from '../shared/shared.module';
import {UniteProductionComponent} from './unite-production/unite-production.component';
import {UniteProductionResolverService} from './unite-production/unite-production-resolver.service';
import {UpFicheidentiteComponent} from './unite-production/up-ficheidentite/up-ficheidentite.component';
import {UpLivraisonsComponent} from './unite-production/up-livraisons/up-livraisons.component';
import {UpDenreesInterditesComponent} from './unite-production/up-denrees-interdites/up-denrees-interdites.component';
import {DialogAjoutDenreeInterditeComponent} from './unite-production/up-denrees-interdites/dialog-ajout-denree-interdite/dialog-ajout-denree-interdite.component';
import {DialogModifierDenreeInterditeComponent} from './unite-production/up-denrees-interdites/dialog-modifier-denree-interdite/dialog-modifier-denree-interdite.component';
import {GrilleDenreesInterditesComponent} from './unite-production/up-denrees-interdites/grille-denrees-interdites/grille-denrees-interdites.component';
import {UpZonesDeStockageComponent} from './unite-production/up-zones-de-stockage/up-zones-de-stockage.component';
import {UpLivraisonsAffectationComponent} from './unite-production/up-livraisons/up-livraisons-affectation/up-livraisons-affectation.component';
import {UpLivraisonResolverService} from './unite-production/up-livraisons/up-livraison-resolver.service';
import {UpParametrageResolverService} from './unite-production/up-equipes/parametrage/up-parametrage-resolver.service';
import {UpArticlesPreferesComponent} from './unite-production/up-articles-preferes/up-articles-preferes.component';
import {DialogFilialesPrefereesComponent} from './unite-production/up-articles-preferes/dialog-filiales-preferees/dialog-filiales-preferees.component';
import {GrilleArticlesPreferesComponent} from './unite-production/up-articles-preferes/grille-articles-preferes/grille-articles-preferes.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DialogRemarqueCommandeComponent} from './unite-production/up-livraisons/up-livraisons-affectation/dialog-remarque-commande/dialog-remarque-commande.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {ListboxModule} from 'primeng/listbox';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {CheckboxModule} from 'primeng/checkbox';
import {MenuModule} from "primeng/menu";
import {DialogModule} from 'primeng/dialog';
import {SliderModule} from 'primeng/slider';
import {CalendarModule} from 'primeng/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import {UpEquipesComponent} from './unite-production/up-equipes/up-equipes.component';
import {ParametrageComponent} from './unite-production/up-equipes/parametrage/parametrage.component';
import {PlanningFabricationsComponent} from './unite-production/up-equipes/planning-fabrications/planning-fabrications.component';
import {PlanningApprosComponent} from './unite-production/up-equipes/planning-appros/planning-appros.component';
import {PlanningConditionnementsComponent} from './unite-production/up-equipes/planning-conditionnements/planning-conditionnements.component';
import {PlanningSortiesComponent} from './unite-production/up-equipes/planning-sorties/planning-sorties.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ParametragePrevisualisationComponent} from './unite-production/up-equipes/parametrage/previsualisation/parametrage-previsualisation.component';
import {ParametrageAssociationComponent} from './unite-production/up-equipes/parametrage/associations/parametrage-association.component';
import {
    DxDataGridModule,
    DxListModule,
    DxProgressBarModule, DxSelectBoxModule, DxTagBoxModule,
    DxTemplateModule, DxTextBoxModule,
    DxToolbarModule
} from 'devextreme-angular';
import {AccordionModule} from 'primeng/accordion';
import {UpIncoherenceComponent} from "./unite-production/up-incoherence/up-incoherence.component";
import {InputTextModule} from "primeng/inputtext";

import {UpConditionnementVarianteComponent} from "./unite-production/up-conditionnement/up-conditionnement-variante/up-conditionnement-variante.component";
import {UpConditionnnementResolverService} from "./unite-production/up-conditionnement/up-conditionnement-variante/up-conditionnnement-resolver.service";
import {DialogAjoutConditionnementComponent} from "./unite-production/up-conditionnement/up-conditionnement-variante/dialog-ajout-conditionnement/dialog-ajout-conditionnement.component";
import {UpProduitsDeclinaisonsNonApprovisionnesComponent} from "./unite-production/up-produits-declinaisons-non-approvisionnes/up-produits-declinaisons-non-approvisionnes";
import {DialogAjoutUpProduitsDeclinaisonsNonApprovisionnes} from "./unite-production/up-produits-declinaisons-non-approvisionnes/dialog-ajout/dialog-ajout-up-produits-declinaisons-non-approvisionnes";
import {UpConditionnementComponent} from "./unite-production/up-conditionnement/up-conditionnement.component";
import {UpModeleConditionnementPlatComponent} from "./unite-production/up-conditionnement/up-modele-conditionnement-plat/up-modele-conditionnement-plat.component";
import {UpModeleConditionnnementPlatResolverService} from "./unite-production/up-conditionnement/up-modele-conditionnement-plat/up-modele-conditionnement-plat-resolver.service";
import {DialogAjoutUdpMcpComponent} from "./unite-production/up-conditionnement/up-modele-conditionnement-plat/dialog-ajout-udp-mcp/dialog-ajout-udp-mcp.component";


@NgModule({
    imports: [
        CommonModule,
        GestionUnitesproductionRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        MenuModule,
        TabMenuModule,

        ListboxModule,
        DropdownModule,
        TableModule,
        PanelModule,
        CheckboxModule,
        DialogModule,
        SliderModule,
        CalendarModule,
        MultiSelectModule,
        InputTextareaModule,
        SelectButtonModule,
        DxTemplateModule,
        DxDataGridModule,
        AccordionModule,
        InputTextModule,
        DxProgressBarModule,
        DxToolbarModule,
        DxListModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxTextBoxModule,
    ],
  entryComponents: [

  ],
  declarations: [
    GestionUproRootComponent,
    GrilleUnitesProductionComponent,
    UniteProductionComponent,
    UpFicheidentiteComponent,
    UpLivraisonsComponent,
    UpDenreesInterditesComponent,
    UpIncoherenceComponent,
    DialogAjoutDenreeInterditeComponent,
    DialogModifierDenreeInterditeComponent,
    UpZonesDeStockageComponent,
    UpLivraisonsAffectationComponent,
    UpConditionnementVarianteComponent,
    UpProduitsDeclinaisonsNonApprovisionnesComponent,
    UpConditionnementComponent,
    UpModeleConditionnementPlatComponent,
    DialogAjoutUdpMcpComponent,

    UpArticlesPreferesComponent,
    DialogFilialesPrefereesComponent,
    DialogAjoutConditionnementComponent,
    GrilleArticlesPreferesComponent,
    GrilleDenreesInterditesComponent,
    DialogRemarqueCommandeComponent,
    UpEquipesComponent,
    ParametrageComponent,
    ParametragePrevisualisationComponent,
    ParametrageAssociationComponent,
    PlanningFabricationsComponent,
    PlanningApprosComponent,
    PlanningConditionnementsComponent,
    PlanningSortiesComponent,
    DialogAjoutUpProduitsDeclinaisonsNonApprovisionnes
  ],
  providers: [
    UniteProductionResolverService,
    UpLivraisonResolverService,
    UpParametrageResolverService,
    UpConditionnnementResolverService,
    UpModeleConditionnnementPlatResolverService,
  ]

})
export class GestionUnitesproductionModule {
}
