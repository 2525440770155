<div class="d-flex">
  <div class="mg-r-30" style="width: 60%; overflow-y: auto;">
    <dx-toolbar class="yo-toolbar">
      <dxi-item location="before">
        <div class="d-flex flex-row align-items-center">
          <yo-help class="mg-r-15" lang="fr" [file]="pathFileProfils" [dialogMsgSupplier]="help()"
                   [width]="800"></yo-help>
          <yo-button
            iconClass="fas fa-plus"
            label="Créer"
            tooltip="Créer un profil"
            (onClick)="addProfilDialog()">
          </yo-button>
        </div>
      </dxi-item>
    </dx-toolbar>

    <dx-data-grid
      [dataSource]="profilList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(182)"
      width="100%"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>

      <dxi-column dataField="id" caption="Actions" cellTemplate="templateActions" [width]="100"
                  [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="libelle" caption="Libellé" cellTemplate="templateLabel" [width]="150"></dxi-column>
      <dxi-column dataField="code" caption="Code" [width]="100"></dxi-column>
      <dxi-column dataField="description" caption="Description" cellTemplate="templateDesc" [width]="200"></dxi-column>
      <dxi-column dataField="actif" caption="Actif" [width]="100"></dxi-column>
      <dxi-column dataField="roles" caption="Rôles associés" cellTemplate="templateRoles"
                  [allowFiltering]="false" width="300"></dxi-column>

      <div *dxTemplate="let cell of 'templateActions'" class="custom-button-container">
        <yo-button *ngIf="canDoActions(cell.row.data)" size="small" buttonClass="cta-inner-edit-or-watch"
                   iconClass="fas fa-edit"
                   (onClick)="onChangeProfil(cell.row.data)"
                   tooltip="Modifier le profil"></yo-button>
        <yo-button *ngIf="canDoActions(cell.row.data)" size="small" buttonClass="cta-delete" iconClass="fas fa-trash"
                   (onClick)="deleteProfil(cell.row.data)"
                   tooltip="Supprimer le profil"></yo-button>
      </div>
      <div *dxTemplate="let cell of 'templateLabel'">
        <strong>{{ cell.row.data.libelle }}</strong>
      </div>
      <div *dxTemplate="let cell of 'templateDesc'" style="white-space: pre-wrap;">
        {{ cell.row.data.description }}
      </div>
      <div *dxTemplate="let cell of 'templateRoles'">
        <ng-container *ngTemplateOutlet="tplRoles;context:{profil: cell.row.data}"></ng-container>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} profil(s)">
      </dxo-pager>

    </dx-data-grid>
  </div>

  <div style="width: 40%">
    <dx-toolbar class="yo-toolbar">
      <dxi-item location="before">
        <div class="d-flex flex-row align-items-center">
          <yo-help class="mg-r-15" lang="fr" [file]="pathFileRoles" [dialogMsgSupplier]="help()"
                   [width]="800"></yo-help>
          <strong>Description des rôles</strong>
        </div>
      </dxi-item>
    </dx-toolbar>
    <dx-data-grid
      [dataSource]="roles"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(182)"
      width="100%"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      [allowColumnResizing]="true"
      #grid>
      <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
      <dxi-column dataField="description" caption="Description"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} description(s)">
      </dxo-pager>
    </dx-data-grid>

  </div>

</div>
<!--TEMPLATE ROLES ASSOCIES AU PROFIL-->
<ng-template #tplRoles let-profil="profil">

  <ng-container *ngFor="let role of profil.roleList">
    <div class="row">
      <div class="col-md-12">
        <span class="role" [title]="role.description"
        >{{role.libelle}}</span>
      </div>
    </div>
  </ng-container>

</ng-template>

<!--AJOUT - MODIFICATION D'UN PROFIL-->
<dx-popup
  [showTitle]="true"
  [title]="title"
  [(visible)]="displayProfilRoleDialog"
  [height]="'auto'"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
  [width]="800"
>


  <ng-container *ngIf="dialogProfil">
    <!--  CONTENU-->

    <div class="dialog-line">
      <div class="label" title="Profil">Profil <span class="danger-color">*</span></div>
      <div class="value">
        <input type="text" class="form-control form-control-sm "
               [(ngModel)]="dialogProfil.libelle">
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Profil">Code <span class="danger-color">*</span></div>
      <div class="value">
        <input type="text" class="form-control form-control-sm "
               [(ngModel)]="dialogProfil.code">
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Roles">Rôles <span class="danger-color">*</span></div>
      <div class="value">
        <yo-tagbox
          #rolesTagBox
          [data]="roles"
          [selectedItems]="selectedRoles"
        ></yo-tagbox>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Actif">Actif</div>
      <dx-check-box
        text="Actif"
        [(ngModel)]="dialogProfil.actif">
      </dx-check-box>
    </div>

    <div class="dialog-line">
      <div class="label" title="Description">Description <span class="danger-color">*</span></div>
      <div class="value">
      <textarea
        [(ngModel)]="dialogProfil.description"
        class="form-control form-control-sm"
        rows="10"
      >
        </textarea>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-12" style="height:68px">
        <div *ngFor="let errMsg of dialogErrMsgList" class="danger-color font-14">
          {{errMsg}}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="custom-button-container-right">
    <yo-button
      iconClass="fas fa-save"
      buttonClass="cta-success"
      (onClick)="saveProfil()"
      tooltip="Mettre à jour"
    >
    </yo-button>
    <yo-button iconClass="fas fa-times" buttonClass="cta-delete" (onClick)="closeDialog()"
               tooltip="Fermer"></yo-button>
  </div>
</dx-popup>
