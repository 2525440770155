<ng-container *ngIf="produit">

  <h5>
    <span *ngIf="produit.site && produit.site.id" class="space">
    <label class="badge badge-secondary">
      <yo-site [siteId]="produit.site.id" [siteLibelle]="produit.site.libelle"></yo-site>
    </label>
    </span>

    <label class="badge badge-secondary mg-r-20">
      <yo-icon-produitfab [yoDelayMsTooltip]="1500"
                          [fabrique]="isFabrique">
      </yo-icon-produitfab>

      <span *ngIf="!isFabrique">

      <yo-viewmode
        [yoLabelFab]="'DENRÉE'" [libelle]="libelleProduit(produit)"
        [siteId]="produit.site.id">
      </yo-viewmode>

    </span>

      <span *ngIf="isFabrique" class="mg-r-20">

      <yo-viewmode yoLabelFab="{{produit.typeProduit.id != 0 ? produit.typeProduit.libelle.toUpperCase() : 'PLAT'}}"
                   [libelle]="produit.libelle | uppercase"
                   [siteId]="produit.site.id">
      </yo-viewmode>

    </span>
    </label>

    <span class="floatright">
        <yo-img-entity class="mg-r-5"
                       [displayNoImage]="false"
                       [ordre]="1"
                       [entityName]="produitsSvc.getEntityName()"
                       [height]="48"
                       [refresh]="refresh"
                       [openable]="true"
                       format="thumb"
                       [entityId]="produit?.id"
                       [title]="produit?.libelle"></yo-img-entity>

      </span>
  </h5>

  <p-tabMenu [model]="tabMenuProduit" [activeItem]="activeTab" ></p-tabMenu>
  <router-outlet></router-outlet>

</ng-container>
