import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Subscription} from 'rxjs';
import {MailHistoDTO} from '../../../../core/dtos/mail-histo-dto';
import {MailHistoService} from '../../../../core/services/entities/mail-histo.service';

@Component({
  selector: 'yo-dialog-histo-mails',
  templateUrl: './dialog-mail-histo.component.html',
  styleUrls: ['./dialog-mail-histo.component.scss']
})
export class DialogMailHistoComponent implements OnInit, OnDestroy {

  subMailsHisto: Subscription;
  displayDialog = false;

  mails: MailHistoDTO[];

  constructor(public utils: UtilsService,
              public emailHistoSvc: MailHistoService) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subMailsHisto);
  }

  initData = () => {
    this.subMailsHisto = this.emailHistoSvc.displayEmailHisto$.subscribe(response => {
      this.displayDialog = true;
      this.mails = response;

      if (!this.utils.isCollectionNullOrEmpty(this.mails)) {
        this.mails.forEach(item => {
          item.sentDate = this.utils.convertNumberDateToDate(item.sentDate, false);
          item.readDate = this.utils.convertNumberDateToDate(item.readDate, false);
        });
      }
    });
  };

  closeDialog = () => {
    this.mails = [];
    this.displayDialog = false;
  };

  getScrollPanelStyle = () => {
    if (window.innerHeight <= 768) {
      return {width: '100%', height: '540px'};
    } else if (window.innerHeight <= 1024) {
      return {width: '100%', height: '704px'};
    } else {
      return {width: '100%', height: '740px'};
    }

  };

}
