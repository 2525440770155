import {Injectable} from '@angular/core';
import {GenericHandler} from '../generics/generic-handler';
import {ListeBesoinsDto} from '../../dtos/liste-besoins-dto';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {Title} from '@angular/platform-browser';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {Auth2Service} from '../security/auth2.service';
import {UtilsService} from '../../utils/utils.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {WorkflowDto} from '../../dtos/workflow-dto';
import {WorkflowsService} from './workflows.service';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {Observable, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SiteDTO} from '../../dtos/site-dto';
import {FormFieldTextboxSupplier} from '../../suppliers/form-field-textbox-supplier';
import {startCase as _startCase} from 'lodash'
import * as moment from 'moment';
import {ListeBesoinJoursService} from './liste-besoin-jours.service';
import {FormFieldDropdownSupplier} from '../../suppliers/form-field-dropdown-supplier';
import {TypeEffectifDTO} from '../../dtos/type-effectif-dto';
import {ObjectDTO} from '../../dtos/object-dto';
import {WorkflowInstanceDTO} from '../../dtos/workflow-instance-dto';
import {Sort} from '../../suppliers/generics/generic-request-supplier';
import {HttpService} from "../technique/http.service";

export const URL_GET_WORKFLOW_BY_ID_LB = `dolrest/gestion-listes-besoins/workflow`;
export const URL_POST_START_LB = `dolrest/gestion-listes-besoins/liste-besoins/start`;
export const URL_GET_TYPES_EFFECTIFS = `dolrest/gestion-listes-besoins/typesEffectifs`;
export const URL_DELETE_LISTES_BESOINS = `dolrest/gestion-listes-besoins/deleteListesBesoins`;
export const URL_GET_LISTES_BESOINS = `dolrest/gestion-listes-besoins/listes-besoins`;
export const URL_GET_INFOS_LISTE_BESOINS = `dolrest/gestion-listes-besoins/liste-besoins/infos`;
export const URL_GENERATE_LB_FROM_PLAN_PROD = `dolrest/gestion-listes-besoins/liste-besoins/generate-from-plan-production/`;

export const URL_FULL_VALUES_FILTERS_LB = `dolrest/gestion-listes-besoins/liste-besoins/full-values-filters-generate-from-plan-production/`;

@Injectable({
  providedIn: 'root'
})
export class ListeBesoinsService extends GenericHandler<ListeBesoinsDto> {

  private subjectInfosListeBesoins = new Subject();
  infosListeBesoins$ = this.subjectInfosListeBesoins.asObservable();

  private subjectRefreshGridListeBesoins = new Subject();
  refreshGridListeBesoins$ = this.subjectRefreshGridListeBesoins.asObservable();

  optionsTypeEffectifs: TypeEffectifDTO[] = [];

  constructor(
    private cwSvc: WorkflowsService,
    private cjSvc: ListeBesoinJoursService,
    private httpSvc: HttpService,
    utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title) {

    super(utils, auth2Svc, router, http, title);

    this.getTypesEffectifs().subscribe(response => {
      const typesEffectifs = response.resultList as TypeEffectifDTO[];
      typesEffectifs.map(te => te.libelle = te.libelle.toUpperCase());
      this.optionsTypeEffectifs = typesEffectifs;
    });

  }

  getTotalRecordsLabel = (): string => _startCase(this.getEntityName());


  createEmptyDTO = (): ListeBesoinsDto => {

    const cf = new ListeBesoinsDto();

    cf.site = new SiteDTO();

    cf.site.id = this.auth2Svc.utilisateur.sitePrincipal.id;

    cf.site.libelle = this.auth2Svc.utilisateur.sitePrincipal.libelle;

    cf.actif = true;

    const SEP = ' ';
    cf.libelle = 'LBD' + SEP;

    cf.code = this.generateCode();

    // par defaut, effectif prevu
    cf.typeEffectif = this.optionsTypeEffectifs[0];

    return cf;
  };

  getAllFromEnvironnement = (): void => {
  };

  getCreateNewObjectLabel = (): string => "";

  getEntityName = (): string => "listeBesoin";

  getFields = (dto: ListeBesoinsDto): FormFieldBaseSupplier<any>[] => {

    if (this.utils.isNullOrEmpty(dto)) {
      dto = this.createEmptyDTO();
    }

    let formFields: FormFieldBaseSupplier<any>[] = [

      new FormFieldDropdownSupplier({
        key: 'site',
        label: 'Site',
        readonly: !this.canModifySite(dto),
        type: 'externalRef',
        value: this.utils.preSelectSingleList(this.optionsSites, dto.site),
        order: 1,
        options: this.optionsSites
      }),

      new FormFieldTextboxSupplier({
        key: 'code',
        label: 'Code',
        readonly: true,
        help: `Le code de la liste de besoins est unique pour toute l'application. Il est non modifiable.`,
        value: dto.code,
        minLength: 3,
        maxLength: 50,
        required: true,
        order: 2
      }),

      new FormFieldTextboxSupplier({
        key: 'libelle',
        label: 'Libellé',
        readonly: !this.canModify(dto),
        type: 'simple',
        help: `Le libellé de la commande fournisseur`,
        value: dto.libelle,
        minLength: 3,
        maxLength: 100,
        required: true,
        order: 3
      }),


      new FormFieldDropdownSupplier({
        key: 'typeEffectif',
        label: `Type d'effectif`,
        type: 'externalRef',
        readonly: !this.canModify(dto),
        help: `On peut calculer sur l'effectif prévu ou l'effectif réel`,
        value: this.utils.preSelectSingleList(this.optionsTypeEffectifs, dto.typeEffectif),
        options: this.optionsTypeEffectifs,
        required: true,
        order: 4
      })
    ];

    return formFields;
  };


  /**
   * Est ce que l'utilisateur connecté peut modifier la commande fournisseur à l'étape courante
   * @param {ListeBesoinsDto} lb commande fournisseur
   * @returns {boolean}
   */
  canModifyCurrentStep = (lb: ListeBesoinsDto): boolean => {

    if (!this.utils.isNullOrEmpty(lb)) {

      const etape = lb.workflowEtape;
      if (!this.utils.isNullOrEmpty(etape)) {

        const actors = etape.workflowEtape__utilisateurList;
        if (!this.utils.isCollectionNullOrEmpty(actors)) {
          const exists = !!this.utils.getFirstElement(actors.filter(actor => actor.idUtilisateur === this.auth2Svc.utilisateur.id));

          return exists;
        }
      }
    }

    return false;
  };

  getHelp = (): DialogMsgSupplier => undefined;

  getOas = (): boolean => false;

  getSort = (): string[] => undefined;

  getTitle = (): string => "";


  /**
   * Récupérer la date de création d'une listede besoin
   * @param {ListeBesoinsDto} c
   * @returns {string}
   */
  getCreationDate = (cf: ListeBesoinsDto): string => this.utils.getFrenchDateHHMM(cf.date);


  /**
   * Générer un code unique de commande fournisseur
   */
  generateCode = (): string => `LB-${moment()}`;

  /**
   * Récupérer le workflow d'une commande fournisseur
   * @param {number} idListeBesoin
   * @returns {Observable<any>}
   */
  getWorkflowByIdListeBesoin = (idListeBesoin: number) => {
    return this.http
      .get<ResponseWrapper<WorkflowDto>>(URL_GET_WORKFLOW_BY_ID_LB, {params: new HttpParams().set('idListeBesoin', idListeBesoin + '')})
      .pipe(
        catchError(error => this.utils.handleError(error))
      );

  };

  getTypesEffectifs = (): Observable<ResponseWrapper<TypeEffectifDTO>> => {

    return this.http.get(URL_GET_TYPES_EFFECTIFS)
      .pipe(
        catchError(error => this.utils.handleError(error))
      );
  };

  getEditObjectLabel = (data: ObjectDTO): string => "";

  createListeBesoinsFromWorkflowInstance = (wi: WorkflowInstanceDTO): ListeBesoinsDto => {

    // persister la nouvelle liste de besoins avec l'instance
    let lb: ListeBesoinsDto = new ListeBesoinsDto();

    lb.site = wi.site;
    lb.libelle = wi.libelle;
    lb.actif = true;
    lb.date = new Date().getTime();
    lb.archive = false;
    lb.workflowInstance = wi;
    lb.code = wi.code;

    return lb;

  };

  /**
   * Récupérer les listes de besoins de l'utilisateur connecté
   */
  getListesBesoins = (page: number, size: number, sort: Sort) => {
    let sortStr = '';
    if (sort && !this.utils.isNullOrEmpty(sort.dir) && !this.utils.isNullOrEmpty(sort.path)) {
      sortStr = `sort=${sort.path},${sort.dir}`;
    }

    let paramsUrl = '?';
    if (page !== null && page !== undefined && size !== null && size !== undefined)
      paramsUrl += `page=${page}&size=${size}`;
    if (sortStr)
      paramsUrl += `&${sortStr}`;

    return this.http.get(`${URL_GET_LISTES_BESOINS}${paramsUrl}`).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  /**
   * Récupérer les infos de sélection d'une liste de besoins
   */
  getInfosListeBesoin = (idListeBesoin: number) => {

    return this.http.get(URL_GET_INFOS_LISTE_BESOINS, {
      params: new HttpParams().set('idListeBesoin', idListeBesoin + '')
    }).pipe(
      catchError(error => this.utils.handleError(error, true))
    )

  };

  generateFromPlanProduction = (planProductionId: number, newLabelListeBesoin?: string, filtersListeBesoin?: any, usedSimulationStocks?: boolean) =>  {
    let url = `${URL_GENERATE_LB_FROM_PLAN_PROD}${planProductionId}`;
    url += (usedSimulationStocks) ? `?usedSimulationStocks=${true}` : `?usedSimulationStocks=${false}`;
    url += (newLabelListeBesoin) ? `&label=${encodeURIComponent(newLabelListeBesoin)}` : '';
    return this.httpSvc.post(url, filtersListeBesoin);
  }

  filtersForListeBesoinFromPlanProduction = (idUdp: number) => this.httpSvc.get(`${URL_FULL_VALUES_FILTERS_LB}${idUdp}`);

  announceInfosListeBesoin = (event: any) => {
    this.subjectInfosListeBesoins.next(event);
  };
}
