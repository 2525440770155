<dx-toolbar class="yo-toolbar">
  <dxi-item location="before">
    <div class="d-flex flex-row align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <yo-button
          class="mg-r-10"
          iconClass="fa fa-plus"
          label="CRÉER"
          tooltip="Créer un modèle de plat"
          (onClick)="openModelePlat(null)"
          [disabled]="!canCreateAndDelete()">
        </yo-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item location="after">
    <div class="d-flex flex-row align-items-center">
      <yo-button
        iconClass="fas fa-trash"
        buttonClass="cta-delete"
        (onClick)="delete()"
        [disabled]="!canCreateAndDelete() || !selectedRows.length"
        tooltip="Supprimer les éléments sélectionnés">
      </yo-button>
    </div>
  </dxi-item>
</dx-toolbar>

<dx-data-grid
  [dataSource]="modelesPlatsList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions"
              [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate">
  </dxi-column>
  <dxi-column dataField="site" caption="Site" cellTemplate="actionsSiteTemplate"
              [calculateCellValue]="calculateSiteValue" [filterOperations]="['contains']"
              [allowFiltering]="true" width="300"></dxi-column>
  <dxi-column dataField="libelle" caption="Libellé"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>


  <!--  ACTIONS CELL TEMPLATE-->
  <div *dxTemplate="let cell of 'actionsCellTemplate'" class="custom-button-container">

    <yo-cell-button (yoNavigation)="openModelePlat(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    [yoIconClass]="canModify(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                    [yoTooltip]="canModify(cell.row.data)?'Modifier le modèle':'Voir le modèle'">
    </yo-cell-button>

    <yo-cell-button (yoNavigation)="openPanelDuplication($event, cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoSmall]="true"
                    yoTextAlign="center"
                    yoIconClass="fa fa-copy"
                    [yoTooltip]="'Dupliquer le modèle de plat '+ cell.row.data.libelle"
    ></yo-cell-button>

  </div>

  <div *dxTemplate="let cell of 'actionsSiteTemplate'">
    <yo-site [siteLibelle]="cell.row.data?.site?.libelle" [siteId]="cell.row.data?.site?.id"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} modèles de plats">
  </dxo-pager>

</dx-data-grid>


<p-overlayPanel #opDuplication>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          title=""
        >Site sur lequel dupliquer le modèle</label>
        <div class="col-sm-10">
          <p-dropdown [options]="auth2Svc.utilisateur.siteListLocaux"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="duplicationSiteSelected"
                      placeholder="sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'360px'}">
            <ng-template let-site pTemplate="selectedItem">
                <span style="vertical-align:middle; margin-left: .5em">
                <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site></span>
            </ng-template>
            <ng-template let-site pTemplate="item">
              <div style="position: relative;height: 25px;">
                <div style="font-size:14px;margin-top:4px">
                  <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <yo-button
        label="Dupliquer"
        iconClass="fas fa-copy"
        (onClick)="duplicateModele()">
      </yo-button>
    </div>
  </div>
</p-overlayPanel>

<!--PANEL EXPORT EXCEL-->
<p-overlayPanel #opExcel>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          title=""
        >Nombre de modèles à exporter</label>
        <div class="col-sm-10">
          <p-dropdown [options]="exportOptionList"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="selectExportOption"
                      placeholder="Sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'180px'}">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

</p-overlayPanel>
