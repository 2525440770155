import {ObjectDTO} from "./object-dto";
import {SiteDTO} from './site-dto';
import {UniteDeProductionDTO} from "./unite-de-production-dto";
import {PlanProductionDetailDTO} from "./plan-production-detail-dto";
import {SortiePourProductionDTO} from "./sortie-pour-production-dto";
import {ProduitDeclinaisonDTO} from "./produit-declinaison-dto";

export class PlanProductionDTO extends ObjectDTO {

  code: string;
  libelle: string;
  actif: boolean;
  dateCreation:number;
  currentStep: string;

  dateDebutProduction: number;
  dateFinProduction: number;
  unitesDeProductions: UniteDeProductionDTO[] = [];
  typeEffectifEnum: string;
  typeVueSortieProduction: string;

  rows: PlanProductionDetailDTO[] = [];
  sorties: SortiePourProductionDTO[] = [];

  denreesWithoutItemsForSorties: ProduitDeclinaisonDTO[] = [];

  constructor() {
    super();
    this.code = "";
    this.libelle = "";
    this.site = new SiteDTO();
    this.site.libelle = "";
  }
}
