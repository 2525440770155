import {ObjectDTO} from "./object-dto";
import {CouleurDTO} from "./couleur-dto";

export class FamilleGemrcnDTO  extends ObjectDTO{

  libelle : string;
  code : string;
  abreviation : string;
  couleur:CouleurDTO;
  actif:boolean;

  ordre:number;
}
