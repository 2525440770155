import {ObjectDTO} from "./object-dto";
import {CatalogueAchatDTO} from "./catalogue-achat-dto";
import {UniteDeMesureDTO} from "./unitedemesure-dto";
import {GroupeAchatCaViewModel} from "../models/gestion-produits/catalogue-achat/groupe-achat-ca-view.model";

export class CatalogueAchatPrixPeriodeDTO extends ObjectDTO {
  catalogueAchat: CatalogueAchatDTO;
  prix: number;
  dateEffective: Date;
  libelle: string;
  referenceArticle: string;
  uniteDeCommande: UniteDeMesureDTO;
  uniteDeFacturation: UniteDeMesureDTO;
  uniteDeStockage: UniteDeMesureDTO;
  ratioUniteCommandeUniteBase: number;
  ratioUniteFacturationUniteBase: number;
  ratioUniteStockageUniteBase: number;
  actif: boolean;
  minimumCommande: number;
  conditionnePar: number;


  groupeAchatCaList: GroupeAchatCaViewModel[] = [];
}
