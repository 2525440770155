// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10850(8266e2a86d)-C04/11/2024-11:18:00-B04/11/2024-11:19:55' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10850(8266e2a86d)-C04/11/2024-11:18:00-B04/11/2024-11:19:55",
  branch: "master",
  latestTag: "6.1",
  revCount: "10850",
  shortHash: "8266e2a86d",
  longHash: "8266e2a86dccaf6322322fc46552ce1b2f8adff3",
  dateCommit: "04/11/2024-11:18:00",
  dateBuild: "04/11/2024-11:19:55",
  buildType: "Ansible",
  } ;
