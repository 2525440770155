<dx-popup
  [showTitle]="true"
  title="Edition des bons d'allotissement "
  [(visible)]="displayDialog"
  [width]="400"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>

  <div class="print-order">
    <div>
      <i class="fa fa-object-group mg-r-5"></i>ORDRE D'IMPRESSION
    </div>
    <dx-radio-group [(value)]="defaultOrder" valueExpr="value" displayExpr="label" [items]="formatPrintingOptions"></dx-radio-group>
  </div>

  <div class="print-order">
    <div>
      <i class="fa fa-object-group mg-r-5"></i>OPTION D'IMPRESSION
    </div>
    <div class="dialog-line">
      <dx-check-box
        [(ngModel)]="totalAndTraca" text="Totaux / Traçabilité"
        [value]="false">
      </dx-check-box>
    </div>
  </div>

  <div class="custom-button-container-right">
    <yo-button label="Imprimer" iconClass="fas fa-print"
               (onClick)="downloadBonAllotissement()">
    </yo-button>
    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>

</dx-popup>
