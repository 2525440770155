import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {DxLookupComponent} from "devextreme-angular";

@Component({
  selector: 'yo-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookUpComponent implements OnInit {

  autoSelectedData: any;

  @Input() data: any[];
  @Input() enableSearch: boolean = true;
  @Input() displayValue: string = "libelle";
  @Input() key: string = "id";
  @Input() disabled: boolean = false;
  @Input() selectedItem: any;
  @Input() placeholder: string;
  @Input() width: any = "auto";
  @Input() autoSelect: boolean = true;
  @Input() showClearButton: boolean = false;

  @Output() onValueChange = new EventEmitter;

  @ViewChild("lookup") lookUp: DxLookupComponent;

  ngOnInit(): void {
  }

  getAutoSelectedData = () => {
    if (this.selectedItem)
      return this.selectedItem;
    else if (this.autoSelect && this.data) {
      this.autoSelectedData = this.data.length === 1 ? this.data[0][this.key] : null;
      return this.autoSelectedData;
    }
  }

  getSelectedItem = (): any => {
    return this.lookUp.selectedItem;
  }


  valueChanged(data) {
    this.onValueChange.emit(data.value);
  }
}
