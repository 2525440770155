<table>
  <thead>
  <tr id="filter-row">
    <td><input type="text"
               placeholder="Recherche"
               [(ngModel)]="_filter"
               (ngModelChange)="onFilter($event)"/>
    </td>
  </tr>
  <tr>
    <th>
      <input id="select-all"
             type="checkbox"
             [indeterminate]="_selectAllValue == undefined"
             [(ngModel)]="_selectAllValue"
             (ngModelChange)="onSelectAll()"/>
      <label>Sélectionner tout</label>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let ligne of _rows"
      [style.display]="ligne.hidden || ligne?.parent?.collapsed ? 'none' : ''">
    <td>
      <input
        [style.margin-left.px]="ligne.depth * 15"
        id="select-{{ligne.data[key]}}"
        type="checkbox"
        [indeterminate]="ligne.selected == undefined"
        [(ngModel)]="ligne.selected"
        (ngModelChange)="onCheckboxChecked(ligne)"/>
      <div class="clickable" (click)="ligne?.children?.length > 0 ? collapseGroup(ligne) : simulateCheckboxClick(ligne)">
        <span>{{ligne.data[displayValue]}}</span>
        <i *ngIf="ligne?.children?.length > 0" class="fas fa-caret-{{ligne.collapsed ? 'up' : 'down'}}"></i>
      </div>
    </td>
  </tr>
  </tbody>
</table>
