import {Injectable} from '@angular/core';
import {ProduitDeclinaisonDTO} from '../../../core/dtos/produit-declinaison-dto';
import {ProduitDTO} from '../../../core/dtos/produit-dto';

@Injectable()
export class TextMessageService {

  listeSuppression: string = `
    <li>les fiches techniques qui utilisent cette denrée</li>
    <li>l'historique des lignes de commandes dans lesquelles sont utilisées les articles de cette denrée</li>
    <li>les listes de besoins</li>
    <li>les articles en stock</li>
    <li>l'historique des sorties pour production</li>
    <li>l'éventuelle liaison avec les lots marchés</li>
  `;

  getProduitDeletionMessage = (produit: ProduitDeclinaisonDTO | ProduitDTO): string => {
    console.log(produit)
    // if type is a Plat
    if (produit instanceof ProduitDeclinaisonDTO && produit?.fabrique || produit instanceof ProduitDTO && produit?.familleProduit.fabrique) {
      return `Confirmez-vous la suppression du produit ${produit?.libelle} ?<br/><br/>` +
        'Important : la suppression de ce plat est définitive. Cette suppression impactera aussi :<br/>' +
        '<ul>' +
        '    <li>les menus qui utilisent ce plat</li>' +
        '    <li>les commandes clients</li>' +
        '    <li>Les lignes du plan de production</li>' +
        '</ul>';
      // if type is a Denrée
    } else if ((produit instanceof ProduitDTO && produit?.typeProduit?.code === "DENREE")
      || (produit instanceof ProduitDeclinaisonDTO && produit?.declinaison?.typeProduit?.code === "DENREE")) {
      return `Confirmez-vous la suppression de la denrée ${produit?.libelle} ?<br/><br/>` +
        'Important : la suppression de cette denrée est définitive. Cette suppression impactera aussi :<br/>' +
        '<ul>' +
        this.listeSuppression +
        '</ul>';
    } else {
      return `Confirmez-vous la suppression du plat ${produit?.libelle} ?<br/><br/>` +
        'Important : la suppression de ce plat est définitive. Cette suppression impactera aussi :<br/>' +
        '<ul>' +
        this.listeSuppression +
        '<li>l\'éventuelle liaison avec un modèle de conditionnement de plat</li>' +
        '</ul>';
    }
  };
}
