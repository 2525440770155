import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../core/utils/utils.service';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {PlanProductionDTO} from '../../../core/dtos/plan-production-dto';
import {ProductionService} from '../../../core/services/gestion-production/production.service';
import {PlanProductionDetailDTO} from '../../../core/dtos/plan-production-detail-dto';
import {UniteDeProduction__EquipeDTO} from '../../../core/dtos/unite-de-production__equipe';
import {RoutemapService} from '../../../core/services/routemap.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {Subscription} from 'rxjs';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {PlanProductionService} from '../../../core/services/entities/plan-production.service';

import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import CustomStore from "devextreme/data/custom_store";
import {DevextremeService} from "../../../core/services/technique/devextreme.service";
import {DxDataGridComponent} from "devextreme-angular";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-plans-de-production',
  templateUrl: './plans-de-production.component.html',
  styleUrls: ['./plans-de-production.component.scss']
})
export class PlansDeProductionComponent implements OnInit, OnDestroy {

  totalRecords: number;
  planProductionItems: PlanProductionDTO[] = [];
  loadingTable = false;
  displayEditDialog: boolean = false;
  details: PlanProductionDetailDTO[];

  equipes: UniteDeProduction__EquipeDTO[] = [];

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;
  noDataText: string = 'En cours de chargement...';

  isActionDisabled: boolean = true;

  pathFile: string = HELP_FOLDERS.PLANS_PRODUCTION + '/plans-de-production';

  subRefreshItems: Subscription;

  data: any[];

  constructor(public utils: UtilsService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              public prodSvc: ProductionService,
              private routeMapSvc: RoutemapService,
              private planProdSvc: PlanProductionService,
              public auth2Svc: Auth2Service,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.initPlansProductions();
    this.initRefreshPlansProductions();
    this.data = [ { id: 'STEP_SELECTION_PLCS', label: this.getLabelFromStep('STEP_SELECTION_PLCS') }, { id: 'STEP_PRODUCTION_LISTE_PLATS', label: this.getLabelFromStep('STEP_PRODUCTION_LISTE_PLATS') }, { id: 'STEP_SORTIES_PRODUCTION', label: this.getLabelFromStep('STEP_SORTIES_PRODUCTION') }];
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRefreshItems);
  }

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
    this.isActionDisabled = true;
  }

  initRefreshPlansProductions = (): void => {
    this.subRefreshItems = this.prodSvc.refreshItems$
      .subscribe((planProductionItem) => {
        const idx: number = this.planProductionItems.findIndex(x => x.id === planProductionItem.id);
        if (idx === -1)
          this.planProductionItems.push(planProductionItem);
        else
          this.planProductionItems.splice(idx, 1, planProductionItem);
        this.planProductionItems.sort((a, b) => b.id - a.id);
      });
  }

  initPlansProductions = (): void => {
    this.noDataText = 'En cours de chargement...';
    this.prodSvc.searchPlansProduction()
      .subscribe(response => {
        this.planProductionItems = response.resultList
        this.noDataText = 'Pas de données';
      });
  }

  editCurrentLine = (id: number): void => {
    this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', id]); // Je ne trouve pas logique ce mode de fonctionnement
  }

  canEditProductionPlan = (planProduction: PlanProductionDTO): boolean => {
    return this.gds.canModify(planProduction) && (planProduction.currentStep === 'STEP_PRODUCTION_LISTE_PLATS' || planProduction.currentStep === 'STEP_SORTIES_PRODUCTION');
  }

  openInitProcessus = (): void => {
    this.prodSvc.announceStartPlanProduction();
  };

  deletePlanProdItems = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();
    this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
      this.prodSvc.deletePlansProds(rowsSelected)
        .subscribe(response => {
          this.initPlansProductions();
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les plans de productions sélectionnés ont été supprimés avec succès`);
        });
    });
  };

  canCreatePlanProduction = (): boolean => this.auth2Svc.utilisateur.siteListLocaux && this.auth2Svc.utilisateur.siteListLocaux.length > 0;

  changeValueLibelle = (newData, newText, currentRowData) => {
    const idx: number = this.planProductionItems.findIndex(p => p.id === currentRowData.id);
    if (idx >= 0)
      this.planProductionItems[idx].libelle = newText;
  }

  /**
   * TODO
   * @param $event
   */
  onEditorPreparing = ($event: any): void => {
    if ($event.dataField === "libelle" && $event.parentType === "dataRow") {
      const pp: PlanProductionDTO = $event.row.data;
      this.gfs.saveOne(pp, this.planProdSvc.getEntityName()).subscribe(response => {
      });
    }
  };

  onClickStepForDirectAccess = ($event, idRow: number, currentStep: string): void => {
    switch($event?.itemData?.id) {
      case 'STEP_SELECTION_PLCS':
        this.routeMapSvc.goToSecondaryRoute(['steps', 'selection-filters', idRow]);
        break;
      case 'STEP_PRODUCTION_LISTE_PLATS':
        if (currentStep === 'STEP_SELECTION_PLCS') {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous devez aupréalable sélectionner des filtres avant de pouvoir générer votre plan de production. Nous vous invitions donc à sélectionner l'étape "Sélection des repas"`);
          break;
        }
        this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', idRow]);
        break;
      case 'STEP_SORTIES_PRODUCTION':
        if (currentStep === 'STEP_SELECTION_PLCS') {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous devez aupréalable sélectionner des filtres avant de pouvoir générer votre plan de production et faire des sorties de plan de production. Nous vous invitions donc à sélectionner l'étape "Sélection des repas"`);
          break;
        }
        if (currentStep === 'STEP_SELECTION_PLCS') {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous devez aupréalable générer votre plan de production pour faire des sorties de plan de production. Nous vous invitions donc à sélectionner l'étape "Plan de production"`);
          break;
        }
        this.routeMapSvc.goToSecondaryRoute(['steps', 'sorties-production', idRow]);
        break;
      default:
        break;
    }
  }

  openStep = (id: number): void => {
    const pp: PlanProductionDTO = this.planProductionItems.find(pp => pp.id === id);
    switch(pp.currentStep) {
      case 'STEP_START':
        break;
      case 'STEP_SELECTION_PLCS':
        this.routeMapSvc.goToSecondaryRoute(['steps', 'selection-filters', id]);
        break;
      case 'STEP_PRODUCTION_LISTE_PLATS':
        this.routeMapSvc.goToSecondaryRoute(['steps', 'plan-production', id]);
        break;
      case 'STEP_SORTIES_PRODUCTION':
        this.routeMapSvc.goToSecondaryRoute(['steps', 'sorties-production', id]);
        break;
      default:
        break;
    }
  }

  getClassFromCurrentStep = (id: number): string => {
    const pp: PlanProductionDTO = this.planProductionItems.find(pp => pp.id === id);
    if (pp) {
      switch (pp.currentStep) {
        case 'STEP_START':
          return 'step-start';
        case 'STEP_SELECTION_PLCS':
          return 'step-selection-plcs';
        case 'STEP_PRODUCTION_LISTE_PLATS':
          return 'step-production-liste-plats';
        case 'STEP_SORTIES_PRODUCTION':
          return 'step-sorties-production';
        default:
          return 'step-start';
      }
    }
  }

  getLabelFromCurrentStep = (id: number): string => {
    const pp: PlanProductionDTO = this.planProductionItems.find(pp => pp.id === id);
    if (pp) {
      switch (pp.currentStep) {
        case 'STEP_START':
          return 'Début';
        case 'STEP_SELECTION_PLCS':
          return 'Sélection des repas';
        case 'STEP_PRODUCTION_LISTE_PLATS':
          return 'Plan de production';
        case 'STEP_SORTIES_PRODUCTION':
          return 'Sorties pour production';
        default:
          return 'Début';
      }
    }
  }

  getLabelFromStep = (codeStep: string): string => {
    switch (codeStep) {
      case 'STEP_START':
        return 'Début';
      case 'STEP_SELECTION_PLCS':
        return 'Sélection des repas';
      case 'STEP_PRODUCTION_LISTE_PLATS':
        return 'Plan de production';
      case 'STEP_SORTIES_PRODUCTION':
        return 'Sorties pour production';
      default:
        return 'Début';
    }

  }

}
