import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {Subscription} from "rxjs";
import {confirm} from "devextreme/ui/dialog";
import {InternationalizationService} from "../../core/services/i8n/i8n.service";
import {ToastService} from "../../core/services/technique/toast.service";
import {Auth2Service} from "../../core/services/security/auth2.service";
import {FamilleEgalimService} from "../../core/services/entities/famille-egalim.service";
import {FamilleEgalimDTO} from "../../core/dtos/referentiel/reception/famille-egalim-dto";

@Component({
  selector: 'yo-famille-egalim',
  templateUrl: './famille-egalim.component.html',
  styleUrls: ['./famille-egalim.component.scss']
})
export class FamilleEgalimComponent implements OnInit, OnDestroy {

  subLog: Subscription;
  subFamilleEgalimSaved: Subscription;
  subFamilleEgalim: Subscription;
  deleteSub: Subscription;

  logged: boolean = false;

  famillesEgalim: FamilleEgalimDTO[] = [];

  selectedRows: number[] = [];
  errors: any[] = [];

  displayDeletePopupErrors: boolean = false;

  pathFile: string = null;// HELP_FOLDERS.REFERENTIEL + '/familleEgalim/familleEgalim';

  constructor(public readonly familleEgalimService: FamilleEgalimService,
              public readonly utils:UtilsService,
              private readonly toastSvc: ToastService,
              private readonly i8nSvc: InternationalizationService,
              private readonly auth2Svc: Auth2Service) {
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subLog);
    this.utils.unsubscribe(this.subFamilleEgalimSaved);
    this.utils.unsubscribe(this.subFamilleEgalim);
    this.utils.unsubscribe(this.deleteSub);
  }

  ngOnInit(): void {
    this.initFamilleEgalimsSubscription();
    this.initFamilleEgalimsSavedSubscription();
  }

  openDialogEdition = (familleEgalim?: FamilleEgalimDTO): void => {
    this.familleEgalimService.announceOpenDialogEdition(familleEgalim);
  };

  calculateSiteValue = (familleEgalim: FamilleEgalimDTO): String => {
    return familleEgalim?.site?.libelle
  };

  initFamilleEgalimsSubscription = (): void => {
    this.subFamilleEgalim = this.familleEgalimService.findFamillesEgalim().subscribe(response => {
      this.famillesEgalim = response;
    });
  }

  initFamilleEgalimsSavedSubscription = (): void => {
    this.subFamilleEgalimSaved = this.familleEgalimService.familleEgalimSaved$
      .subscribe((famille) => {
        let familleFound = this.famillesEgalim.find(f => f.id == famille.id);
        if (familleFound)
          this.famillesEgalim[this.famillesEgalim.indexOf(familleFound)] = famille;
        else
          this.famillesEgalim.push(famille);
      });
  };

  displayDeleteErrors = (): void => {
    this.displayDeletePopupErrors = true;
  }

  closeErrors = (): void => {
    this.errors = [];
    this.displayDeletePopupErrors = false;
  }


  deleteValues = async (): Promise<void> => {
    this.errors = [];
    let idsToDeleteInTable = Object.assign([], this.selectedRows);

    let isDeletionValidated: boolean = await confirm(this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? "CONFIRM_DELETION_PLURAL" : "CONFIRM_DELETION_SINGULAR", null), this.i8nSvc.getLabelFromCode("Suppression", null));

    if (!isDeletionValidated)
      return new Promise(null);

    this.deleteSub = this.familleEgalimService.deleteByFilters(this.selectedRows).subscribe(response => {
      const res: any = response.one;

      if (res) {
        if (res.messagesErrorList?.length) {
          for (const error of res.messagesErrorList) {
            const infosLine: string = this.i8nSvc.getLabelFromCode(error.code, error.args.split(','));
            this.errors.push({infosLine});
            const elementToKeep = this.famillesEgalim.find(familleEgalim => familleEgalim.code === error.args);
            idsToDeleteInTable = idsToDeleteInTable.filter(id => elementToKeep.id !== id);
          }
          this.displayDeleteErrors();
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, this.i8nSvc.getLabelFromCode(idsToDeleteInTable.length > 1 ? `DELETION_COMPLETED_PLURAL` : `DELETION_COMPLETED_SINGULAR`, null));
        }
        this.famillesEgalim = this.famillesEgalim.filter(familleEgalim => !idsToDeleteInTable.find(id => id === familleEgalim.id));
      }
    });
  }

  canDelete = (): boolean => this.selectedRows.length > 0;

}
