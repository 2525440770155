<dx-popup [showTitle]="true"
          title="Origines des produits carnés"
          [dragEnabled]="true"
          [closeOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="visible"
          [width]="500"
          height="auto">
  <div class="dialog-line">
    <div class="label" title="Libellé">Naissance</div>
    <div class="value">
      <yo-lookup
        #naissance
        [data]="origines"
        (onValueChange)="onOriginChange()"
        [placeholder]="getOriginePlaceholder('de naissance', selectedNaissance?.id)"
        displayValue="libelle"
        [selectedItem]="selectedNaissance?.id"
        [showClearButton]="true"
        [autoSelect]="false"
        [disabled]="disabled">
      </yo-lookup>
    </div>
  </div>
  <div class="dialog-line">
    <div class="label" title="Élevage">Élevage</div>
    <div class="value">
      <yo-lookup
        #elevage
        [data]="origines"
        (onValueChange)="onOriginChange()"
        [placeholder]="getOriginePlaceholder('d\'élevage', selectedElevage?.id)"
        displayValue="libelle"
        [selectedItem]="selectedElevage?.id"
        [autoSelect]="false"
        [showClearButton]="true"
        [disabled]="disabled">
      </yo-lookup>
    </div>
  </div>
  <div class="dialog-line">
    <div class="label" title="Abattage">Abattage</div>
    <div class="value">
      <yo-lookup
        #abattage
        [data]="origines"
        (onValueChange)="onOriginChange()"
        [placeholder]="getOriginePlaceholder('d\'abattage', selectedAbattage?.id)"
        displayValue="libelle"
        [selectedItem]="selectedAbattage?.id"
        [autoSelect]="false"
        [showClearButton]="true"
        [disabled]="disabled">
      </yo-lookup>
    </div>
  </div>
</dx-popup>
