import {Injectable} from '@angular/core';
import {HttpService} from "../technique/http.service";
import {Observable} from "rxjs";
import {ResponseWrapper} from "../../suppliers/wrappers/response-wrapper";
import {Subject} from "rxjs/index";
import {ObjectDTO} from "../../dtos/object-dto";
import {Auth2Service} from "../security/auth2.service";
import {GraphQLService} from "../technique/graphql.service";
import {map} from "rxjs/operators";
import {FamilleEgalimDTO} from "../../dtos/referentiel/reception/famille-egalim-dto";

@Injectable({
  providedIn: 'root'
})
export class FamilleEgalimService {

  private subjectFamilleEgalimSaved: Subject<any> = new Subject<any>();
  familleEgalimSaved$ = this.subjectFamilleEgalimSaved.asObservable();

  private subjectOpenDialog: Subject<FamilleEgalimDTO> = new Subject<FamilleEgalimDTO>();
  openDialog$ = this.subjectOpenDialog.asObservable();

  constructor(
              private httpSvc: HttpService,
              private auth2Svc: Auth2Service,
              private graphQlSvc: GraphQLService) {
  }

  findFamillesEgalim = (): Observable<any> => {
    const idsSites: number[] = this.auth2Svc.utilisateur.sites.map(s => s.id);
    return this.graphQlSvc.sendQuery(`
      {
        allFamillesEgalim(filters: {
          siteIds: [${idsSites}]
        }) {
            id,
            libelle,
            code,
            ordre,
            actif,
            site {
                id,
                libelle,
            }
        }
      }
    `).pipe(map(result => result.allFamillesEgalim))
  }

  canModify(element: ObjectDTO): boolean {
    if (!element || !element?.site || !element?.site.id)
      return true;
    return this.auth2Svc.isSiteLocal(element.site.id);
  }

  deleteByFilters = (rowsToDelete?: number[]): Observable<ResponseWrapper<FamilleEgalimDTO>> => {
    let params: string = '1=1';
    if (rowsToDelete && rowsToDelete.length) params += `&ids=${rowsToDelete.join(',')}`
    return this.httpSvc.delete(`dolrest/referentiel/reception/famille-egalim/delete?${params}`);
  };

  announceOpenDialogEdition = (familleEgalim: FamilleEgalimDTO) => {
    this.subjectOpenDialog.next(familleEgalim);
  };

  announceFamilleEgalimSaved = (familleEgalim: FamilleEgalimDTO) => {
    this.subjectFamilleEgalimSaved.next(familleEgalim);
  }

  save = (familleEgalim: FamilleEgalimDTO): Observable<ResponseWrapper<FamilleEgalimDTO>> => {
    return this.httpSvc.post("dolrest/referentiel/reception/famille-egalim/save", familleEgalim);
  }

}
