<p-dialog (onHide)="closeDialog()"
          [dismissableMask]="false"
          position="top"
          [contentStyle]="{'overflow':'visible'}"
          [(visible)]="displayDialog"
          [responsive]="true"
          [style]="{'width':'1000px'}"
          [modal]="true"
          [breakpoint]="640">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
      <i class="fas fa-sign-in-alt mg-r-5"></i> Ajouter un lot
    </div>
  </ng-template>


  <!-- PANEL ARTICLE -->
  <p-panel header="DENRÉE (produit déclinaison)" [toggleable]="true" [style]="{'margin-bottom': '10px'}">
    <div class="dialog-line">
      <div class="dialog-line">
        <div class="label" title="Nom">Nom :</div>
        <div class="value">
          <input type="text" pInputText [value]="produitDeclinaison?.libelle" disabled="true">
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="Code">Code :</div>
        <div class="value">
          <input type="text" pInputText [value]="produitDeclinaison?.code" disabled="true">
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="Declinaison">Declinaison :</div>
        <div class="value">
          <input type="text" pInputText [value]="produitDeclinaison?.declinaison?.libelle" disabled="true">
        </div>
      </div>
    </div>
  </p-panel>

  <!-- PANEL ARTICLE -->
  <p-panel header="ARTICLE" [toggleable]="true" [style]="{'width':'100%'}">
    <div class="dialog-line mg-b-10">
      <div class="dialog-line">
        <div class="label" title="Unité de production">Unité de production :<span class="danger-color">*</span></div>
        <div class="value">
          <yo-lookup
            [data]="uniteProductionList"
            [selectedItem]="uniteProductionSelected?.id"
            (onValueChange)="onChangeUdp($event)"
          ></yo-lookup>
        </div>
      </div>
      <div class="dialog-line">
        <div class="label" title="Fournisseur">Fournisseur :</div>
        <div class="value">
          <yo-lookup
            [data]="fournisseurList"
            [selectedItem]="fournisseurSelected?.id"
            (onValueChange)="onChangeFournisseur($event)"
          ></yo-lookup>
        </div>
      </div>
    </div>

    <dx-data-grid
      [dataSource]="catalogueAchatList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(750)"
      width="100%"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="produitArticle.libelle" alignment="left" caption="Article" [width]="300"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="produitArticle.reference" alignment="left" caption="Référence" [width]="300"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="groupeAchatCaList" alignment="left" caption="Prix (UC)" [width]="200"
                  [allowFiltering]="true"
                  cellTemplate="prixCellTemplate">

        <!-- TEMPLATE -->
      </dxi-column>
      <div *dxTemplate="let cell of 'prixCellTemplate'">
        <div *ngFor="let groupeAchatCa of cell.row.data?.groupeAchatCaList" class="d-flex flex-column">
          <div class="d-flex flex-row align-items-center">
            <p-radioButton [name]="'groupnameGaca'"
                           [value]="groupeAchatCa"
                           [(ngModel)]="selectedGacaViewModel"
                           class="mg-r-5"
                           (onClick)="updateGacaViewModel(groupeAchatCa, cell.row.data)">
            </p-radioButton>
            <ng-container [ngSwitch]="groupeAchatCa.prefere">
              <i *ngSwitchCase="true" class="mg-r-5 fas fa-star cursor" title="Article préféré"></i>
              <ng-container *ngSwitchCase="undefined">
                <!--                // DO NOTHING-->
              </ng-container>
            </ng-container>
            <div>
              {{groupeAchatCa.prixUC | currency:'EUR':'symbol':'1.4-4'}}
              / {{cell.row.data.uniteDeCommande.libelle | slice:0:5}}
              <span
                [title]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.row.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
            </div>
          </div>
        </div>
      </div>


      <!--    OPTION -->
      <dxo-selection mode="single"></dxo-selection>
      <dxo-pager
        [showPageSizeSelector]="false"
        [showNavigationButtons]="true"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} Article(s)">
      </dxo-pager>
    </dx-data-grid>
  </p-panel>

  <!--PANEL STOCK -->
  <p-panel header="STOCK" [collapsed]="false" [style]="{'margin-bottom': '10px'}">
    <form [formGroup]="form">

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label" title="Zone de stockage">Zone de stockage <span class="danger-color">*</span></div>
          <div class="value">
            <yo-lookup
              [data]="zoneStockageList"
              [selectedItem]="zoneStockageSelected?.id"
              (onValueChange)="onChangeZoneStockage($event)"
            ></yo-lookup>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label">Lot fabriquant</div>
          <div class="value">
            <input type="text" pInputText id="lotFabriquant" formControlName="lotFabriquant">
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label">Date d'entrée</div>
          <div class="value">
            <dx-date-box
              id="selected-date"
              formControlName="dateEntree"
              [inputAttr]="{ 'aria-label': 'Date' }">
            </dx-date-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label">Quantité en US <span class="danger-color">*</span></div>
          <div class="value">
            <input type="text" pInputText id="quantite" formControlName="quantite">
            <span class="input-group-text w-auto"
                  *ngIf="!utils.isNullOrEmpty(uniteDeStockage)">{{this.uniteDeStockage?.abreviation}}</span>
          </div>
        </div>
      </div>

      <div class="dialog-line">
        <div class="dialog-line">
          <div class="label">DLC</div>
          <div class="value">
            <dx-date-box
              id="dlc"
              formControlName="dlc"
              [inputAttr]="{ 'aria-label': 'Date' }"
            >
            </dx-date-box>
          </div>
        </div>
        <div class="dialog-line">
          <div class="label">Prix d'achat en UF</div>
          <div class="value">
            <input type="text" pInputText id="prixUnitaire" formControlName="prixUnitaire">
            <span class="input-group-text w-auto"
                  *ngIf="!utils.isNullOrEmpty(uniteDeFacturation)">€/{{this.uniteDeFacturation?.abreviation}}</span>
          </div>
        </div>
      </div>
      <div class="dialog-line">
        <div class="dialog-line">
        </div>
        <div class="dialog-line">
          <div class="label">Images</div>
          <div class="value">
            <yo-button
              iconClass="fas fa-camera"
              buttonClass="cta-secondary"
              (onClick)="openImageDialog()"
              tooltip="Ajouter une ou plusieurs photos"
              [label]="getImageCount()">
            </yo-button>
          </div>
        </div>
      </div>
    </form>
  </p-panel>


  <hr/>
  <div class="custom-button-container-right">
    <yo-button iconClass="fas fa-save" (onClick)="ajouterLot()"
               [disabled]="isDisabledSaveBtn()" tooltip="Enregistrer" buttonClass="cta-success">
    </yo-button>
    <yo-button iconClass="fas fa-times" tooltip="Fermer" (onClick)="closeDialog()"
               buttonClass="cta-inner-delete">
    </yo-button>
  </div>
</p-dialog>

<yo-image-list-popup
  [isOpened]="isImagePopupOpened"
  (onClose)="onImagePopupClose($event)"
  [values]="this.stock.images">
</yo-image-list-popup>
